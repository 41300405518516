import {StyleSheet} from 'aphrodite';

const styles = StyleSheet.create({
    ErrorContainer:{
        position:'absolute',
        top:0,
        left:0,
        right:0,
        bottom:0,
        backgroundColor:'#36AA9D',
        textAlign:'center',
        zIndex:223000,
        '@media (orientation:landscape)':{
            display:'none',
            pointerEvents:'none'
        }
    },
    animationContainer:{
        position:'relative',
        top:'35vh'
    },
    errorText:{
        color:'#FFAE47',
        fontFamily:'futura',
        fontSize:'3vh',
        width:'70vw',
        marginTop:'40vh',
        marginLeft:'15vw'
    }
})

export default styles;

