import React from 'react'
import { colorThemes } from '../../../constants'

export const FloorIcons = (props) => {
    
    return (
        props.number===1?
            <svg width='100%'  viewBox="0 0 38 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="18.1732" cy="26.813" r="17.9575" fill="#FFE7BA"/>
                <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="5" y="2" width="26" height="25">
                <path d="M5.41174 6.15811L17.887 26.7639L30.8236 6.76738L26.3279 4.03863L17.887 2.58984L9.44615 4.03863L5.41174 6.15811Z" fill="#C4C4C4"/>
                </mask>
                <g mask="url(#mask0)">
                {/* <circle cx="18.217" cy="26.9198" r="21.6337" stroke="#FFE7BA" stroke-width="2"/> */}
                </g>
                <path d="M17.0674 21.8477H16.3809V35.8123H17.0674V21.8477Z" fill={colorThemes[props.eraid]}/>
                <path d="M18.4395 21.8467H17.7529V35.8113H18.4395V21.8467Z" fill={colorThemes[props.eraid]}/>
                <path d="M19.8117 21.8467H19.1251V35.8113H19.8117V21.8467Z" fill={colorThemes[props.eraid]}/>
                <path d="M21.1848 21.8486H20.4976V35.8133H21.1848V21.8486Z" fill={colorThemes[props.eraid]}/>
                <path d="M21.1847 17.8125V21.1277H14.4639L16.5907 17.8125H21.1847Z" fill={colorThemes[props.eraid]}/>
                {/* <path d="M18.2588 0.869141L6.42761 7.8116L18.2588 5.97792L30.0906 7.8116L18.2588 0.869141Z" fill="#FFE7BA"/> */}
            </svg>
        :props.number===2?
        <svg width='100%'  viewBox="0 0 37 45" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="18.1084" cy="26.813" r="17.9575" fill="#FFE7BA"/>
            <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="5" y="2" width="26" height="25">
            <path d="M5.34692 6.15811L17.8222 26.7639L30.7588 6.76738L26.2631 4.03863L17.8222 2.58984L9.38133 4.03863L5.34692 6.15811Z" fill="#C4C4C4"/>
            </mask>
            <g mask="url(#mask0)">
            {/* <circle cx="18.1522" cy="26.9198" r="21.6337" stroke="#FFE7BA" stroke-width="2"/> */}
            </g>
            <path d="M23.5708 32.1064V35.3623H17.0754L19.4059 32.1064H23.5708Z" fill={colorThemes[props.eraid]}/>
            <path d="M18.1943 25.9722L16.4149 28.4707C15.1447 28.0901 14.0476 27.2771 13.3137 26.1727C12.5797 25.0683 12.2552 23.742 12.3963 22.4235C12.5373 21.105 13.1351 19.8773 14.0861 18.9532C15.037 18.029 16.2813 17.4666 17.6033 17.3633V20.5169C17.1227 20.6015 16.6765 20.8222 16.3176 21.1529C15.9588 21.4836 15.7024 21.9103 15.5789 22.3824C15.4554 22.8544 15.47 23.3521 15.621 23.8161C15.7719 24.2801 16.0529 24.6911 16.4305 25.0001C16.9615 25.4181 17.5573 25.7465 18.1943 25.9722Z" fill={colorThemes[props.eraid]}/>
            <path d="M23.7253 23.0281C23.7272 24.2658 23.3229 25.4699 22.5745 26.4557L16.2173 35.3621L12.3641 35.3637L17.1636 28.6396C17.1636 28.6396 20.1564 24.46 20.2157 24.3632C20.4336 24.009 20.5615 23.6068 20.5883 23.1918C20.6151 22.7768 20.54 22.3615 20.3694 21.9822C20.1988 21.6029 19.9381 21.2711 19.6098 21.0157C19.2816 20.7603 18.8959 20.5891 18.4863 20.5171V17.3643C19.9121 17.4756 21.2435 18.1206 22.2147 19.1705C23.1858 20.2204 23.7253 21.5979 23.7253 23.0281Z" fill={colorThemes[props.eraid]}/>
            {/* <path d="M18.194 0.869141L6.36279 7.8116L18.194 5.97792L30.0257 7.8116L18.194 0.869141Z" fill="#FFE7BA"/> */}
        </svg>
        :
        <svg width='100%' viewBox="0 0 38 45" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="18.5261" cy="26.813" r="17.9575" fill="#FFE7BA"/>
            <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="5" y="2" width="27" height="25">
            <path d="M5.76465 6.15811L18.2399 26.7639L31.1765 6.76738L26.6808 4.03863L18.2399 2.58984L9.79906 4.03863L5.76465 6.15811Z" fill="#C4C4C4"/>
            </mask>
            <g mask="url(#mask0)">
            {/* <circle cx="18.5699" cy="26.9198" r="21.6337" stroke="#FFE7BA" stroke-width="2"/> */}
            </g>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M18.2631 20.8581C17.7021 20.9567 17.1916 21.2439 16.816 21.6722C16.4404 22.1004 16.2222 22.6441 16.1976 23.2132H13.1239C13.1508 21.8288 13.6919 20.504 14.6418 19.4966C15.5918 18.4893 16.8827 17.8716 18.2631 17.7637V20.8581Z" fill={colorThemes[props.eraid]}/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M18.2633 32.6698V35.7642C16.8641 35.6549 15.5576 35.0219 14.6047 33.9916C13.6517 32.9612 13.1225 31.6094 13.1226 30.2059C13.1226 30.0535 13.1287 29.9033 13.1409 29.7539H16.2361C16.177 30.0786 16.1825 30.4117 16.2525 30.7342C16.3224 31.0567 16.4554 31.3622 16.6438 31.6331C16.8321 31.904 17.0722 32.1351 17.3501 32.313C17.628 32.4909 17.9384 32.6122 18.2633 32.6698Z" fill={colorThemes[props.eraid]}/>
            <path d="M23.0807 26.7653C23.7037 27.5587 24.0997 28.5064 24.2264 29.5072C24.3532 30.5079 24.2059 31.5244 23.8004 32.4481C23.3949 33.3717 22.7462 34.1681 21.9237 34.7521C21.1012 35.3361 20.1355 35.686 19.1298 35.7643V32.6707C19.5657 32.5939 19.9734 32.4028 20.3113 32.117C20.6493 31.8312 20.9053 31.4608 21.0534 31.0437C21.2015 30.6266 21.2363 30.1777 21.1542 29.7428C21.0721 29.3079 20.8761 28.9025 20.5862 28.5681C20.5045 28.4745 20.4162 28.3869 20.3219 28.306H18.2633V25.2507H20.2905C20.3965 25.1623 20.4954 25.0658 20.5862 24.9619C20.8761 24.6274 21.0721 24.2221 21.1542 23.7871C21.2363 23.3522 21.2015 22.9033 21.0534 22.4862C20.9053 22.0691 20.6493 21.6988 20.3113 21.4129C19.9734 21.1271 19.5657 20.9361 19.1298 20.8593V17.7656C20.1355 17.8441 21.1011 18.1941 21.9237 18.7782C22.7462 19.3622 23.3948 20.1586 23.8003 21.0823C24.2058 22.006 24.353 23.0225 24.2263 24.0233C24.0996 25.0241 23.7037 25.9718 23.0807 26.7653Z" fill={colorThemes[props.eraid]}/>
            {/* <path d="M18.6118 0.869141L6.78052 7.8116L18.6118 5.97792L30.4435 7.8116L18.6118 0.869141Z" fill="#FFE7BA"/> */}
        </svg>

        
    )
}
