import React, { useEffect, useState, useRef } from 'react'
import { useGLTF, useAnimations, Html } from '@react-three/drei'
import { useSpring } from '@react-spring/core'
import { animated as a } from '@react-spring/three'
import { animated } from '@react-spring/web'
import * as THREE from 'three'
import { useSound } from '../../stores'
import { useThree } from '@react-three/fiber'
import { useControls } from 'leva'
import { ACESFilmicToneMapping, CineonToneMapping, LinearEncoding, NoToneMapping, ReinhardToneMapping, sRGBEncoding } from 'three'
import { isDesktop } from 'react-device-detect'
import { css, StyleSheet } from 'aphrodite'


export default function Typewriter(props) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('./Models/Typewriter_06.glb')
  const { actions } = useAnimations(animations, group)
  const soundState = useSound()
  const input = useRef()
  const { gl } = useThree()
  const firstTime = useRef(1)
  const [open, setOpen] = useState(false)
  const [typeText, setType] = useState('')
  const handleKeyDown = (e) => {
    // soundState.play('type')
    // console.log(e.target)
    // setType(e.target)

  }
  const handleClick = () => {
    soundState.play('type')
    actions.Animation.paused = !actions.Animation.paused
  }
  useEffect(() => {
    actions.Animation.paused = false
    actions.Animation.loop =THREE.LoopRepeat
    actions.Animation.play()

    actions.Animation.clampWhenFinished = true
    actions.Animation.paused=true
    if(!props.credits){
      document.onkeydown = function(evt) {
        // document.getElementById()
  
        if(firstTime.current){
            input.current?.focus()
            input.current?.select()
            firstTime.current-=1
        }
      };
    }
    // props.finishedLoading()
    // ACESFilmicToneMapping, CineonToneMapping, LinearEncoding, NoToneMapping, ReinhardToneMapping, sRGBEncoding
    // gl.toneMapping = LinearEncoding
    // gl.toneMappingExposure = 1.5 
  },[])
  useEffect(()=>{
    

  })
  useEffect(()=>{
    if(props.shouldType){
      actions.Animation.paused = false
    }else{
      actions.Animation.paused = true
    }
  },[props.shouldType])

  const spring = useSpring({ state:4.4, from:{state:0}})

  return (
    <>
    <a.group onPointerDown={handleClick}  style={{cursor:'pointer'}} position={[0,0.4,0]} rotation={[0,Math.PI+Math.PI/6,0]} scale={spring.state.to(x=>x)} ref={group} {...props} dispose={null}>
    <group name="Scene">
        <group name="Typewriter_Parent" scale={[1, 0.8, 0.85]}>
          <group name="Cube006" position={[-0.07056, 0.08702, -0.02069]} scale={[0.00591, 0.00884, 0.01272]}>
            <mesh name="Cube008_1" geometry={nodes.Cube008_1.geometry} material={nodes.Cube008_1.material} />
            <mesh name="Cube008_2" geometry={nodes.Cube008_2.geometry} material={nodes.Cube008_2.material} />
            <mesh name="Cube008_3" geometry={nodes.Cube008_3.geometry} material={nodes.Cube008_3.material} />
            <mesh
              name="Plane_1"
              geometry={nodes.Plane_1.geometry}
              material={nodes.Plane_1.material}
              position={[6.67315, 0.32038, 2.49222]}
              rotation={[1.09198, 0, 0]}
              scale={[18.59771, 8.86104, 10.99554]}>
              <mesh
                name="Text006"
                geometry={nodes.Text006.geometry}
                material={nodes.Text006.material}
                position={[0.24337, 0.05749, -0.0022]}
                scale={0}
              />
              <mesh
                name="Text007"
                geometry={nodes.Text007.geometry}
                material={nodes.Text007.material}
                position={[0.10922, 0.05749, -0.0022]}
                scale={0}
              />
              <mesh
                name="Text008"
                geometry={nodes.Text008.geometry}
                material={nodes.Text008.material}
                position={[0.30636, 0.05749, -0.0022]}
                scale={0}
              />
              <mesh
                name="Text009"
                geometry={nodes.Text009.geometry}
                material={nodes.Text009.material}
                position={[0.3746, 0.05749, -0.0022]}
                scale={0}
              />
              <mesh
                name="Text010"
                geometry={nodes.Text010.geometry}
                material={nodes.Text010.material}
                position={[0.40627, 0.05749, -0.0022]}
                rotation={[0.45705, 0, 0]}
                scale={[0.0461, 0.0461, 0.0461]}
              />
              <mesh
                name="Text011"
                geometry={nodes.Text011.geometry}
                material={nodes.Text011.material}
                position={[0.46505, 0.05749, -0.0022]}
                rotation={[0.45705, 0, 0]}
                scale={[0.0461, 0.0461, 0.0461]}
              />
            </mesh>
          </group>
          <mesh
            name="Cube045"
            geometry={nodes.Cube045.geometry}
            material={nodes.Cube045.material}
            position={[-0.11096, -0.07169, 0.18042]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube046"
            geometry={nodes.Cube046.geometry}
            material={nodes.Cube046.material}
            position={[-0.07782, -0.07169, 0.18042]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube051"
            geometry={nodes.Cube051.geometry}
            material={nodes.Cube051.material}
            position={[0.08792, -0.07169, 0.18042]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube058"
            geometry={nodes.Cube058.geometry}
            material={nodes.Cube058.material}
            position={[-0.0297, -0.06991, 0.17541]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube067"
            geometry={nodes.Cube067.geometry}
            material={nodes.Cube067.material}
            position={[-0.09594, -0.06991, 0.17541]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube071"
            geometry={nodes.Cube071.geometry}
            material={nodes.Cube071.material}
            position={[0.03654, -0.06991, 0.17541]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube001"
            geometry={nodes.Cube001.geometry}
            material={nodes.Cube001.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <group name="Cube002" position={[-0.29149, 0.043, -0.06996]} scale={[0.00591, 0.00884, 0.01272]}>
            <mesh name="Cube003_1" geometry={nodes.Cube003_1.geometry} material={nodes.Cube003_1.material} />
            <mesh name="Cube003_2" geometry={nodes.Cube003_2.geometry} material={nodes.Cube003_2.material} />
            <mesh name="Cube003_3" geometry={nodes.Cube003_3.geometry} material={nodes.Cube003_3.material} />
          </group>
          <mesh
            name="Cube003"
            geometry={nodes.Cube003.geometry}
            material={nodes.Cube003.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube004"
            geometry={nodes.Cube004.geometry}
            material={nodes.Cube004.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube005"
            geometry={nodes.Cube005.geometry}
            material={nodes.Cube005.material}
            position={[-0.05059, 0.12256, 0.00694]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube007"
            geometry={nodes.Cube007.geometry}
            material={nodes.Cube007.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube008"
            geometry={nodes.Cube008.geometry}
            material={nodes.Cube008.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube009"
            geometry={nodes.Cube009.geometry}
            material={nodes.Cube009.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube010"
            geometry={nodes.Cube010.geometry}
            material={nodes.Cube010.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube011"
            geometry={nodes.Cube011.geometry}
            material={nodes.Cube011.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube012"
            geometry={nodes.Cube012.geometry}
            material={nodes.Cube012.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube013"
            geometry={nodes.Cube013.geometry}
            material={nodes.Cube013.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube014"
            geometry={nodes.Cube014.geometry}
            material={nodes.Cube014.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube015"
            geometry={nodes.Cube015.geometry}
            material={nodes.Cube015.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube021"
            geometry={nodes.Cube021.geometry}
            material={nodes.Cube021.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube022"
            geometry={nodes.Cube022.geometry}
            material={nodes.Cube022.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube023"
            geometry={nodes.Cube023.geometry}
            material={nodes.Cube023.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube024"
            geometry={nodes.Cube024.geometry}
            material={nodes.Cube024.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube025"
            geometry={nodes.Cube025.geometry}
            material={nodes.Cube025.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube026"
            geometry={nodes.Cube026.geometry}
            material={nodes.Cube026.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube027"
            geometry={nodes.Cube027.geometry}
            material={nodes.Cube027.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube029"
            geometry={nodes.Cube029.geometry}
            material={nodes.Cube029.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube030"
            geometry={nodes.Cube030.geometry}
            material={nodes.Cube030.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube031"
            geometry={nodes.Cube031.geometry}
            material={nodes.Cube031.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube032"
            geometry={nodes.Cube032.geometry}
            material={nodes.Cube032.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube033"
            geometry={nodes.Cube033.geometry}
            material={nodes.Cube033.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube036"
            geometry={nodes.Cube036.geometry}
            material={nodes.Cube036.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube037"
            geometry={nodes.Cube037.geometry}
            material={nodes.Cube037.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube038"
            geometry={nodes.Cube038.geometry}
            material={nodes.Cube038.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube039"
            geometry={nodes.Cube039.geometry}
            material={nodes.Cube039.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube041"
            geometry={nodes.Cube041.geometry}
            material={nodes.Cube041.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube042"
            geometry={nodes.Cube042.geometry}
            material={nodes.Cube042.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube043"
            geometry={nodes.Cube043.geometry}
            material={nodes.Cube043.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube044"
            geometry={nodes.Cube044.geometry}
            material={nodes.Cube044.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube047"
            geometry={nodes.Cube047.geometry}
            material={nodes.Cube047.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube048"
            geometry={nodes.Cube048.geometry}
            material={nodes.Cube048.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube049"
            geometry={nodes.Cube049.geometry}
            material={nodes.Cube049.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube050"
            geometry={nodes.Cube050.geometry}
            material={nodes.Cube050.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube052"
            geometry={nodes.Cube052.geometry}
            material={nodes.Cube052.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube053"
            geometry={nodes.Cube053.geometry}
            material={nodes.Cube053.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube054"
            geometry={nodes.Cube054.geometry}
            material={nodes.Cube054.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube055"
            geometry={nodes.Cube055.geometry}
            material={nodes.Cube055.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube057"
            geometry={nodes.Cube057.geometry}
            material={nodes.Cube057.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube059"
            geometry={nodes.Cube059.geometry}
            material={nodes.Cube059.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube061"
            geometry={nodes.Cube061.geometry}
            material={nodes.Cube061.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube062"
            geometry={nodes.Cube062.geometry}
            material={nodes.Cube062.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube063"
            geometry={nodes.Cube063.geometry}
            material={nodes.Cube063.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube064"
            geometry={nodes.Cube064.geometry}
            material={nodes.Cube064.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube065"
            geometry={nodes.Cube065.geometry}
            material={nodes.Cube065.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube066"
            geometry={nodes.Cube066.geometry}
            material={nodes.Cube066.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube068"
            geometry={nodes.Cube068.geometry}
            material={nodes.Cube068.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube070"
            geometry={nodes.Cube070.geometry}
            material={nodes.Cube070.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube072"
            geometry={nodes.Cube072.geometry}
            material={nodes.Cube072.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube073"
            geometry={nodes.Cube073.geometry}
            material={nodes.Cube073.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube074"
            geometry={nodes.Cube074.geometry}
            material={nodes.Cube074.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube075"
            geometry={nodes.Cube075.geometry}
            material={nodes.Cube075.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube076"
            geometry={nodes.Cube076.geometry}
            material={nodes.Cube076.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube077"
            geometry={nodes.Cube077.geometry}
            material={nodes.Cube077.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube078"
            geometry={nodes.Cube078.geometry}
            material={nodes.Cube078.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube079"
            geometry={nodes.Cube079.geometry}
            material={nodes.Cube079.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube080"
            geometry={nodes.Cube080.geometry}
            material={nodes.Cube080.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube081"
            geometry={nodes.Cube081.geometry}
            material={nodes.Cube081.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube082"
            geometry={nodes.Cube082.geometry}
            material={nodes.Cube082.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube083"
            geometry={nodes.Cube083.geometry}
            material={nodes.Cube083.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube084"
            geometry={nodes.Cube084.geometry}
            material={nodes.Cube084.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube085"
            geometry={nodes.Cube085.geometry}
            material={nodes.Cube085.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube086"
            geometry={nodes.Cube086.geometry}
            material={nodes.Cube086.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube087"
            geometry={nodes.Cube087.geometry}
            material={nodes.Cube087.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube088"
            geometry={nodes.Cube088.geometry}
            material={nodes.Cube088.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube089"
            geometry={nodes.Cube089.geometry}
            material={nodes.Cube089.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube090"
            geometry={nodes.Cube090.geometry}
            material={nodes.Cube090.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube091"
            geometry={nodes.Cube091.geometry}
            material={nodes.Cube091.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube092"
            geometry={nodes.Cube092.geometry}
            material={nodes.Cube092.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube093"
            geometry={nodes.Cube093.geometry}
            material={nodes.Cube093.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube094"
            geometry={nodes.Cube094.geometry}
            material={nodes.Cube094.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube095"
            geometry={nodes.Cube095.geometry}
            material={nodes.Cube095.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube096"
            geometry={nodes.Cube096.geometry}
            material={nodes.Cube096.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube097"
            geometry={nodes.Cube097.geometry}
            material={nodes.Cube097.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube098"
            geometry={nodes.Cube098.geometry}
            material={nodes.Cube098.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube099"
            geometry={nodes.Cube099.geometry}
            material={nodes.Cube099.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube100"
            geometry={nodes.Cube100.geometry}
            material={nodes.Cube100.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube101"
            geometry={nodes.Cube101.geometry}
            material={nodes.Cube101.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube102"
            geometry={nodes.Cube102.geometry}
            material={nodes.Cube102.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube103"
            geometry={nodes.Cube103.geometry}
            material={nodes.Cube103.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube104"
            geometry={nodes.Cube104.geometry}
            material={nodes.Cube104.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube105"
            geometry={nodes.Cube105.geometry}
            material={nodes.Cube105.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube106"
            geometry={nodes.Cube106.geometry}
            material={nodes.Cube106.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube107"
            geometry={nodes.Cube107.geometry}
            material={nodes.Cube107.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Cube108"
            geometry={nodes.Cube108.geometry}
            material={nodes.Cube108.material}
            position={[-0.29149, 0.043, -0.06996]}
            scale={[0.00591, 0.00884, 0.01272]}
          />
          <mesh
            name="Godrej_Typewriter"
            geometry={nodes.Godrej_Typewriter.geometry}
            material={materials.Godrej_Typewriter}
            position={[0, 0.10137, 0.17621]}
            scale={[0.02486, 0.03359, 0.05126]}
          />
          <mesh
            name="Plane001"
            geometry={nodes.Plane001.geometry}
            material={nodes.Plane001.material}
            position={[-0.17318, 0.09213, 0.018]}
            scale={[1.44754, 1.35063, 1.35063]}
          />
          <mesh
            name="Plane002"
            geometry={nodes.Plane002.geometry}
            material={nodes.Plane002.material}
            position={[0.14192, 0.09486, 0.01865]}
            scale={[1.44754, 1.35063, 1.35063]}
          />
          <mesh
            name="Universal"
            geometry={nodes.Universal.geometry}
            material={nodes.Universal.material}
            position={[0, -0.12542, 0.17076]}
            rotation={[Math.PI / 2, 0, 0]}
            scale={[0.02404, 0.02404, 0.02404]}
          />
          <mesh
            name="Universal001"
            geometry={nodes.Universal001.geometry}
            material={nodes.Universal001.material}
            position={[0, 0.07025, -0.28207]}
            rotation={[Math.PI / 2, 0, Math.PI]}
            scale={[0.0202, 0.0202, 0.0202]}
          />
        </group>
      </group>
    </a.group>
    <Html fullscreen style={{pointerEvents:'none'}}>

    {!props.credits && <animated.div style={{zIndex:100,position:'absolute',width:'100%',height:'14%',left:0,bottom:0,pointerEvents:'none',textAlign:'center'}}>
          {isDesktop && <p style={{color:"#FFE7BA",fontFamily:'futura'}}>Type something on your keyboard!</p>}
    </animated.div>}
    {!props.credits && <animated.div style={{zIndex:100,position:'absolute',width:'100%',left:'0%',top:'10%',pointerEvents:'auto',textAlign:'center'}}>
          {isDesktop && <textarea rows={4} onChange={(e)=>setType(e.target.value)} ref={input} value={typeText} placeholder={'Typing...'} className={css(styles.input)} />}
    </animated.div>}
    </Html>
    </>
  )
}
const styles = StyleSheet.create({
  input:{
    color:"#FFE7BA",
    fontFamily:'Pica',
    backgroundColor:'rgba(0,0,0,0)',
    fontSize:window.innerWidth>900?'19px':'13px',
    boxShadow:'0px 0px 0px 0px',
    width:"50%",
    // height:"%",
    border:'none',
    outline:'none',
    fontSize: '2vh',
    whiteSpace: 'normal'
    // '@media(max-width:900px)':{
    //   fontSize:'13px'
    // },
    // ':active':{
    //   border:'none'
    // },
    // ':focused':{
    //   border:'none'
    // }
  }
})
setTimeout(()=>useGLTF.preload('./Models/Typewriter_06.glb'),30000)


