import React,{useEffect, useRef, useState} from 'react'
import styles from './PortraitErrorStyles'
import { css } from 'aphrodite'
import ScreenRotate from './portraitGIF/ScreenRotate.json';
import Lottie from 'react-lottie';
import { isMobile } from 'react-device-detect';
import { GodrejLogo } from '../assets/svgs/GodrejLogo';
// import useScreenOrientation from 'react-hook-screen-orientation'
export default function PortraitError() {

  // const screenOrientation = useScreenOrientation();
  const animation = useRef();
  const [portrait, setPortrait] = useState(false);
  const [windowState,setWindow] = useState({x:window.innerWidth,y:window.innerHeight})
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: ScreenRotate,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
    };
    
  

    return (
      <>
      {
        //screenOrientation === 'portrait-primary' 
        
        <div className={css(styles.ErrorContainer)}>
          <GodrejLogo bigger/>
          <div className={css(styles.animationContainer)} >
            <Lottie
              ref={animation} 
              options={defaultOptions}
              height={100}
              width={100}
            />
          </div> 
          <div className={css(styles.errorText)} >
            Please rotate your screen to start the experience
          </div>     
        </div>
      }
      </>
    );
}