import { config, useSpring } from "@react-spring/core";
import { css, StyleSheet } from "aphrodite";
import React, { useState, useEffect, useRef } from "react";
import { AllMuseumLocations, springConfigs, zindexes } from "../../constants";
import { useLocations, usePage } from "../../stores";
import { animated as a } from "@react-spring/web";
import {
  TimelineDates1897,
  TimelineDates19401,
  TimelineDates19402,
  TimelineDates1970,
} from "../assets/svgs/TimelineDates";
import { LeftArrow, RightArrow } from "../assets/svgs/Arrows";
import { Bar, PercentageBar } from "../assets/svgs/Bar";
import { BackArrow, BetterBackArrow } from "../assets/svgs/BackArrow";
import { EnterRoom } from "../assets/svgs/EnterRoom";
import { NextArrowComponent } from "./NextArrowComponent";
import { LeftArrowComponent } from "./LeftArrowComponent";
import { AnimatedButton } from "../assets/AnimatedButton";
// import {isMobile} from 'react-device-detect'

const getSVG = (location) => {
  if (location.eraid === 0) return <TimelineDates1897 />;
  if (location.eraid === 1) return <TimelineDates19401 />;
  if (location.eraid === 2) return <TimelineDates19402 />;
  if (location.eraid === 3) return <TimelineDates1970 />;
};

const Content = ({ location }) => {
  if (!location) return null;
  return (
    <div className={css(styles.Headings)}>
      <h4 className={css(styles.Title)}>{location.eraTitle}</h4>
      <div className={css(styles.DataAndPara)}>
      <div className={css(styles.DateContainer)}>
        {/* <div  className={css(styles.Bar)}><PercentageBar /></div> */}
        <div className={css(styles.DateLine)} style={{marginTop:location.eraid===3?'1%':0}}></div>
        {getSVG(location)}
      </div>
      <div className={css(styles.Paragraph)}>
        <p >{location.eraText}</p>
        {/* <p className={css(styles.SmallHeading)}>{location.eraListHeading}</p>
        <ol className={css(styles.List)}>
          {location.eraList.split('\n').map(x=><li className={css(styles.ListItem)}>{x}</li>)}
        </ol> */}
      </div>
      </div>
    </div>
  );
};
export const Introduction = () => {
  // 
  let isMobile = true;
  const pageState = usePage();
  const locationsState = useLocations();
  const [fade, setFade] = useState(true);
  const [show, setShow] = useState(false);
  const [currentEra, setCurrentEra] = useState(0);
  const [slide, setSlide] = useState(false);
  useEffect(() => {
    if (pageState.page === "introduction") {
      setFade(false);
      setShow(true);
    } else {
      setFade(true);
    }
  }, [pageState.page]);

  ////////////////////////// Handler Functions
  const handleBack = () => {
    setShow(false);
    pageState.setPage("timelines");
  };

  const handleEnterMuseum = () => {
    setShow(false);
    pageState.setPage("exitanimation");
  };
  const handleFadeOutEnd = () => {
    setShow(false);
    if (pageState.page === "exitanimation") pageState.setPage("panorama");
  };
  const currRef = useRef();
  const spring = useSpring({
    state: fade ? 0 : 1,
    config: springConfigs.precision,
    reset: true,
    onRest: () => fade && handleFadeOutEnd(),
  });

  const slideInFromLeft = useSpring({
    // from: { marginLeft: -1700 },
    from: { transform:'translateX(-1700px)' },
    reset: true,
    // marginLeft: 0,
    transform:'translateX(0)',
    config: springConfigs.precision,
  });
  const slideInFromRight = useSpring({
    // from: { marginLeft: 1700 },
    from: { transform:'translateX(1700px)' },
    reset: true,
    transform:'translateX(0)',
    // marginLeft: 0,
    config: springConfigs.precision,
  });
  const exitToRight = useSpring({
    from: { transform:'translateX(0px)' },
    // from: { marginLeft: 0 },
    // marginLeft: 1700,
    transform:'translateX(1700px)',
    reset: true,
    config: springConfigs.precision,
  });
  const exitToLeft = useSpring({
    from: { transform:'translateX(0px)' },
    // from: { marginLeft: 0 },
    transform:'translateX(-1700px)',
    // marginLeft: -1700,
    reset: true,
    config: springConfigs.precision,
  });
  // const lastLocation = useRef()
  const [currLocation, setCurrentLocation] = useState(
    locationsState.currentLocation
  );
  const [lastLocation, setLastLocation] = useState();
  const [fromLeftAnim, setFromLeft] = useState(true);
  useEffect(() => {
    if (locationsState.currentLocation.eraid > currLocation.eraid)
      setFromLeft(true);
    else setFromLeft(false);
    setLastLocation(currLocation);
    setCurrentLocation(locationsState.currentLocation);
  }, [locationsState.currentLocation]);
  useEffect(() => {
    if (pageState.page === "introduction") {
      setLastLocation();
      locationsState.setLastLocation()
      
    }
  }, [pageState.page]);
  return (
    <>
      <a.div
        style={{ opacity: spring.state.to((x) => x) }}
        className={show ? css(styles.Container) : css(styles.ClickThrough)}
      >
        <div onClick={() => handleBack()} className={css(styles.BackArrow)}>
          <BetterBackArrow eraid={locationsState.currentLocation.eraid} />
        </div>
        {/* <EnterRoom
          className={css(styles.EnterRoom)}
          onClick={handleEnterMuseum}
        /> */}
        <AnimatedButton 
          // fade={fadeSpring} 
          eraid={locationsState.currentLocation.eraid}
          clickEvent={handleEnterMuseum} 
          top='90%' 
          left='92%' 
          text='ENTER ERA' 
        />
        
        <NextArrowComponent
          AllMuseumLocations={AllMuseumLocations}
          className={css(styles.Next)}
        />
        <LeftArrowComponent
          AllMuseumLocations={AllMuseumLocations}
          className={css(styles.Left)}
        />

        {!fade && show && lastLocation !== locationsState.currentLocation && locationsState.currentLocation!==locationsState.lastLocation? (
          <a.div style={!fromLeftAnim ? slideInFromLeft : slideInFromRight}>
            <Content location={currLocation} />
          </a.div>
        ) : (
          <a.div>
            <Content location={currLocation} />
          </a.div>
        )}

        {(pageState.page !== "exitanimation" && pageState.page!=='timelines' && locationsState.currentLocation!==locationsState.lastLocation) && (
          <a.div style={!fromLeftAnim ? exitToRight : exitToLeft}>
            {lastLocation && <Content location={lastLocation} />}
          </a.div>
        )}

        {/* <a.div style={exit}>
                    {lastLocation!==locationsState.currentLocation && <Content location={lastLocation}/>}
                </a.div> */}
      </a.div>
    </>
  );
};

const styles = StyleSheet.create({
  Container: {
    position: "absolute",
    width: "100%",
    height: "100%",
    overflow: "hidden",
    zIndex: zindexes.timelines,
    // textAlign:'center'
    // '@media (max-height: 360px)':{
    //     height:'100vh'
    // }
  },
  ClickThrough: {
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: zindexes.timelines,
    overflow: "hidden",
    pointerEvents: "none",
    // textAlign:'center'
  },
  None: {
    display: "none",
  },
  DateContainer: {
    position: "absolute",
    top: "10vw",
    left: "35vw",
    width: "7vw",
    // "@media (max-height: 400px)": {
    //   top: "24vh",
    //   left: "20vw",
    // },
  },
  DataAndPara:{
    position:'absolute',
    top:'2vw',
    "@media (max-height: 450px)": {
      marginLeft:'-14vw'
    },
    
  },
  DateLine:{
    position:'absolute',
    width:'530%',
    top:'0.1%',
    height:'18%',
    left:'-500%',
    backgroundColor:'#FFE7BA'
  },
  Title: {
    position: "absolute",
    fontFamily: "Bungee Inline",
    fontWeight: 1,
    left: "1.5vw",
    fontSize: "70%",
    marginTop: "5vw",
    width: "200%",
  },
  List:{
    lineHeight:'120%',
    "@media (max-height: 450px)": {
        marginTop:'-1.3vw',
      lineHeight:'120%',
    },
    "@media (max-height: 330px)": {
        marginTop:'-4vh',
      lineHeight:'120%',
    },
    
  },  
  ListItem:{
    lineHeight:'160%',
    "@media (max-height: 450px)": {
      // marginTop:'-1.3vw',
      lineHeight:'120%',
      fontSize:14
  },
    "@media (max-height: 330px)": {
      // marginTop:'-1.6vw',
      lineHeight:'110%',
      fontSize:12
  },
  },
  Headings: {
    color: "#FFE7BA",
    fontSize: "5vw",
    position: "absolute",
    top: "3vw",
    lineHeight: "3vw",
    width: "50vw",
    "@media (max-height: 450px)": {
      width: "70vw",
    },
  },
  Paragraph: {
    top: "17.5vw",
    textAlign: "left",
    position: "absolute",
    left: "36.2vw",
    width: "45vw",
    lineHeight: "150%",
    fontFamily: "futura",
    fontWeight: 100,
    color: "#FFE7BA",
    fontSize: 18,
    // "@media (max-height: 400px)": {
    //   top: "24vh",
    //   left: "50vw",
    //   width: "40vw",
    // },
    "@media (max-width: 1100px)": {
      fontSize:14
    },
    "@media (max-height: 450px)": {
      // width: "76vw",
      // marginTop:'-4vh',
      lineHeight:'100%'
    },
    "@media (max-height: 330px)": {
      // width: "76vw",
      // marginTop:'-3vh',
      lineHeight:'100%',
      fontSize:12
    },
  },
  SmallHeading:{
    fontSize:21,
    lineHeight:'120%',
    '@media (max-width: 1100px)':{
      fontSize:15
    },
    "@media (max-height: 450px)": {
      lineHeight:'-111%',
      marginTop:'-2%'
    },
    "@media (max-height: 330px)": {
      lineHeight:'-111%',
      marginTop:'-2%',
      fontSize:13
    },
    
  },
  Next: {
    position: "absolute",
    // top:'24vh',
    top:'8.4vw',
    // transform:'translateY(-100%)',
    right:'0.8vw',
    transform: "scale(1)",
    zIndex: zindexes.timelines + 10,
    "@media (max-width: 1000px)": {
      transform: "scale(0.7) translateY(-30%)",
      // right:-10,
      // top:'14vh'
    },
  },
  Left: {
    top: "18vw",
    position: "absolute",
    transform: "scale(1)",
    left:'1vw',
    zIndex: zindexes.timelines + 10,
    "@media (max-width: 1000px)": {
      left:'1vw',
      transform: "scale(0.7) translateX(-25%)",

      // left: "-20px",
      // top: "34vh",
    },
  },
  BackArrow: {
    position:'absolute',
    zIndex:50,
    width:'6.5%',
    left:'1vw',
    top:'1.9vh'
  },
  EnterRoom: {
    position: "absolute",
    textAlign: "center",
    bottom:20,
    right:20,
    zIndex: 30,
    "@media (max-width: 1000px)": {
      transform: "scale(0.6)",
      bottom:10,
      right:10
    },
  },
});