import { useSpring } from '@react-spring/core'
import { Html } from '@react-three/drei'
import { css, StyleSheet } from 'aphrodite'
import React, { useEffect, useRef } from 'react'
import { useBlur, useLocations, usePage, useSound } from '../../../stores'
import { Animate, AudioIcon, CloseIcon, CloserLook, ExternalIcon, TidBits, VideoIcon } from '../../assets/svgs/Buttons'
import { animated as a } from '@react-spring/web'
export const Guide = ({ setShowHelp }) => {
    const locationsState = useLocations()
    const blur = useBlur()
    const spring = useSpring({ opacity: 1, from: { opacity: 0 } })
    const soundState = useSound()
    const pageState = usePage()
    const container = useRef()
    const handleClose = () => {
        setShowHelp(false)
        blur.setBlur(false)
        soundState.play('close')
    }
    const handleClickOutside = (e) => {
        // 
        handleClose()
    }
    useEffect(() => {
        locationsState.currentLocation.era !== 'era0' && blur.setBlur(true)

    }, [locationsState.currentLocation.era])
    useEffect(() => {
        // window.addEventListener('click', handleClickOutside)
        return () => window.removeEventListener('click', handleClickOutside)
    }, [])
    const eraid = locationsState.currentLocation.era === 'era0' ? 0 : locationsState.currentLocation.eraid
    return (

        locationsState.currentLocation.era !== 'era0' && <a.div ref={container} style={spring} className={css(styles.Container)}>
            {/* <div   onClick={handleClose} >
                <CloseIcon className={css(styles.Close)}/>
            </div> */}
            <div className={css(styles.TextContainer)}>
                <h2 className={css(styles.Heading)}>Navigation Guide</h2>
                <ul className={css(styles.List)}>
                    <li>Drag on the screen to look around the room</li>
                    <li>Go to different parts of the room with the help of the navigation bar at the bottom of the screen</li>
                    <li>Open up the minimap to get an overview of the museum</li>
                    <li>Tap the various hotspots for more information</li>
                </ul>
                <h2 className={css(styles.Heading)}>Hotspot guide</h2>
                <table width={600}>
                    <tbody style={{ fontSize: '2.15vh', lineHeight: '2vh' }} className={css(styles.List)}>
                        <tr>
                            <td style={{ width: '5.2vh', }}> <CloserLook eraid={eraid} /> </td>
                            <td style={{ paddingBottom: '0.7vh', marginLeft: '0vw' }}><p style={{ marginLeft: '0vw' }}>Closer Look</p></td>
                            <td style={{ width: '6.2vh', marginLeft: '' }}> <TidBits eraid={eraid} /> </td>
                            <td style={{ paddingBottom: '0.7vh', marginLeft: '-4vw' }}><p style={{ marginLeft: '0vw' }}>Tidbits</p></td>
                        </tr>
                        <tr>
                            <td style={{ width: '6.2vh' }}> <Animate eraid={eraid} /> </td>
                            <td style={{ paddingBottom: '0.7vh' }}><p style={{ marginLeft: '1vw' }}>Interative</p></td>
                            <td style={{ width: '6.2vh', }}> <ExternalIcon eraid={eraid} /> </td>
                            <td style={{ paddingBottom: '0.7vh', marginLeft: '-4vw' }}><p style={{ marginLeft: '1vw' }}>QR Code/External link</p></td>
                        </tr>
                        <tr>
                            <td> <VideoIcon eraid={eraid} /> </td>
                            <td style={{ paddingBottom: '0.7vh' }}><p style={{ marginLeft: '1vw' }}>Video</p></td>

                        </tr>
                        <tr>
                            <td> <AudioIcon eraid={eraid} /> </td>
                            <td style={{ paddingBottom: '0.7vh' }}><p style={{ marginLeft: '1vw' }}>Audio</p></td>

                        </tr>
                    </tbody>
                </table>
                <p onClick={handleClickOutside} style={{textDecoration:'underline',cursor:'pointer'}} className={css(styles.List)}>Tap to close this page</p>
            </div>
            <div className={css(styles.ContactContainer)}>
                <h2 className={css(styles.Heading)}>CONTACT US</h2>
                <p className={css(styles.List)}>
                    <p style={{ margin: '0' }}>Visit us at: </p>
                    <a target='_blank' href='http://www.archives.godrej.com'><p style={{ marginTop: '0', textDecoration: 'underline' }}>https://www.archives.godrej.com</p></a>
                    <p style={{ marginBottom: '0' }}>Email us at: </p>
                    <p style={{ marginTop: '0' }}>archives@godrej.com</p>
                    <p style={{ marginBottom: '0' }}>Social Media</p>
                    <p style={{ marginBottom: 0 }}>
                        <a target={'_blank'} href='https://www.youtube.com/channel/UCGNtoQJx94guoF3nclCXuJg'><img src='../YouTube.svg' href="" style={{ margin: '0px 8px 0px 0px' }}></img></a>
                        <a target={'_blank'} href='https://www.facebook.com/GodrejArchives'><img src='../Facebook.svg' style={{ margin: '0px 8px' }}></img></a>
                        <a target={'_blank'} href='https://twitter.com/GodrejArchives'><img src='../Twitter.svg' style={{ margin: '0px 8px' }}></img></a>
                        <a target={'_blank'} href='https://www.instagram.com/godrej.archives/'><img src='../Instagram.svg' style={{ margin: '0px 8px' }}></img></a>
                        <a target={'_blank'} href='https://www.linkedin.com/in/godrej-archives-5452a9144/'><img src='../LinkedIn.svg' style={{ margin: '0px 8px' }}></img></a>
                    </p>
                    <p style={{ margin: '0', fontSize: '12px'}} className={css(styles.rightBottomText)}>Restore.Reflect.Reimagine</p>
                </p>
            </div>
            <div className={css(styles.Container)} style={{ backgroundColor: 'black', opacity: 0.9 }}></div>
        </a.div>
    )
}
const styles = StyleSheet.create({
    Container: {
        fontSize: '1.1vw',
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        zIndex: 110,
        overflow: 'hidden',
        textAlign: 'left',
        '@media (max-width: 1000px)': {
            fontSize: '1.4vw'
        }
    },
    Heading: {
        fontFamily: 'Bungee Inline',
        color: '#FFE7BA',
        fontWeight: 1,
        // fontSize:'22',
        lineHeight: '4vh'
    },
    TextContainer: {
        position: 'absolute',
        marginLeft: '10vw',
        // marginTop:'10vh',
        zIndex: 120,
        top: '50%',
        width: "40%",
        transform: 'translateY(-50%)',
        '@media (max-width: 700px)': {
            marginLeft: '7vw',
        }
    },
    ContactContainer: {
        position: 'absolute',
        zIndex: 120,
        top: '30vh',
        right: '-9vw',
        width: "40%",
        transform: 'translateY(-50%)',
        '@media (max-width: 700px)': {
            width: "50%",
        },
        '@media (max-width: 950px)': {
            width: "45%",
        }
    },
    rightBottomText:{
        marginLeft:"5vw",
        '@media (max-width: 700px)': {
            marginLeft: "8vw",
        },
        '@media (max-width: 950px)': {
            marginLeft: "6vw",
        }
    },
    List: {
        fontFamily: 'futura',
        color: '#FFE7BA',
        lineHeight: '3.2vh',
        marginBottom: '7vh',
        width: '44vw',
        
    }, Close: {
        position: 'absolute',
        top: '1.2%',
        zIndex: 500,
        right: '0.3%',
        height: '2.5vh',
        width: '1.9vw',
    },
    td: {
        width: '8rem'
    }
})