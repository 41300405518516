/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useEffect, useState, useRef } from "react";
import { useGLTF, useAnimations, Html } from "@react-three/drei";
import { useSpring } from "@react-spring/core";
import { animated as a } from "@react-spring/three";
import { animated } from "@react-spring/web";
import * as THREE from "three";
import { Tada } from "../assets/svgs/Tada";
import { useFrame, useThree } from "@react-three/fiber";
import {
  CineonToneMapping,
  LinearEncoding,
  sRGBEncoding,
  Vector3,
} from "three";
import { lerp } from "three/src/math/MathUtils";
import { useSound } from "../../stores";
export default function Ballot_box(props) {
  const group = useRef();
  const { nodes, materials, scene, animations } = useGLTF(
    "./Models/ballot_box.glb"
  );
  const { actions } = useAnimations(animations, group);
  const [open, setOpen] = useState(false);
  const tadaSpring = useSpring({ state: 1, from: { state: 0 } });
  const { camera } = useThree();
  const sprite = useRef();
  const sound = useSound();
  const { gl } = useThree();
  const handleClick = () => {
    sound.play("hud");
    actions.Animation.reset();
    actions.Animation.play();
  };
  useEffect(() => {
    actions.Animation.paused = false;
    actions.Animation.loop = THREE.LoopOnce;
    // actions.Animation.play()
    // actions.Animation.
    actions.Animation.clampWhenFinished = true;
    // actions.Animation.paused=true
    props.finishedLoading();

    // gl.toneMapping = THREE.ReinhardToneMapping;
    // gl.toneMappingExposure = 1;
    // gl.outputEncoding = THREE.sRGBEncoding;
  }, []);
  const spring = useSpring({ state: 6.4, from: { state: 0 } });
  const cube = useRef();
  return (
    <>
      <a.group
        position={[0, -0.3, 0]}
        onPointerDown={handleClick}
        rotation={[0, Math.PI / 6, 0]}
        scale={spring.state.to((x) => x)}
        ref={group}
        {...props}
        dispose={null}
      >
        <primitive object={scene} />
      </a.group>
      <Html fullscreen style={{ pointerEvents: "none" }}>
        <animated.div
          style={{
            zIndex: 100,
            position: "absolute",
            width: "100%",
            height: "20%",
            left: 0,
            bottom: 0,
            pointerEvents: "none",
            textAlign: "center",
          }}
        >
          <p style={{ color: "#FFE7BA", fontFamily: "futura" }}>Tap to open</p>
        </animated.div>
      </Html>
    </>
  );
}

// useGLTF.preload('./Models/ballot_box.glb')
setTimeout(() => useGLTF.preload("./Models/ballot_box.glb"), 30000);
