import React from 'react'
import { isMobile } from 'react-device-detect'

export const GodrejLogo = (props) => {
    return (
        <div 
            {...props}
        style={{
            position:'absolute',
            top:'2vh',
            right:'1.4vw',
            cursor: 'pointer',
            // width:!isMobile? '10vw':'12vw',
            zIndex:1000,
            ...props.style
            // "@media (max-width: 1000px)": {
            //     transform:'scale(0.8)'
            //   },
        }}>
        
<img width={props.bigger?'130vh':"200.4vh"} src={'./godrejlogo.svg'} />



        </div>


    )
}
