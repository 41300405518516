import React from 'react'

export const MinimapIcon = (props) => {
    return (
        <svg onClick={props.onClick} width="100%"  viewBox="0 0 49 41" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M47.3466 39.016V15.4033H2.13953V39.016H47.3466Z" stroke="#FFE7BA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M2.14392 19.3007C2.19264 19.5444 8.09177 27.3395 10.4872 30.5063H20.4138M35.3952 38.6669V30.5063H20.4138M20.4138 30.5063V38.6669M40.1454 16.0121V23.5028L46.6617 31.7243M20.5356 21.9803V8.52148" stroke="#FFE7BA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<circle cx="20.4359" cy="5.24341" r="5" fill="#FFE7BA"/>
</svg>

    )
}
