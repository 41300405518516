
import React from 'react'
import { useSpring } from '@react-spring/core'
import { animated as a } from '@react-spring/web'
import { colorThemes, springConfigs } from '../../../constants'
import { StyleSheet, css } from 'aphrodite'
// const CurrentPart = () => {
//     return (
//         <svg width="40%" height="40%" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
//         <circle cx="29.0674" cy="29.5518" r="12" fill="#FFE7BA"/>
//         <circle cx="29.0674" cy="29.5518" r="19" stroke="#FFE7BA" stroke-width="2"/>
//         <path d="M22.6921 19.1797H22.0818V31.5926H22.6921V19.1797Z" fill="#36AA9D"/>
//         <path d="M23.9115 19.1797H23.3013V31.5926H23.9115V19.1797Z" fill="#36AA9D"/>
//         <path d="M25.1315 19.1797H24.5212V31.5926H25.1315V19.1797Z" fill="#36AA9D"/>
//         <path d="M26.3519 19.1807H25.741V31.5935H26.3519V19.1807Z" fill="#36AA9D"/>
//         <path d="M26.352 15.5938V18.5406H20.3779L22.2684 15.5938H26.352Z" fill="#36AA9D"/>

//         {/* 2 */}
//     {/* <path d="M9.97935 13.6562V16.5503H4.20581L6.27732 13.6562H9.97935Z" fill="#36AA9D"/>
//     <path d="M5.20045 8.204L3.61879 10.4249C2.48972 10.0865 1.51455 9.36392 0.862184 8.38224C0.209819 7.40056 -0.0786534 6.22163 0.0467341 5.04964C0.172122 3.87766 0.70347 2.78644 1.54875 1.96499C2.39402 1.14353 3.49999 0.643593 4.67508 0.551758V3.3549C4.2479 3.43008 3.85128 3.62629 3.53232 3.92022C3.21336 4.21415 2.98546 4.59345 2.87569 5.01308C2.76593 5.4327 2.77891 5.87501 2.91309 6.28748C3.04727 6.69994 3.29702 7.06523 3.63266 7.33995C4.10467 7.7115 4.63425 8.00336 5.20045 8.204Z" fill="#36AA9D"/>
//     <path d="M10.117 5.58714C10.1187 6.68732 9.75927 7.75765 9.09398 8.63388L3.44329 16.5505L0.0183105 16.5519L4.28444 10.5751C4.28444 10.5751 6.94461 6.85997 6.99736 6.77391C7.19104 6.45905 7.30475 6.10158 7.32857 5.73268C7.35238 5.36378 7.28557 4.99466 7.13397 4.65751C6.98236 4.32035 6.75057 4.02541 6.45881 3.79841C6.16705 3.57141 5.82418 3.41924 5.46011 3.35518V0.552734C6.72749 0.651689 7.91094 1.22501 8.77417 2.15822C9.6374 3.09144 10.1169 4.3159 10.117 5.58714Z" fill="#36AA9D"/> */}

//         </svg>

//     )
// }
const Selected = (props) => {
    const spring = useSpring( {
        marginLeft:props.current===1?'-10.34%':props.current===2?'0%':'10.34%',config:springConfigs.precision
    })
    const twospring = useSpring(
        {
            marginLeft:props.current===1?`-4.8%`:`4.8%`,config:springConfigs.precision
        }
    )
    return (
        <a.div 
            className={css(styles.Selected)}
            style={{
                // position:'absolute',
                pointerEvents:'none',
                marginTop:'-2.8%',
                // marginLeft:props.current===1?'-8.5%':props.current===2?'0%':'8.5%',
                marginLeft:props.two?twospring.marginLeft.to(x=>x):spring.marginLeft.to(x=>x),
                "@media (max-height:400px)": {
                    marginTop:'-90%',
                },
                
            }}
        >
        <svg width="4vw" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="24.0674" cy="23.5518" r="12" fill="#FFE7BA"/>
<circle cx="24.0674" cy="23.5518" r="19" stroke="#FFE7BA" stroke-width="2"/>
{ props.current==1?
<>
    <path d="M22.6921 19.1797H22.0818V31.5926H22.6921V19.1797Z" fill={colorThemes[props.eraid]}/>
    <path d="M23.9115 19.1797H23.3013V31.5926H23.9115V19.1797Z" fill={colorThemes[props.eraid]}/>
    <path d="M25.1315 19.1797H24.5212V31.5926H25.1315V19.1797Z" fill={colorThemes[props.eraid]}/>
    <path d="M26.3519 19.1807H25.741V31.5935H26.3519V19.1807Z" fill={colorThemes[props.eraid]}/>
    <path d="M26.352 15.5938V18.5406H20.3779L22.2684 15.5938H26.352Z" fill={colorThemes[props.eraid]}/>
    </>
    :props.current===2?
    <>
    <path d="M28.9793 28.6562V31.5503H23.2058L25.2773 28.6562H28.9793Z" fill={colorThemes[props.eraid]}/>
    <path d="M24.2005 23.204L22.6188 25.4249C21.4897 25.0865 20.5145 24.3639 19.8622 23.3822C19.2098 22.4006 18.9213 21.2216 19.0467 20.0496C19.1721 18.8777 19.7035 17.7864 20.5487 16.965C21.394 16.1435 22.5 15.6436 23.6751 15.5518V18.3549C23.2479 18.4301 22.8513 18.6263 22.5323 18.9202C22.2134 19.2142 21.9855 19.5935 21.8757 20.0131C21.7659 20.4327 21.7789 20.875 21.9131 21.2875C22.0473 21.6999 22.297 22.0652 22.6327 22.34C23.1047 22.7115 23.6343 23.0034 24.2005 23.204Z" fill={colorThemes[props.eraid]}/>
    <path d="M29.117 20.5871C29.1187 21.6873 28.7593 22.7577 28.094 23.6339L22.4433 31.5505L19.0183 31.5519L23.2844 25.5751C23.2844 25.5751 25.9446 21.86 25.9974 21.7739C26.191 21.4591 26.3048 21.1016 26.3286 20.7327C26.3524 20.3638 26.2856 19.9947 26.134 19.6575C25.9824 19.3204 25.7506 19.0254 25.4588 18.7984C25.1671 18.5714 24.8242 18.4192 24.4601 18.3552V15.5527C25.7275 15.6517 26.9109 16.225 27.7742 17.1582C28.6374 18.0914 29.1169 19.3159 29.117 20.5871Z" fill={colorThemes[props.eraid]}/>
    </>:
    <>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M23.8152 18.2642C23.3165 18.3519 22.8627 18.6072 22.5289 18.9879C22.195 19.3685 22.0011 19.8517 21.9792 20.3576H19.2471C19.271 19.1271 19.7519 17.9495 20.5963 17.0541C21.4408 16.1587 22.5882 15.6096 23.8152 15.5137V18.2642Z" fill={colorThemes[props.eraid]}/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M23.8151 28.7628V31.5133C22.5714 31.4162 21.4101 30.8535 20.563 29.9377C19.716 29.0218 19.2455 27.8202 19.2456 26.5727C19.2456 26.4372 19.2511 26.3037 19.2619 26.1709H22.0132C21.9606 26.4595 21.9655 26.7556 22.0277 27.0422C22.0899 27.3289 22.2081 27.6004 22.3755 27.8413C22.543 28.0821 22.7563 28.2875 23.0034 28.4456C23.2504 28.6038 23.5263 28.7116 23.8151 28.7628Z" fill={colorThemes[props.eraid]}/>
    <path d="M28.0973 23.5142C28.651 24.2195 29.003 25.0618 29.1157 25.9514C29.2283 26.841 29.0974 27.7445 28.737 28.5655C28.3765 29.3866 27.8 30.0944 27.0688 30.6135C26.3377 31.1327 25.4794 31.4436 24.5854 31.5133V28.7634C24.9728 28.6952 25.3353 28.5253 25.6356 28.2713C25.936 28.0172 26.1636 27.688 26.2953 27.3173C26.4269 26.9465 26.4578 26.5475 26.3848 26.1609C26.3119 25.7743 26.1377 25.414 25.88 25.1167C25.8073 25.0335 25.7288 24.9557 25.645 24.8838H23.8152V22.168H25.6171C25.7113 22.0894 25.7992 22.0036 25.88 21.9112C26.1377 21.614 26.3119 21.2536 26.3848 20.867C26.4578 20.4805 26.4269 20.0814 26.2953 19.7107C26.1636 19.3399 25.936 19.0107 25.6356 18.7567C25.3353 18.5026 24.9728 18.3328 24.5854 18.2645V15.5146C25.4794 15.5844 26.3377 15.8955 27.0688 16.4147C27.7999 16.9339 28.3764 17.6417 28.7369 18.4628C29.0973 19.2839 29.2282 20.1873 29.1156 21.077C29.0029 21.9666 28.651 22.8089 28.0973 23.5142Z" fill={colorThemes[props.eraid]}/>
    </>
}
)
</svg>

        </a.div>

    )
}

export const NavigationBar = (props) => {
    // const [last,setLast] = useState()
    // const spring = useSpring()
    return (
        !props.two? (
            <>
                <div className={css(styles.Container)} >
                    <div style={{marginTop:0,
                    
                    }}>

                        <a.svg overflow='visible' style={props.fadeOut} width="11vw"  viewBox="0 0 134 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path style={{pointerEvents:'none'}} d="M6.66602 7.74023L66.8298 7.74024" stroke="#FFE7BA" stroke-width="2"/>
                        <path style={{pointerEvents:'none'}} d="M66.8301 7.74023L126.994 7.74024" stroke="#FFE7BA" stroke-width="2"/>
                        <circle onClick={()=>props.setLocation(1)} cx="66.8281" cy="7.74023" r="6" fill="#FFE7BA"/>
                        <circle onClick={()=>props.setLocation(1)} cx="66.8281" cy="7.74023" r="21" fill="rgba(0,0,0,0)"/>
                        <circle onClick={()=>props.setLocation(0)} cx="6.66602" cy="7.74023" r="6" fill="#FFE7BA"/>
                        <circle onClick={()=>props.setLocation(0)} cx="6.66602" cy="7.74023" r="21" fill="rgba(0,0,0,0)"/>
                        <circle onClick={()=>props.setLocation(2)} cx="126.992" cy="7.81055" r="21" fill="rgba(1,0,0,0)"/>
                        <circle onClick={()=>props.setLocation(2)} cx="126.992" cy="7.81055" r="6" fill="#FFE7BA"/>
                        </a.svg>
                    </div>

                
                
                    <Selected eraid={props.eraid} current={props.current} />

                        </div>

            </>
            ):(
            <>
            <div className={css(styles.Container)}>
                <div style={{marginTop:0}}>
                        <svg overflow="visible" width="5.9vw" viewBox="0 0 73 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.7605 6.84863L66.9243 6.84864" stroke="#FFE7BA" stroke-width="3"/>
                            <circle onClick={()=>props.setLocation(1)} cx="66.9243" cy="6.84863" r="6" fill="#FFE7BA"/>
                            <circle onClick={()=>props.setLocation(1)} cx="66.9243" cy="6.84863" r="21" fill="rgba(0,0,0,0)"/>
                            <circle onClick={()=>props.setLocation(0)} cx="6.7605" cy="6.84863" r="6" fill="#FFE7BA"/>
                            <circle onClick={()=>props.setLocation(0)} cx="6.7605" cy="6.84863" r="21" fill="rgba(0,0,0,0)"/>
                        </svg>
                    <Selected two eraid={props.eraid} current={props.current} />
        
                </div>

            </div>
    
            </>
            )
                
        
    )
}
export const NavigationBar2 = (props) => {
    return (
        <>
        <div style={{marginTop:0}}>
                <svg width="200px" viewBox="0 0 73 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.7605 6.84863L66.9243 6.84864" stroke="#FFE7BA" stroke-width="3"/>
                    <circle onClick={()=>props.setLocation(0)} cx="66.9243" cy="6.84863" r="6" fill="#FFE7BA"/>
                    <circle onClick={()=>props.setLocation(1)} cx="6.7605" cy="6.84863" r="6" fill="#FFE7BA"/>
                </svg>
            <Selected two eraid={props.eraid} current={props.current} />

        </div>

        </>
    )
}
const styles = StyleSheet.create({
    Container: {
        position:'absolute',
        zIndex:40,
        height:'4%',
        textAlign:'center',
        width:'100%',
        bottom:'3.5vh',
        left:0,
        '@media (max-height: 750px)':{
            bottom:'4vh'
        },
        '@media (max-height: 450px)':{
            bottom:'7vh'
        },
        "@media (max-width: 900px)": {
            // right:'13vw',
            marginBottom:'1.4vh',
            transform:'scale(1.5)',
          //   top:'34vh'
          },
    },
    Selected:{
        marginTop:'-2.8%',
        "@media (max-height:500px)": {
            marginTop:'-2.9%',
        },
        "@media (max-height:320px)": {
            marginTop:'-3.1%',
        },
    }
})