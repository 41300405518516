import { useSpring } from "@react-spring/core";
import React, { useState, useEffect, useRef } from "react";
import { useBlur, useLocations, useSound } from "../../stores";
import { Reel } from "../assets/svgs/Reel";
import { StyleSheet, css } from "aphrodite";
import { animated as a } from "@react-spring/web";
import { springConfigs } from "../../constants";
import { Html } from "@react-three/drei";
import { VideoRoom } from "../../constants";
import { PopupScreen } from "../popups/PopupScreen";
import { GradientVideo } from "../assets/svgs/GradientVideo";

export const VideoBooth = (props) => {
  const [show, setShow] = useState(false);
  const [showPopup, setShowPopup] = useState();
  const locationsState = useLocations();
  const soundState = useSound();
  const blur = useBlur();
  const fade = useSpring({
    opacity: show ? 1 : 0,
    config: springConfigs.precision,
  });
  const fadeReel = useSpring({
    opacity: showPopup ? 0 : 1,
    config: springConfigs.precision,
  });
  useEffect(() => {
    if (locationsState.currentLocation.name === "videobooth") {
      !show && setShow(true);
    } else {
      setShow(false);
    }
  }, [locationsState]);
  const ref = useRef();
  const closePopup = () => {
    setShowPopup();
  };
  useEffect(() => {
    function transformScroll(event) {
      if (!event.deltaY) {
        return;
      }
      ref.current.scrollLeft += event.deltaY;
    }

    var element = document.scrollingElement || document.documentElement;
    element.addEventListener("wheel", transformScroll);

    return () => {
      element.removeEventListener("wheel", transformScroll);
      // window.removeEventListener('click',()=>time && clearInterval(time))
    };
  }, []);
  let time;

  return (
    // <Html fullscreen>
    <>
      <a.div
        style={{
          opacity: fade.opacity,
          cursor: "default",
          pointerEvents: show ? "auto" : "none",
          zIndex: 30
        }}
        className={css(styles.Container)}
      >
        <a.div style={{ ...fadeReel }}>
          <div
            style={{
              width: "15%",
              left: 0,
              top: 0,
              position: "absolute",
              zIndex: 600,
              transform: "scale(-1)",
              pointerEvents: "none",
            }}
          >
            <GradientVideo />
          </div>
          <div
            style={{
              width: "15%",
              right: 0,
              top: 0,
              position: "absolute",
              zIndex: 600,
              pointerEvents: "none",
            }}
          >
            <GradientVideo />
          </div>
          <div ref={ref} className={css(styles.Reel)}>
            <Reel />
            <div className={css(styles.ImageContainer)}>
              {VideoRoom.map((video) => {
                return (
                  <img
                    onPointerDown={() => soundState.play("hotspot")}
                    onClick={() => setShowPopup(video)}
                    className={css(styles.Img)}
                    height="100%"
                    width="100%"
                    src={video.image}
                    alt=""
                  />
                );
              })}
            </div>
          </div>
        </a.div>
      </a.div>
      {showPopup !== undefined && (
        <a.div
          style={{
            opacity: fadeReel.opacity.to((x) => 1 - x),
            position: "absolute",
            backgroundColor: "rgba(0,0,0,0)",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            zIndex: 120,
          }}
        >
          <PopupScreen
            type="video"
            {...showPopup}
            noDrei
            closePopup={closePopup}
          />
        </a.div>
      )}
    </>
    // </Html>
  );
};

const styles = StyleSheet.create({
  Container: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    overflow: "hidden",
    // zIndex:30,
    backgroundColor: "#3B3B3B",
    svg: {
      cursor: "none",
    },
  },
  Reel: {
    paddingBottom: "1%",
    paddingtop: "0.5%",
    position: "absolute",
    height: "47%",
    width: "100%",
    overflowX: "auto",
    overflowY: "hidden",
    top: "50%",
    transform: "translateY(-50%)",
    cursor: "default",
    // '::-webkit-scrollbar': {
    //     display: 'none'  /* Safari and Chrome */
    // },
    // '-ms-overflow-style':'none', /* Internet Explorer 10+ */
    // scrollbarWidth: 'none'  /* Firefox */
  },
  ImageContainer: {
    display: "inline-flex",
    justifyContent: "space-between",
    position: "absolute",
    height: "70%",
    top: "50%",
    transform: "translateY(-51%)",
  },
  Img: {
    marginLeft: "5%",
    // marginRight: "1%",
    cursor: "pointer",
  },
});
