import React, { useState, useEffect, useRef } from 'react'
import { cameraStore, useAudio, useBlur, useLocations, usePage, useSound } from '../../../stores'
import { BackArrow, BetterBackArrow } from '../../assets/svgs/BackArrow'
import { Minimap } from './Minimap'
import { StyleSheet,css } from 'aphrodite'
import { LeftArrowComponent } from '../../Introduction/LeftArrowComponent'
import { NextArrowComponent } from '../../Introduction/NextArrowComponent'
import { zindexes } from '../../../constants'
import { GuideIcon, AudioIcon, MuteIcon } from '../../assets/svgs/Buttons'
import { Guide } from './Guide'
import { config, useSpring } from '@react-spring/core'
import { animated as a } from '@react-spring/web'
import { Heading } from './Heading'
import { LocationSlider } from './LocationSlider'
import { Hints } from './Hints'
import { MuteUnmute } from './MuteUnmute'
import { AudioText } from './AudioText'

export const HUD = () => {
    const locationsState = useLocations()
    const pageState = usePage()
    const [showHelp,setShowHelp] = useState(false)
    const [hints, setHints] = useState(0)
    const isFirstTime = useRef(false)
    const audio = useAudio()
    const blur = useBlur()
    const soundState = useSound()
    const cameraState = cameraStore()
    useEffect(()=> {
        if(pageState.page==='panorama' && locationsState.currentLocation.era!=='era0'){
            if(isFirstTime.current){
                setShowHelp(true)
                isFirstTime.current = false
            }

        }
    },[pageState.page, locationsState.currentLocation.era])
    useEffect(()=>{
        hints === 5 && cameraState.stopAndResume(4000)
    },[hints])
    const fadeOut = useSpring( {opacity:( pageState.page==='panorama' ||  pageState.page==='exitanimation')?1:0,reset:true,config:config.slow})
    const blurSpring = useSpring( {opacity:blur.blur?0:1})
    return (
        
        <a.div style={{opacity:fadeOut.opacity,position:'absolute',width:'100%',height:'100%',top:0,left:0,overflow:'hidden'}}>
            { <a.div style={{position:'absolute',width:'100%',height:'100%',top:0,left:0,textAlign:'center'}}>
                {
                (locationsState.currentLocation.era!=='era0') && 
                    <>
                        {pageState.page==='panorama' && <Hints hints={hints} incHints={()=>setHints(state=>state+1)}/>}
                        {/* {pageState.page==='panorama' && <Hin} */}
                        <LocationSlider fadeOut={fadeOut} />
                        {/* <EraSlider /> */}
                        {locationsState.currentLocation.era!=='era0' && pageState.page==='panorama' && <Minimap />}
                        <div className={css(styles.Back)} >
                            <BetterBackArrow eraid={locationsState.currentLocation.eraid} onClick={()=> pageState.setPage('introduction')}/>
                        </div>
                        { <LeftArrowComponent className={css(styles.Left)}/>}
                        {/* {locationsState.currentLocation.locationId%3===1 && <LeftArrowComponent className={css(styles.Left)}/>} */}
                        {/* {locationsState.currentLocation.locationId%3===0 && <NextArrowComponent  className={css(styles.Right)} />} */}
                        { <NextArrowComponent  className={css(styles.Right)} />}
                        {locationsState.currentLocation.eraid===3 && <NextArrowComponent credits className={css(styles.Right)} /> }
                        <div onPointerDown={()=>soundState.play('hud')} onClick={()=>setShowHelp(true)} className={css(styles.Guide)}>
                            <GuideIcon />
                        </div>
                        {/* <div onClick={() => audio.setMuted()} className={css(styles.Audio)}>
                            <MuteIcon muted={audio.isMuted}/> 
                        </div> */}
                        <MuteUnmute />
                        {/* <div className={css(styles.nowPlayingContainer)}>
                            {audio.title && <p className={css(styles.Text)}>{`Now playing: ${audio.title}`}</p>}
                        </div> */}
                        {audio.title && <AudioText  title={audio.title} text={audio.text} subsubtext={audio.subsubtext} subtext={audio.subtext} eraid={locationsState.currentLocation.eraid}/>}
                        <Heading currentLocation={locationsState.currentLocation}/>
                    </>
                }
                
            </a.div>}
        {showHelp && <Guide setShowHelp={setShowHelp}/>}
        
        </a.div>
    )
}

const styles = StyleSheet.create({
    Back:{
        position:'absolute',
        zIndex:50,
        width:'6.5%',
        overflow:'visible',
        // width:91,
        // height:'2.72%',
        // backgroundColor:'black',
        left:'1vw',
        top:'1.9vh',
        "@media (max-width: 900px)": {
            top:'2.2vh',
            left:'1.4vw',
            transform:'scale(1.3)',
          //   top:'34vh'
          },
    },
    Right: {
        position: "absolute",
        top:'93.1%',
        // backgroundColor:'red',
        // bottom:'-50vh',
        right:'23vw',
        // height:'5vh',
        // bottom:'6.8vh',
        overflow:'visible',
        transform: "translateY(-50%) scale(1)",
        zIndex: zindexes.timelines + 10,
        "@media (max-width: 1100px)": {
          right:'23vw',
          transform:'translateY(-50%) scale(0.6)',
          top:'92%'
        },
        '@media (max-height: 400px)': {
            // top:'75vh'
        }
      },
      Left: {
        // top: "43%",
        // bottom:'6.9vh',
        top:'93.1%',
        
        position: "absolute",
        transform: "translateY(-50%) scale(1)",
        left:'23vw',
        zIndex: zindexes.timelines + 10,
        "@media (max-width: 1100px)": {
          transform: "translateY(-50%) scale(0.6)",
          left:'23vw',
        //   top:'34vh'
        },
      },
      Guide:{
          position:'absolute',
          width:'2.6vw',
          left:'2.1vw',
          zIndex:50,
          bottom:'3.3vh',
          "@media (max-width: 900px)": {
            transform:'scale(1.6)',
          //   top:'34vh'
          },
      },
    Text:{
        // position:'absolute',
        fontFamily:'futura',
        color:'#FFE7BA',
        
        zIndex:30,
        fontSize:18,
        '@media (max-width: 1000px)':{
            // marginBottom:'26vh',
            fontSize:14
        }
    },
    nowPlayingContainer:{
        position:'absolute',
        height:'24%',
        width:'100%',
        bottom:0,
        left:0,
        textAlign:'center',
        zIndex:30,
        pointerEvents:'none',
        fontSize:'',
        '@media (max-width: 1000px)':{
            // marginBottom:'26vh',
            bottom:'5%'
        }
    }
})