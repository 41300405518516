import React from 'react'
import { useLocations, useSound } from '../../stores'
import { LeftArrow } from '../assets/svgs/Arrows'
import { AllMuseumLocations } from '../../constants'

export const LeftArrowComponent = ({className}) => { 
    const locationsState = useLocations()
    const soundState = useSound()
    return (
        <div
        onPointerDown={()=>soundState.play('hud')}
          onClick={() =>
            locationsState.setCurrentLocation(
              locationsState.allLocations.find(
                (x) => x.eraid === locationsState.currentLocation.eraid - 1
              )
            )
          }
          className={className}        >
          {locationsState.currentLocation.eraid > 0 &&
            locationsState.currentLocation.eraid !== undefined && (
              <LeftArrow
                text={`Go to ${
                  AllMuseumLocations[
                    locationsState.currentLocation.eraid
                  ].era.split("-")[0]
                }`}
              />
            )}
        </div>
    )
}
