import { Html } from '@react-three/drei'
import { StyleSheet, css } from 'aphrodite'
import React, { useEffect, useRef, useState } from 'react'
import { CloseIcon } from '../assets/svgs/Buttons'
import { popupColorThemes as colorThemes } from '../../constants'
import HTMLReactParser from 'html-react-parser'

function strip(html) {
  let doc = new DOMParser().parseFromString(html, 'text/html')
  console.log(doc)
  return doc.body || ''
}
export const parseItalicsBold = (line) => {
  // const italics = line.split('/i')
  // res = []
  // for (let i=0; i<line.length-1;i++){
  //     curr = line[i]+line[i+1]
  //     if(curr === '/i' || )
  // }
  return { __html: strip(line) }
}
const Text = (props) => {
  return (
    <>
      <h3 className={css(styles.Heading)}>{props.title}</h3>
      {props.text.split('\n').map((line) => {
        console.log(HTMLReactParser(line))
        return <p className={css(styles.Text)}>{HTMLReactParser(line)}</p>
      })}
      {props.title.toLowerCase() === 'mangroves' && !props.subsubtext && (
        <p className={css(styles.Text)}>
          To know more, click here:{' '}
          <a
            href="https://mangroves.godrej.com"
            target="_blank"
            className={css(styles.link)}
            rel="noreferrer"
          >
            https://mangroves.godrej.com
          </a>
        </p>
      )}
    </>
  )
}
export const TextHeavy = (props) => {
  const smallLines = props.subsubtext && props.subsubtext.split('\n')
  const video = useRef()
  const [play, setPlay] = useState(true)


  useEffect(() => {
    video.current?.addEventListener(
      'click',
      function (e) {
        video.current.play()
      },
      true
    )
    video.current?.addEventListener(
      'click',
      function (e) {
        video.current.pause()
      },
      true
    )
    return () => {
      video.current?.removeEventListener(
        'click',
        function (e) {
          video.current.play()
        },
        true
      )
      video.current?.removeEventListener(
        'click',
        function (e) {
          video.current.pause()
        },
        true
      )
    }
  }, [])
  useEffect(() => {
    if (play) {
      video.current?.play()
    } else {
      video.current?.pause()
    }
  }, [play])
  return (
    <div className={css(styles.Container)}>
      <div
        className={css(styles.TextContainer)}
        style={{ paddingRight: props.imagetype === 'portrait' ? '32%' : '38%' }}
      >
        <div>
          {typeof props.title === 'object' ? (
            props.title.map((x, idx) => {
              return (
                <Text
                  key={idx}
                  title={props.title[idx]}
                  text={props.text[idx]}
                />
              )
            })
          ) : (
            <Text {...props} />
          )}
          {/* <Text {...props}/> */}
          {/* <p className={css(styles.Text)}>{props.text}</p> */}
          <div
            className={
              typeof props.text === 'object' ||
              props.text.split(' ').length > 120
                ? css(styles.BottomTextNotFixed)
                : css(styles.BottomText)
            }
          >
            <p className={css(styles.SubText)}>{props.subtext}</p>
            {/* <p className={css(styles.SubSubText)} style={{}} >{props.subsubtext}</p> */}
            {smallLines &&
              smallLines.map((line) => (
                <p className={css(styles.SubSubText)}>{line}</p>
              ))}
          </div>
        </div>
        {/* <div className={css(styles.ImageContainer)}> */}

        {!props.video ? (
          <img
            className={css(styles.Img)}
            style={{
              maxWidth: props.imagetype === 'portrait' ? '27.5%' : '33.5%',
            }}
            src={props.url}
            alt=""
          />
        ) : (
          <div>
            <video
              controls
              controlsList="noremoteplay play nodownload"
              ref={video}
              className={css(styles.Img)}
              // controls
              // className={css(styles.Img)}
              style={{
                maxWidth: props.imagetype === 'portrait' ? '28%' : '33.5%',
                backgroundColor: '#3B3B3B',
              }}
              autoPlay
              loop={true}
            >
              <source src={props.url} />
            </video>
            {/* <div className={css(styles.playpause)}>
                                {!play && <img width="100%" onClick={()=>setPlay(state=>!state)} src="https://img.icons8.com/ios-glyphs/30/000000/play--v1.png"/>} 
                                {play && <img width="100%" onClick={()=>setPlay(state=>!state)} src="https://img.icons8.com/ios-glyphs/30/000000/pause.png"/>}
                            </div>  */}
          </div>
        )}
      </div>
      {/* </div> */}
    </div>
  )
}

const styles = StyleSheet.create({
  Container: {
    position: 'absolute',
    // backgroundColor:"red",
    display: 'flex',
    verticalAlign: 'center',
    height: '100%',
    width: '100%',
    overflow: 'visible',
    zIndex: 50,
  },
  playpause: {
    position: 'absolute',
    bottom: '10%',
    left: '66%',
    margin: '1%',
    width: '2.5%',
  },
  Img: {
    position: 'absolute',
    border: '#FFE7BA 4px solid',
    display: 'inline',
    top: '50%',
    transform: 'translateY(-50%)',
    right: 0,
    // backgroundColor:'blue',
    // top:0,
    // minHeight:'80%',
    marginRight: '3%',
    maxHeight: '85%',

    // height:'82%',
    '@media (max-width: 900px)': {
      maxHeight: '85%',
    },
  },
  ImageContainer: {},
  TextContainer: {
    display: 'inline',
    color: '#FFE7BA',
    // backgroundColor:'red',
    float: 'left',
    // width:'56%',
    marginLeft: '4%',
    marginRight: '0.9%',
    marginTop: '2.8%',
    textAlign: 'left',
    overflow: 'auto',
    direction: 'rt1',
    height: '87%',
    // width:'100vw',
    // fontSize:'100%'
    fontSize: 18,
    fontWeight: 100,
    '@media (max-width: 900px)': {
      fontSize: 14,
      marginTop: '2.8%',
      // lineHeight:'93%'
    },
    // '@media (max-height: 400px)':{
    //     height:'87%',
    // }
  },
  SubText: {
    fontFamily: 'futura',
    margin: 0,
    fontSize: 18,
    fontWeight: 100,
    lineHeight: '21px',
    '@media (max-width: 900px)': {
      fontSize: 14,
      lineHeight: '17px',
    },
  },
  link: {
    color: '#FFE7BA',
    pointerEvents: 'auto',
    cursor: 'pointer',
    ':link': {
      color: '#FFE7BA',
    },
  },
  SubSubText: {
    fontFamily: 'futura',
    fontStyle: 'italic',
    fontSize: 16,
    margin: 0,
    lineHeight: 19 + 'px',
    '@media (max-width: 900px)': {
      fontSize: 12,
      lineHeight: 14 + 'px',
    },
  },
  BottomText: {
    '@media (min-width: 901px)': {
      position: 'absolute',
      bottom: '7%',
      width: '40%',
    },

    '@media (max-width: 900px)': {
      marginTop: '7%',
      width: '100%',
    },
  },
  BottomTextNotFixed: {
    marginTop: '7%',
    width: '100%',
  },
  Heading: {
    fontFamily: 'Bungee Inline',
    fontSize: 22.5,
    fontWeight: 400,
    lineHeight: '24px',
    '@media (max-width: 900px)': {
      fontSize: 18,
      // lineHeight:'1px'
      // lineHeight:'93%'
    },
  },
  Text: {
    fontFamily: 'futura',
  },
  Close: {
    position: 'absolute',
    top: '2%',
    left: '97%',
  },
})
