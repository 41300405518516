import { useSpring } from '@react-spring/core';
import { animated } from '@react-spring/web';
import { css, StyleSheet } from 'aphrodite';
import react, { useState } from 'react'


export const LeftArrow = (props) => {
    const text = props.text
    const [expand, setExpand] = useState(false)
    const spring = useSpring( {r:expand?16:13,config:{duration:200} , onRest:()=>setExpand(false) }, )
    return (
    <div overflow='visible' onClick={()=>setExpand(true)} style={props.style}>
        <svg overflow='visible' width="116" height="31" viewBox="0 0 120 31" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M95.482 15.075L4.98766 15.075L19.3982 29.4856" stroke="#FFE7BA" stroke-width="4"/>
        <circle cx="101.968" cy="15.075" r="7" transform="rotate(-180 101.968 15.075)" fill="#FFE7BA"/>
        <animated.circle cx="101.968" cy="14.7847" r={spring.r.to(x=>x)} transform="rotate(-180 101.968 14.7847)" stroke="#FFE7BA" stroke-width="2"/>
        </svg>

        <p className={css(styles.leftText)}>{text}</p>
    </div>
    )
  };

export const RightArrow = (props) => {
    const [expand, setExpand] = useState(false)
    const spring = useSpring( {r:expand?16:13,config:{duration:200} , onRest:()=>setExpand(false) }, )
    const text = props.text
    return (
        <animated.div overflow='visible' onClick={() => setExpand(true)} style={props.style}>
            <svg overflow='visible' width={116} height={31} viewBox="0 0 120 31" fill="none" >
                <path
                d="M14.9533 16.4496H105.448L91.0371 2.03906"
                stroke="#FFE7BA"
                strokeWidth={4}
                />
                <circle cx={14.9532} cy={16.4497} r={7} fill="#FFE7BA" />
                <animated.circle cx={14.9535} cy={16.74} r={spring.r.to(x=>x)} stroke="#FFE7BA" strokeWidth={2} />
            </svg>
            <p className={css(styles.rightText)}>{text}</p>
    </animated.div>
    )
      
}
export const FloorRightArrow = (props) => {
    const [expand, setExpand] = useState(false)
    const spring = useSpring( {r:expand?16:13,config:{duration:200} , onRest:()=>setExpand(false) }, )
    const text = props.text
    return (
        <animated.div overflow='visible' onClick={() => setExpand(true)} style={props.style}>
            <svg overflow='visible' width={116} height={31} viewBox="0 0 120 31" fill="none" >
                <path
                d="M14.9533 16.4496H105.448L91.0371 2.03906"
                stroke="#FFE7BA"
                strokeWidth={4}
                />
                <circle cx={14.9532} cy={16.4497} r={7} fill="#FFE7BA" />
                {!props.noCircle && <animated.circle cx={14.9535} cy={16.74} r={spring.r.to(x=>x)} stroke="#FFE7BA" strokeWidth={2} />}
            </svg>
            <p className={css(styles.floorArrowText)}>{text}</p>
    </animated.div>
    )
      
}
export const BackArrow = () => (
    <svg width="85" height="3" viewBox="0 0 85 3" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.751831 1.81226H84.8963" stroke="#FFE7BA" stroke-width="2"/>
</svg>
)
const styles = StyleSheet.create({
    rightText:{
        position:'absolute',
        top:'5%',
        left:'27%',
        whiteSpace:'nowrap',
        fontFamily: 'Source Sans Pro',
        fontStyle:'normal',
        fontWeight: 'normal',
        color: '#FFE7BA',
        cursor:"pointer",
        

    },
    floorArrowText:{
        position:'absolute',
        top:'-15%',
        left:'67%',
        whiteSpace:'nowrap',
        fontFamily: 'Source Sans Pro',
        fontStyle:'normal',
        fontWeight: 'normal',
        color: '#FFE7BA',
        cursor:"pointer",
        fontSize:'1.3vw'
    },
    leftText:{
        position:'absolute',
        top:'-67%',
        left:'13.3%',
        fontFamily: 'Source Sans Pro',
        fontStyle:'normal',
        fontWeight: 'normal',
        color: '#FFE7BA',
        cursor:"pointer",
        whiteSpace:'nowrap'
    }
})