import { Html } from '@react-three/drei'
import { StyleSheet, css } from 'aphrodite'
import React, { useState, useEffect } from 'react'
import { CloseIcon, TidBits } from '../assets/svgs/Buttons'
import { popupColorThemes as colorThemes } from '../../constants'

let typeImage
const MiniHotspotsManager = (props) => {
  typeImage = props.type
  const [selected, setSelected] = useState(
    Array(props.hotspots.length).fill(false)
  )
  const handleClick = (text, idx) => {
    setSelected(
      selected.map((x, i) => {
        if (i === idx) return !x
        else return false
      })
    )
    if (props.textObject.body === text.body) {
      props.setText({ title: '', body: '' })
    } else {
      props.setText(text)
    }
  }
  return props.hotspots.map((spot, idx) => {
    return (
      <div
        onClick={() => handleClick({ title: spot.title, body: spot.text }, idx)}
        style={{
          position: 'absolute',
          left: spot.position[0] + '%',
          top: spot.position[1] + '%',
          width: '6.2vh',
        }}
      >
        <TidBits key={idx} selected={selected[idx]} eraid={props.eraid} />
      </div>
    )
  })
}
export const ImagePopup = (props) => {
  const [text, setText] = useState({ title: '', body: '' })
  const [loaded, setLoaded] = useState(false)
  const smallLines = props.subsubtext && props.subsubtext.split('\n')


  return (
    <>
      {props.type === 'image' ? (
        <div className={css(styles.ContainerFlex)}>
          <div className={css(styles.ComicNoOverflow)}>
            <img
              className={css(styles.FlexImage)}
              onLoadedData={() => setLoaded(true)}
              style={{ border: '#FFE7BA 4px solid' }}
              maxWidth="90%"
              src={props.url}
              alt=""
            />
          </div>
          <div className={css(styles.BottomText)}>
            <p className={css(styles.SubText)}>{props.subtext}</p>

            {
              // smallLines && smallLines.map(line=><p className={css(styles.SubSubText)}>{line}</p>)
            }
          </div>
          {loaded && (
            <MiniHotspotsManager
              hotspots={props.hotspots}
              setText={setText}
              eraid={props.eraid}
              textObject={text}
            />
          )}
        </div>
      ) : (
        <div className={css(styles.Comic)}>
          <h3 className={css(styles.Heading)}>{props.title}</h3>
          <p className={css(styles.Text)}>{props.text}</p>
          <p className={css(styles.SubText)}>{props.subtext}</p>
          <p className={css(styles.SubSubText)}>
            <i>{props.subsubtext}</i>
          </p>
          <img
            style={{ marginTop: props.title ? '3%' : '0%' }}
            onLoad={() => setLoaded(true)}
            width={props.name === 'soapline' ? '60%' : '100%'}
            maxWidth="100%"
            src={props.url}
            alt=""
          />
          {loaded && props.hotspots && (
            <MiniHotspotsManager
              hotspots={props.hotspots}
              setText={setText}
              eraid={props.eraid}
              textObject={text}
            />
          )}
        </div>
      )}

      {text.title !== '' && (
        <div
          style={{ backgroundColor: colorThemes[props.eraid] }}
          className={css(styles.TextContainer)}
        >
          <h3 className={css(styles.Heading)}>{text.title}</h3>
          <p className={css(styles.Text)}>{text.body}</p>
        </div>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  TextContainer: {
    // position:'absolute',
    position: 'absolute',
    color: '#FFE7BA',
    // paddingRight:'3%',
    paddingLeft: '5%',
    marginLeft: '0%',
    bottom: '10.2%',
    height: '16%',
    width: '100%',
    fontSize: '100%',
    // overflow:'hidden',
    zIndex: 62,
    fontSize: '3vh',
    overflow: 'auto',
    // backgroundColor:'black'
  },
  Heading: {
    fontFamily: 'Bungee Inline',
    textAlign: 'left',
    color: '#FFE7BA',
    fontSize: 22.5,
    fontWeight: 2.5,
    lineHeight: '15px',
    '@media (max-width: 1100px)': {
      fontSize: 18,
      lineHeight: '12px',
    },
  },
  FlexImage: {
    position: 'relative',
  },
  ContainerFlex: {
    clear: 'both',
    // display:'flex',
    // flexDirection:'column',
    width: '100%',
    // alignContent: 'space-between',
    height: '100%',
    position: 'absolute',
    top: '5.5%',
    // paddingBottom:'5.5%',
    // backgroundColor:'black'
  },
  HeadingSmall: {
    textAlign: 'left',
    color: '#FFE7BA',
    fontSize: 17,
    // fontWeight:2.5,
    lineHeight: '12px',
    fontFamily: 'futura',
    margin: '0',
    paddingTop: '0.4%',
    '@media (max-width: 1100px)': {
      fontSize: 13,
      lineHeight: '9px',
    },
  },
  Text: {
    color: '#FFE7BA',
    fontSize: 16,
    fontWeight: 200,
    fontFamily: 'futura',
    lineHeight: '19px',
    textAlign: 'left',
    margin: 0,
    marginTop: '0.5%',
    width: '93%',
    '@media (max-width: 1100px)': {
      fontSize: 13,
      lineHeight: '16px',
    },
  },

  Comic: {
    // border: '#FFE7BA 4px solid',
    position: 'absolute',
    width: '98.25%',
    height: '90%',
    overflow: 'auto',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%,-49.9%)',
    paddingLeft: '2%',
    paddingRight: '1.5%',
    // marginTop:'0.4%',

    '@media (max-width:900px)': {
      // marginTop:'1.3%',
      height: '84%',
    },
  },
  FlexImage: {
    // position:'absolute',
    // width:window.innerWidth/window.innerHeight<1.6?'95%':''
    maxWidth: '92%',
    maxHeight: '92%',
    // margin:'auto'
    // position:'absolute'
  },
  ComicNoOverflow: {
    position: 'absolute',
    overflow: 'hidden',
    height: '72%',
    width: '97%',
    left: '50%',

    margin: 'auto',
    transform: 'translateX(-50%)',
    // backgroundColor:'red',
    // marginLeft:'2%',
    alignItems: 'center',
  },
  SubText: {
    fontFamily: 'futura',
    margin: 0,
    fontSize: 18,
    fontWeight: 100,
    lineHeight: '19px',
    '@media (max-width: 900px)': {
      fontSize: 14,
      lineHeight: '17px',
    },
  },
  SubText: {
    fontFamily: 'futura',
    marginBottom: 0,
    fontSize: 18,
    fontWeight: 100,
    textAlign: 'left',
    color: '#FFE7BA',
    fontFamily: 'futura',
    // lineHeight:'21px',
    '@media (max-width: 900px)': {
      fontSize: 14,
      // lineHeight:'17px'
    },
  },
  SubSubText: {
    fontFamily: 'futura',
    fontSize: 16,
    margin: 0,
    lineHeight: 19 + 'px',
    color: '#FFE7BA',
    textAlign: 'left',
    '@media (max-width: 900px)': {
      fontSize: 12,
      lineHeight: 14 + 'px',
    },
  },
  BottomText: {
    position: 'absolute',
    bottom: 0,
    textAlign: 'left',
    color: '#FFE7BA',
    // height:'20%',
    marginLeft: '8%',
    marginBottom: '1%',
    '@media (min-width: 1201px)': {
      position: 'absolute',
      // bottom:'7%',
      height: '20%',

      width: '90%',
    },

    '@media (max-width: 1200px)': {
      // marginTop:'7%',
      height: '23%',

      width: '100%',
    },
  },
  BottomTextNotFixed: {
    marginTop: '7%',
    width: '100%',
  },
})
