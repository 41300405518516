import React from 'react'

export const CarouselArrowRight = () => {
    return (
        <svg width='100%' viewBox="0 0 18 35" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.18359 1.01172L15.2687 17.2614L1.18359 33.5112" stroke="#FFE7BA" stroke-width="3"/>
</svg>

    )
}
export const CarouselArrowLeft = () => {
    return (
        <svg width='100%' on viewBox="0 0 19 35" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.9844 33.5117L2.89927 17.262L16.9844 1.01226" stroke="#FFE7BA" stroke-width="3"/>
</svg>


    )
}
