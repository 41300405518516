import React, { useState } from "react";
import { StyleSheet, css } from "aphrodite";
import { MinimapIcon } from "../../assets/svgs/MinimapIcon";
import { isMobile } from "react-device-detect";
import { MinimapSvg } from "../../assets/svgs/MinimapSvg";
import { zindexes } from "../../../constants";
import { useLocations, useSound } from "../../../stores";
import { BoxHelper } from "three";
import { useSpring } from "@react-spring/core";
import { springConfigs } from "../../../constants";
// eraidx-locidx after split
const minimapPositions = [
  ["41", "27"],
  ["109", "-55"],
  ["-10", "-90"],
  // ["-24", "-61"],
  ["156", "-83"],
  ["254", "-89"],
  ["333", "-87"],
  ["247", "-0"],
  ["81", "68"],
  ["332.5", "13.5"],
  ["395", "16"],
  ["384", "64"],
  ["384", "64"],
  ["384", "64"],
  ["384", "64"],
  ["384", "64"],
];
export const Minimap = () => {
  const [showMap, set] = useState(false);
  const locationsState = useLocations();
  const [helperX, setHelperX] = useState(-100);
  const [helperY, setHelperY] = useState(-100);
  const soundState = useSound();
  const [last, setLast] = useState([0, 0]);
  // const position=Object.values(minimapPositions)[locationsState.currentLocation.locationId-1]
  const moveGuy = useSpring({
    transform: `translate(${
      minimapPositions[locationsState.currentLocation.locationId - 1][0]
    },${minimapPositions[locationsState.currentLocation.locationId - 1][1]})`,
    from: {
      transform: `translate(${(last[0], last[1])})`,
    },
    onRest: () =>
      setLast(minimapPositions[locationsState.currentLocation.locationId - 1]),
    config: { ...springConfigs.precision, velocity: 100 },
  });
  return (
    <>
      {/* Minimap Helper */}
      {/* <input value={helperX} onChange={(e)=>setHelperX(e.target.value)} style={{position:'absolute',width:'32vw',height:'4vh',top:'50%',left:'30%',zIndex:120}} type='range' min={-100} max={400} />
            <input value={helperY} onChange={(e)=>setHelperY(e.target.value)} style={{position:'absolute',width:'32vw',height:'4vh',top:'70%',left:'30%',zIndex:120}} type='range' min={-100} max={400} />
    <button style={{position:'absolute',width:'25vw',top:'30%',left:'30%',zIndex:120}} */}

      <div
        onPointerDown={() => soundState.play("hud")}
        className={css(styles.Logo)}
      >
        <MinimapIcon onClick={() => set(true)} />
      </div>
      {
        <div
          onPointerDown={() => soundState.play("close")}
          className={css(styles.Minimap)}
          style={{
            width: isMobile ? "11vw" : "8vw",
            pointerEvents: showMap ? "auto" : "none",
            cursor: 'default'
          }}
        >
          <MinimapSvg
            // position={Object.values(minimapPositions)[locationsState.currentLocation.locationId-1]}
            onClick={() => set(false)}
            moveGuy={moveGuy}
            showMap={showMap}
          />
        </div>
      }
    </>
  );
};

const styles = StyleSheet.create({
  Logo: {
    position: "absolute",
    width: "4.1vw",
    bottom: "3.3vh",
    right: "2vw",
    zIndex: 100,
    "@media (max-width: 900px)": {
      right: "2.5vw",
      bottom: "4vh",
      transform: "scale(1.5)",
      //   top:'34vh'
    },
  },
  Minimap: {
    position: "absolute",
    width: "42vw",
    // bottom:'3vh',
    right: "2vw",
    bottom: "3.3vh",
    zIndex: 120,
    cursor: 'default'
  },
});
