import { css, StyleSheet } from 'aphrodite'
import React, { useRef } from 'react'
import { colorThemes as popupColorThemes } from '../../constants'
import { BackArrow, BetterBackArrow } from '../assets/svgs/BackArrow'
import { animated as a } from '@react-spring/web'
import { useSpring } from '@react-spring/core'
import { useEffect } from 'react'
import { springConfigs } from '../../constants'
import ReactGA from 'react-ga'
import { sendPageView } from '../GA/google-analytics'
export const TimelinesOverview = (props) => {
  const overviewSpring = useSpring({
    opacity: props.showOverview ? 1 : 0,
    config: springConfigs.precision,
  })
  const ref = useRef()
  useEffect(() => {
    function transformScroll(event) {
      if (!event.deltaY) {
        return
      }

      ref.current.scrollLeft += event.deltaY
    }
    sendPageView('quicklook', 'quicklook', '/quicklook')
    // ReactGA.pageview('/quicklook')
    var element = document.scrollingElement || document.documentElement
    element.addEventListener('wheel', transformScroll)

    return () => {
      element.removeEventListener('wheel', transformScroll)
      sendPageView('timelines', 'timelines', '/timelines')
      //   ReactGA.pageview('/timelines')
    }
  }, [])

  return (
    <a.div
      style={{
        opacity: overviewSpring.opacity.to((x) => x),
        pointerEvents: props.showOverview ? 'auto' : 'none',
      }}
      className={css(styles.Container)}
    >
      <div
        onClick={() => props.setShowOverview(false)}
        className={css(styles.Back)}
      >
        <BetterBackArrow noFill />
      </div>
      <div ref={ref} className={css(styles.ImgContainer)}>
        <img
          className={css(styles.Img)}
          src={'./timelinesoverview/Timeline.jpg'}
          height="100%"
        />
      </div>
    </a.div>
  )
}

const styles = StyleSheet.create({
  Container: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: '#FFAE47',
    top: 0,
    left: 0,
    zIndex: 1110,
    overflow: 'hidden',
    // padding:'5%'
  },
  Back: {
    position: 'absolute',
    // zIndex:50,
    width: '6.5%',
    overflow: 'visible',
    left: '1vw',
    top: '1.9vh',
    zIndex: 1200,
    // height:'1vw'
  },
  Img: {
    paddingTop: '0',
    paddingBottom: '1%',
  },
  ImgContainer: {
    position: 'absolute',
    width: '100%',
    height: '98%',
    backgroundColor: '#FFAE47',
    top: 0,
    left: 0,
    marginBottom: '2%',
    overflowX: 'auto',
    overflowY: 'hidden',
  },
})
