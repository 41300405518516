import React from 'react'
import { animated as a } from '@react-spring/web'
export const ValueWheelSVG = (props) => {
    return (
        <a.svg style={props.style} width="100%" height="100%" viewBox="0 0 1421 1420" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M391.977 974.629C246.65 800.079 272.153 540.097 448.939 393.941C625.725 247.786 886.849 270.804 1032.18 445.353C1177.5 619.903 1152 879.885 975.215 1026.04C798.429 1172.2 537.305 1149.18 391.977 974.629ZM785.395 674.828C804.202 715.86 785.872 764.592 744.453 783.674C703.035 802.755 654.212 784.961 635.404 743.929C616.596 702.897 634.926 654.165 676.345 635.084C717.764 616.002 766.587 633.796 785.395 674.828ZM654.401 582.883C712.875 555.945 738.753 487.147 712.201 429.22C685.649 371.292 616.722 346.171 558.249 373.109C499.775 400.048 473.897 468.846 500.449 526.773C527.001 584.701 595.928 609.822 654.401 582.883ZM1009.04 488.592C1035.59 546.52 1009.71 615.318 951.239 642.256C892.765 669.195 823.839 644.074 797.287 586.146C770.735 528.219 796.613 459.421 855.086 432.482C913.56 405.544 982.486 430.665 1009.04 488.592ZM983.52 937.889C1041.99 910.951 1067.87 842.153 1041.32 784.225C1014.77 726.298 945.841 701.177 887.368 728.115C828.894 755.054 803.016 823.852 829.568 881.779C856.12 939.707 925.047 964.828 983.52 937.889ZM766.547 910.813C793.099 968.74 767.222 1037.54 708.748 1064.48C650.274 1091.42 581.348 1066.29 554.796 1008.37C528.244 950.439 554.122 881.642 612.595 854.703C671.069 827.764 739.996 852.885 766.547 910.813ZM504.565 845.491C563.039 818.553 588.917 749.755 562.365 691.827C535.813 633.9 466.886 608.779 408.413 635.717C349.939 662.656 324.061 731.454 350.613 789.381C377.165 847.309 446.092 872.43 504.565 845.491Z" fill="#FFE7BA"/>
<path d="M1135.38 514.236C1243.29 749.736 1140.56 1028.25 905.993 1136.35C671.425 1244.45 393.769 1141.22 285.855 905.721C177.942 670.221 280.672 391.703 515.24 283.607C749.808 175.511 1027.46 278.736 1135.38 514.236Z" stroke="#FFE7BA" stroke-width="18"/>
<ellipse rx="63.1428" ry="62.4977" transform="matrix(0.908249 -0.41843 0.416677 0.909055 710.399 709.379)" fill="#FFE7BA"/>
<path d="M894.931 494.981L889.327 495.006L888.812 608.394L894.416 608.369L894.931 494.981Z" fill="#FFE7BA"/>
<path d="M906.129 494.93L900.525 494.955L900.011 608.343L905.615 608.318L906.129 494.93Z" fill="#FFE7BA"/>
<path d="M917.33 494.879L911.726 494.905L911.212 608.293L916.816 608.267L917.33 494.879Z" fill="#FFE7BA"/>
<path d="M928.537 494.837L922.927 494.862L922.413 608.25L928.023 608.225L928.537 494.837Z" fill="#FFE7BA"/>
<path d="M928.686 462.07L928.564 488.988L873.707 489.236L891.188 462.239L928.686 462.07Z" fill="#FFE7BA"/>
<path d="M905.747 888.361L880.391 896.504L864.519 846.329L895.569 856.189L905.747 888.361Z" fill="#FFE7BA"/>
<path d="M940.38 831.488L916.574 823.991C916.434 813.227 920.084 802.719 926.891 794.287C933.699 785.856 943.234 780.032 953.847 777.824C964.46 775.616 975.481 777.164 985.002 782.198C994.523 787.233 1001.94 795.438 1005.98 805.391L981.42 813.278C979.587 809.777 976.777 806.883 973.325 804.938C969.873 802.993 965.923 802.079 961.945 802.306C957.967 802.533 954.127 803.89 950.882 806.217C947.638 808.544 945.124 811.742 943.64 815.432C941.682 820.579 940.581 826.003 940.38 831.488V831.488Z" fill="#FFE7BA"/>
<path d="M976.826 866.85C967.191 869.96 956.826 869.849 947.32 866.532L862.425 839.7L852.997 809.939L917.09 830.197C917.09 830.197 956.952 842.862 957.851 843.078C961.143 843.876 964.587 843.858 967.885 843.027C971.182 842.196 974.232 840.577 976.769 838.311C979.306 836.045 981.253 833.2 982.44 830.026C983.627 826.852 984.017 823.444 983.578 820.1L1008.13 812.215C1010.75 823.507 1008.98 835.405 1003.18 845.533C997.373 855.661 987.963 863.273 976.826 866.85Z" fill="#FFE7BA"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M692.267 995.375C695.55 992.071 697.611 987.761 698.109 983.159C698.607 978.556 697.513 973.937 695.006 970.065L715.53 955.531C721.848 964.794 724.452 976.097 722.836 987.237C721.221 998.377 715.5 1008.56 706.789 1015.8L692.267 995.375Z" fill="#FFE7BA"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M636.835 917.42L622.313 896.998C632.169 891.103 643.863 889.103 655.062 891.397C666.26 893.691 676.138 900.111 682.724 909.374C683.44 910.38 684.104 911.4 684.723 912.444L664.056 927.079C662.927 924.657 661.327 922.485 659.346 920.687C657.366 918.89 655.044 917.502 652.515 916.605C649.985 915.707 647.298 915.317 644.607 915.457C641.917 915.597 639.275 916.264 636.835 917.42V917.42Z" fill="#FFE7BA"/>
<path d="M632.383 979.175C624.5 976.884 617.409 972.502 611.866 966.496C606.322 960.49 602.535 953.086 600.908 945.072C599.281 937.058 599.875 928.736 602.626 920.992C605.378 913.248 610.184 906.373 616.531 901.1L631.05 921.518C628.5 924.086 626.674 927.274 625.759 930.759C624.844 934.243 624.872 937.898 625.841 941.351C626.81 944.804 628.684 947.931 631.274 950.414C633.863 952.896 637.074 954.645 640.579 955.481C641.564 955.712 642.565 955.873 643.574 955.961L657.319 946.227L671.658 966.391L658.122 975.977C657.829 977.061 657.622 978.166 657.503 979.282C657.137 982.86 657.731 986.462 659.224 989.72C660.717 992.979 663.056 995.777 666.002 997.83C668.948 999.883 672.396 1001.12 675.994 1001.4C679.592 1001.69 683.211 1001.03 686.482 999.472L701.001 1019.89C693.917 1024.13 685.827 1026.38 677.594 1026.42C669.36 1026.45 661.292 1024.26 654.249 1020.08C647.207 1015.91 641.453 1009.89 637.602 1002.69C633.752 995.484 631.948 987.357 632.383 979.175V979.175Z" fill="#FFE7BA"/>
<path d="M373.557 771.984L389.864 750.481L403.562 760.707L373.557 771.984Z" fill="#FFE7BA"/>
<path d="M465.018 725.958L503.724 754.854L520.219 733.103L481.513 704.206L465.018 725.958Z" fill="#FFE7BA"/>
<path d="M414.087 687.934L424.225 695.503L440.72 673.751L430.582 666.183L414.087 687.934Z" fill="#FFE7BA"/>
<path d="M379.002 776.169L474.406 740.31L500.164 759.539L404.75 795.4L404.73 795.353L404.718 795.368L379.002 776.169Z" fill="#FFE7BA"/>
<path d="M432.177 749.949L489.111 674.873L493.57 678.202L441.946 746.277L432.177 749.949Z" fill="#FFE7BA"/>
<path d="M412.639 757.295L480.193 668.215L484.652 671.544L422.408 753.622L412.639 757.295Z" fill="#FFE7BA"/>
<path d="M402.343 752.454L406.802 755.783L475.734 664.886L471.275 661.557L402.343 752.454Z" fill="#FFE7BA"/>
<path d="M393.42 745.792L397.884 749.125L466.824 658.217L462.359 654.884L393.42 745.792Z" fill="#FFE7BA"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M593.845 520.635C593.849 520.643 593.855 520.645 593.858 520.653L601.633 498.79L601.617 498.778L593.845 520.635ZM584.562 469.892C583.476 472.937 582.678 476.074 582.179 479.262L604.067 491.89L608.911 478.268C611.143 471.988 615.682 466.763 621.608 463.65C627.535 460.537 634.409 459.768 640.84 461.497C647.271 463.227 652.781 467.327 656.255 472.968C659.729 478.61 660.909 485.372 659.556 491.889L684.018 500.304C687.651 487.308 686.081 473.462 679.64 461.685C673.199 449.909 662.389 441.122 649.491 437.178C636.593 433.234 622.613 434.44 610.5 440.543C598.387 446.646 589.088 457.169 584.562 469.892V469.892ZM624.932 511.595C623.138 510.979 621.419 510.172 619.805 509.186L611.062 533.772C617.392 536.75 624.273 538.422 631.292 538.686C638.311 538.95 645.325 537.801 651.913 535.308C658.5 532.815 664.526 529.029 669.629 524.178C674.732 519.326 678.808 513.508 681.611 507.073L657.154 498.66C654.223 504.308 649.346 508.73 643.423 511.107C637.5 513.485 630.932 513.658 624.932 511.595V511.595Z" fill="#FFE7BA"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M557.896 418.046L582.904 426.649L564.801 477.556L601.636 498.724L592.836 523.472L532.677 488.899L557.896 418.046Z" fill="#FFE7BA"/>
</a.svg>



    )
}

export const SelectedCircle = () => {
    return (
        <svg width="241%" height="241%" viewBox="0 0 241 241" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="120.51" cy="120.509" r="120.275" transform="rotate(-24.6805 120.51 120.509)" fill="#CE492A"/>
</svg>

    )
}

export const ClickToRotate = () => (
    <svg width="100%" height="27%" viewBox="0 0 133 37" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.75269 31.7617C3.75269 31.7617 23.3079 25.7149 63.9608 23.7308C104.614 21.7467 131.114 26.4039 131.114 26.4039L108.962 10.0811" stroke="#FFE7BA" stroke-width="4"/>
<path d="M6.61686 23.1871C6.08878 23.2864 5.58929 23.279 5.11838 23.1649C4.64747 23.0509 4.21975 22.8406 3.83521 22.5341C3.45798 22.2174 3.13731 21.8064 2.87322 21.301C2.61643 20.7855 2.43933 20.1845 2.34192 19.498C2.24572 18.8202 2.25296 18.1933 2.36364 17.6175C2.47431 17.0417 2.66894 16.5338 2.94755 16.0937C3.22615 15.6537 3.57716 15.2926 4.00057 15.0103C4.42277 14.7195 4.90216 14.5236 5.43876 14.4227C5.9498 14.3266 6.41218 14.3498 6.82591 14.4922C7.24693 14.6245 7.60042 14.8047 7.88639 15.0328L7.41009 15.836C7.15455 15.6374 6.87589 15.4915 6.57412 15.3985C6.27235 15.3055 5.94259 15.2926 5.58486 15.3599C5.18455 15.4352 4.83413 15.5848 4.5336 15.8087C4.23186 16.024 3.98731 16.3035 3.79996 16.647C3.61139 16.982 3.48306 17.3717 3.41496 17.8162C3.35538 18.2591 3.36273 18.7423 3.43701 19.2657C3.5125 19.7977 3.64031 20.267 3.82043 20.6736C4.00786 21.07 4.2336 21.3976 4.49767 21.6563C4.76174 21.9149 5.06291 22.0962 5.40119 22.1999C5.74798 22.3021 6.12154 22.3155 6.52186 22.2403C6.93069 22.1634 7.27685 22.0146 7.56034 21.7939C7.84261 21.5646 8.09872 21.2742 8.32866 20.9226L9.01133 21.4814C8.73395 21.93 8.4018 22.3008 8.01489 22.5938C7.62798 22.8868 7.16197 23.0846 6.61686 23.1871Z" fill="#FFE7BA"/>
<path d="M11.7451 21.8165C11.3439 21.8874 11.0414 21.8178 10.8375 21.6075C10.6336 21.3973 10.4995 21.0604 10.4354 20.5969L9.34193 12.6901L10.3917 12.5045L11.4958 20.4885C11.5196 20.6602 11.5661 20.7752 11.6353 20.8333C11.7045 20.8914 11.7776 20.9137 11.8544 20.9001C11.8885 20.8941 11.9184 20.8888 11.944 20.8842C11.9781 20.8782 12.0239 20.8613 12.0812 20.8336L12.3325 21.6071C12.269 21.6535 12.1914 21.6936 12.0999 21.7274C12.0084 21.7611 11.8901 21.7908 11.7451 21.8165Z" fill="#FFE7BA"/>
<path d="M14.8928 21.1189L14.047 14.8578L15.0982 14.6807L15.944 20.9418L14.8928 21.1189ZM14.4114 13.4788C14.2063 13.5133 14.0224 13.4785 13.8597 13.3741C13.7056 13.2683 13.6151 13.1167 13.5885 12.9191C13.5606 12.713 13.6069 12.5383 13.7274 12.395C13.8553 12.2417 14.0218 12.1478 14.2269 12.1132C14.432 12.0786 14.6117 12.1142 14.7658 12.22C14.9273 12.3158 15.022 12.4667 15.0499 12.6729C15.0765 12.8704 15.0259 13.0458 14.8981 13.1991C14.7787 13.351 14.6165 13.4442 14.4114 13.4788Z" fill="#FFE7BA"/>
<path d="M21.6639 20.1765C21.2531 20.2418 20.8583 20.2299 20.4797 20.1409C20.101 20.052 19.7609 19.8911 19.4595 19.6583C19.1654 19.4155 18.9149 19.1044 18.7078 18.725C18.5081 18.3357 18.3744 17.8832 18.3067 17.3677C18.2378 16.8435 18.2582 16.3666 18.3678 15.9369C18.4762 15.4986 18.6488 15.1159 18.8854 14.7888C19.1221 14.4617 19.4106 14.1966 19.7509 13.9934C20.0997 13.7889 20.4753 13.6546 20.8776 13.5907C21.2885 13.5254 21.649 13.5427 21.9592 13.6426C22.2694 13.7424 22.5487 13.8735 22.7972 14.0358L22.3494 14.8174C22.1482 14.6827 21.9381 14.5801 21.7192 14.5097C21.5088 14.4378 21.2795 14.4216 21.0312 14.4611C20.7488 14.5059 20.4956 14.6076 20.2718 14.7659C20.0468 14.9157 19.8628 15.1116 19.7198 15.3536C19.5768 15.5957 19.4738 15.8752 19.4105 16.1923C19.3548 16.4994 19.3506 16.8334 19.398 17.1943C19.4454 17.5552 19.5353 17.8742 19.6679 18.1514C19.8004 18.4286 19.9621 18.6617 20.1529 18.8507C20.3512 19.0297 20.5781 19.1604 20.8335 19.2426C21.0878 19.3162 21.3562 19.3306 21.6387 19.2857C21.9297 19.2395 22.1872 19.1372 22.411 18.9788C22.6423 18.8105 22.8451 18.6248 23.0194 18.4216L23.5747 19.0571C23.325 19.3512 23.0385 19.5985 22.7153 19.7989C22.391 19.9908 22.0406 20.1167 21.6639 20.1765Z" fill="#FFE7BA"/>
<path d="M24.9024 19.5275L23.7357 10.3453L24.7774 10.1912L25.5672 16.4073L25.6058 16.4016L27.8483 12.7064L29.0186 12.5333L27.242 15.3581L30.0979 18.7591L28.9405 18.9303L26.7309 16.1826L25.7344 17.7227L25.9441 19.3734L24.9024 19.5275Z" fill="#FFE7BA"/>
<path d="M36.4516 18.0862C36.1164 18.13 35.8266 18.1154 35.5822 18.0424C35.3463 17.9683 35.1444 17.8505 34.9766 17.6889C34.8174 17.5261 34.6921 17.3196 34.6009 17.0693C34.5097 16.819 34.4455 16.539 34.4082 16.2292L33.9905 12.7573L33.0624 12.8785L32.9661 12.0782L33.938 11.8858L33.8558 10.1136L34.7452 9.99748L34.9564 11.7528L36.6451 11.5324L36.7491 12.3971L35.0604 12.6176L35.4797 16.1025C35.5263 16.4897 35.6313 16.7819 35.7946 16.979C35.9656 17.1665 36.2315 17.2366 36.5924 17.1895C36.7042 17.1749 36.8224 17.142 36.9472 17.0907C37.0719 17.0395 37.1833 16.9856 37.2812 16.9291L37.5838 17.7024C37.4191 17.7851 37.2363 17.8614 37.0352 17.9314C36.8438 18.0088 36.6492 18.0604 36.4516 18.0862Z" fill="#FFE7BA"/>
<path d="M41.7027 17.4458C41.3155 17.4923 40.937 17.4635 40.5672 17.3596C40.206 17.2546 39.876 17.0803 39.5772 16.8369C39.2775 16.5848 39.0223 16.2663 38.8118 15.8814C38.6089 15.4867 38.4774 15.0312 38.4174 14.5147C38.3563 13.9895 38.3798 13.5153 38.4876 13.0921C38.6031 12.6593 38.7777 12.2848 39.0114 11.9687C39.2452 11.6525 39.5256 11.4006 39.8528 11.2129C40.1886 11.0242 40.5501 10.9066 40.9373 10.8601C41.3245 10.8136 41.6987 10.8428 42.0599 10.9479C42.4297 11.0518 42.7645 11.2298 43.0643 11.4819C43.3641 11.7339 43.6154 12.0573 43.8183 12.4519C44.0288 12.8369 44.1646 13.2919 44.2256 13.817C44.2857 14.3336 44.258 14.8083 44.1425 15.2411C44.0346 15.6643 43.8638 16.034 43.6301 16.3501C43.3954 16.6576 43.1101 16.9058 42.7743 17.0945C42.4471 17.2822 42.0899 17.3993 41.7027 17.4458ZM41.6007 16.5677C41.8674 16.5356 42.1061 16.4502 42.3169 16.3114C42.5266 16.1641 42.6969 15.9778 42.8278 15.7525C42.9662 15.5177 43.0615 15.2487 43.1135 14.9457C43.1655 14.6427 43.1705 14.3104 43.1285 13.9488C43.0865 13.5872 43.0055 13.2653 42.8854 12.9829C42.7643 12.692 42.6095 12.4487 42.421 12.2531C42.2411 12.0565 42.0328 11.9157 41.7961 11.8306C41.5583 11.737 41.3061 11.7062 41.0394 11.7382C40.7726 11.7702 40.5344 11.8599 40.3247 12.0073C40.1139 12.1461 39.9393 12.3329 39.8009 12.5678C39.671 12.8016 39.5805 13.0743 39.5295 13.386C39.4775 13.689 39.4725 14.0213 39.5145 14.3829C39.5565 14.7444 39.6376 15.0664 39.7576 15.3488C39.8777 15.6311 40.0277 15.8706 40.2076 16.0672C40.3951 16.2542 40.6077 16.3945 40.8454 16.4882C41.0822 16.5732 41.3339 16.5997 41.6007 16.5677Z" fill="#FFE7BA"/>
<path d="M49.3141 16.455L48.6276 10.1744L49.507 10.0843L49.7218 11.2123L49.7606 11.2083C49.9328 10.7899 50.1571 10.449 50.4334 10.1855C50.7184 9.92105 51.0419 9.7703 51.404 9.73321C51.654 9.7076 51.8829 9.72771 52.0906 9.79355L51.9854 10.7452C51.8782 10.7213 51.7805 10.7052 51.6924 10.6968C51.6043 10.6884 51.4956 10.6908 51.3663 10.7041C51.099 10.7315 50.8306 10.8679 50.561 11.1133C50.3 11.3578 50.091 11.7582 49.9338 12.3144L50.3745 16.3464L49.3141 16.455Z" fill="#FFE7BA"/>
<path d="M56.5767 15.9132C56.1883 15.9489 55.8095 15.9098 55.4402 15.7959C55.0796 15.6811 54.751 15.4982 54.4545 15.247C54.157 14.9872 53.905 14.6623 53.6984 14.2723C53.4995 13.8728 53.3728 13.4146 53.3184 12.8974C53.2631 12.3717 53.2918 11.8991 53.4045 11.4797C53.5249 11.0509 53.7039 10.6819 53.9415 10.3729C54.1792 10.0638 54.4628 9.82015 54.7926 9.64185C55.1311 9.46275 55.4944 9.35533 55.8828 9.31958C56.2712 9.28383 56.6456 9.32333 57.0063 9.43808C57.3755 9.55204 57.7089 9.73891 58.0064 9.99872C58.3038 10.2585 58.552 10.5881 58.7509 10.9875C58.9575 11.3775 59.0885 11.8354 59.1438 12.3612C59.1982 12.8783 59.1652 13.3513 59.0448 13.7801C58.932 14.1995 58.7569 14.5637 58.5193 14.8728C58.2808 15.1732 57.9923 15.413 57.6539 15.5921C57.3241 15.7704 56.965 15.8774 56.5767 15.9132ZM56.4841 15.034C56.7517 15.0094 56.9917 14.9307 57.2043 14.798C57.416 14.6567 57.5887 14.4755 57.7222 14.2543C57.8635 14.0237 57.9619 13.758 58.0174 13.457C58.0728 13.156 58.0815 12.8245 58.0434 12.4625C58.0053 12.1005 57.9277 11.7769 57.8106 11.4918C57.6925 11.1981 57.5402 10.951 57.3535 10.7507C57.1755 10.5495 56.9684 10.4032 56.7322 10.3118C56.4952 10.2118 56.2429 10.1741 55.9753 10.1987C55.7078 10.2234 55.4682 10.3063 55.2565 10.4476C55.0439 10.5804 54.8669 10.762 54.7256 10.9926C54.593 11.2223 54.4994 11.492 54.4448 11.8016C54.3893 12.1026 54.3807 12.4341 54.4188 12.7961C54.4568 13.1581 54.5345 13.4817 54.6516 13.7668C54.7687 14.0519 54.9163 14.295 55.0943 14.4962C55.2801 14.688 55.4915 14.8339 55.7286 14.9339C55.9648 15.0253 56.2166 15.0587 56.4841 15.034Z" fill="#FFE7BA"/>
<path d="M62.3619 15.4293C62.025 15.4569 61.7347 15.4284 61.4911 15.344C61.256 15.2589 61.0559 15.1318 60.8907 14.9627C60.7342 14.7929 60.6126 14.5811 60.526 14.3274C60.4394 14.0736 60.3803 13.7916 60.3489 13.4812L59.997 10.0019L59.0641 10.0781L58.983 9.27621L59.9611 9.1311L59.9128 7.36149L60.8068 7.28847L60.9847 9.04749L62.6821 8.90885L62.7697 9.77542L61.0724 9.91407L61.4257 13.4062C61.4649 13.7943 61.5646 14.0904 61.7246 14.2947C61.8923 14.4896 62.1576 14.5723 62.5204 14.5427C62.6327 14.5335 62.7519 14.5064 62.878 14.4613C63.0041 14.4162 63.1167 14.3679 63.216 14.3163L63.5045 15.1013C63.3378 15.1758 63.153 15.243 62.9501 15.3031C62.7566 15.371 62.5606 15.4131 62.3619 15.4293Z" fill="#FFE7BA"/>
<path d="M66.6407 15.1161C66.1134 15.1538 65.6575 15.0299 65.2729 14.7447C64.8969 14.4588 64.6813 14.0312 64.6261 13.4619C64.5591 12.7718 64.8148 12.2237 65.3934 11.8176C65.9712 11.4029 66.9273 11.0741 68.2616 10.8313C68.2423 10.6329 68.2019 10.4404 68.1402 10.2537C68.0872 10.0664 68.006 9.90277 67.8967 9.76291C67.7874 9.62305 67.6421 9.51616 67.4608 9.44223C67.2874 9.35906 67.071 9.32672 66.8116 9.34523C66.4485 9.37113 66.1138 9.4645 65.8073 9.62533C65.5009 9.78617 65.2307 9.96179 64.9967 10.1522L64.5102 9.44426C64.7857 9.23352 65.1268 9.02678 65.5337 8.82404C65.9397 8.61267 66.3977 8.4888 66.9077 8.45241C67.6771 8.39753 68.2577 8.59497 68.6496 9.04473C69.0406 9.48588 69.2742 10.0989 69.3504 10.8839L69.7248 14.7398L68.843 14.8027L68.6794 14.0587L68.6405 14.0615C68.3622 14.3332 68.059 14.572 67.7308 14.7778C67.4017 14.975 67.0384 15.0878 66.6407 15.1161ZM66.869 14.2399C67.1716 14.2184 67.4502 14.129 67.7048 13.9719C67.9585 13.8061 68.2233 13.5744 68.499 13.2768L68.3294 11.53C67.8088 11.6366 67.3716 11.7503 67.0177 11.8711C66.6725 11.9913 66.3938 12.1241 66.1816 12.2695C65.978 12.4143 65.8327 12.5767 65.7458 12.7566C65.6666 12.9273 65.6366 13.1118 65.6559 13.3102C65.6911 13.6725 65.8242 13.9236 66.0554 14.0635C66.2866 14.2033 66.5578 14.2622 66.869 14.2399Z" fill="#FFE7BA"/>
<path d="M73.4361 14.68C73.0988 14.7006 72.8085 14.6662 72.5653 14.5769C72.3307 14.4871 72.1315 14.356 71.9676 14.1838C71.8123 14.011 71.6924 13.797 71.6077 13.5419C71.5231 13.2867 71.4663 13.0038 71.4374 12.6931L71.1135 9.21113L70.1792 9.26813L70.1046 8.46559L71.0847 8.3407L71.0507 6.57239L71.9461 6.51776L72.1098 8.27816L73.8097 8.17444L73.8903 9.0417L72.1905 9.14541L72.5156 12.6403C72.5517 13.0286 72.6491 13.3265 72.8075 13.5337C72.9739 13.7319 73.2387 13.8199 73.602 13.7977C73.7145 13.7908 73.834 13.7662 73.9605 13.7237C74.0871 13.6813 74.2002 13.6354 74.3 13.5859L74.5823 14.3757C74.4149 14.4467 74.2294 14.5101 74.0258 14.5659C73.8317 14.6298 73.6351 14.6678 73.4361 14.68Z" fill="#FFE7BA"/>
<path d="M78.8267 14.3991C78.4026 14.4206 77.9979 14.3674 77.6127 14.2395C77.2353 14.1026 76.8958 13.903 76.5943 13.6408C76.3006 13.3695 76.0578 13.035 75.866 12.6371C75.6741 12.2393 75.5551 11.7814 75.509 11.2635C75.4629 10.7455 75.4997 10.2797 75.6194 9.86609C75.747 9.44338 75.928 9.08301 76.1623 8.78497C76.4053 8.48649 76.689 8.25531 77.0135 8.09144C77.3373 7.91893 77.6809 7.82346 78.0445 7.80503C78.4426 7.78484 78.8036 7.8359 79.1275 7.95822C79.4601 8.0801 79.7417 8.26526 79.9725 8.51371C80.2119 8.76171 80.404 9.06414 80.5488 9.42101C80.6936 9.77787 80.7859 10.1807 80.8259 10.6296C80.8359 10.7419 80.8415 10.8543 80.8428 10.967C80.852 11.0706 80.8515 11.1616 80.8411 11.2402L76.5825 11.4562C76.6857 12.1273 76.9411 12.652 77.3486 13.0302C77.764 13.3994 78.2746 13.5686 78.8805 13.5379C79.1835 13.5225 79.4566 13.4653 79.6999 13.3662C79.9511 13.2581 80.1871 13.1247 80.4077 12.9661L80.8464 13.6463C80.5848 13.833 80.291 13.9996 79.9649 14.1462C79.6389 14.2928 79.2595 14.3771 78.8267 14.3991ZM76.5016 10.6928L79.8772 10.5217C79.8204 9.88285 79.6389 9.40645 79.3328 9.09245C79.0345 8.76939 78.6387 8.62037 78.1453 8.64539C77.9203 8.6568 77.7077 8.71093 77.5077 8.8078C77.3163 8.90422 77.146 9.04292 76.9969 9.22391C76.847 9.39627 76.7266 9.60616 76.6356 9.85358C76.5532 10.1006 76.5086 10.3803 76.5016 10.6928Z" fill="#FFE7BA"/>
</svg>

)
export const CurvedText = () => {
    return <svg width='100%' viewBox="0 0 69 215" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.20646 12.1881L21.1295 4.47824L22.2771 7.91573L15.6759 11.2582C14.9323 11.6077 14.1836 11.9673 13.4299 12.337C12.6914 12.7016 11.9427 13.0612 11.1839 13.4158L11.2143 13.5068C12.0542 13.3951 12.8839 13.2783 13.7035 13.1565C14.5433 13.0448 15.3756 12.9356 16.2003 12.829L23.6756 12.1045L24.6256 14.9501L18.2143 18.8617C17.5062 19.2668 16.7803 19.6694 16.0367 20.0694C15.3134 20.4796 14.5749 20.8948 13.8212 21.3151L13.8516 21.4062C14.6914 21.2439 15.5135 21.0791 16.3179 20.9117C17.1274 20.7595 17.9369 20.6074 18.7464 20.4552L26.0316 19.1615L27.1033 22.3714L11.379 24.686L9.97295 20.4745L16.2249 16.7679C16.7611 16.454 17.2998 16.1476 17.841 15.8488C18.4025 15.5602 18.9438 15.2867 19.4648 15.0284L19.4344 14.9373C18.8576 15.0287 18.2556 15.12 17.6282 15.2114C17.0211 15.3129 16.4089 15.3992 15.7917 15.4703L8.5897 16.3313L7.20646 12.1881Z" fill="#FFE7BA"/>
<path d="M12.856 29.7902L27.9383 25.6208L28.8783 29.0213L23.0721 30.6264L24.5109 35.8312L30.3171 34.2261L31.2636 37.6497L16.1812 41.8191L15.2348 38.3955L21.5268 36.6561L20.088 31.4513L13.796 33.1907L12.856 29.7902Z" fill="#FFE7BA"/>
<path d="M17.4676 45.9193L32.7193 42.4199L34.9091 51.9639L32.0084 52.6295L30.6076 46.5241L27.5432 47.2272L28.7347 52.4203L25.8575 53.0804L24.666 47.8874L21.1572 48.6924L22.6117 55.0317L19.711 55.6972L17.4676 45.9193Z" fill="#FFE7BA"/>
<path d="M20.6444 59.8597L35.9981 56.8387L37.8885 66.4465L34.9684 67.021L33.7591 60.8749L30.6743 61.4819L31.7029 66.7096L28.8064 67.2795L27.7778 62.0518L24.2455 62.7468L25.5012 69.1284L22.5812 69.7029L20.6444 59.8597Z" fill="#FFE7BA"/>
<path d="M23.5415 73.455L39.003 71.0462L39.5461 74.5322L27.0251 76.4828L27.9783 82.601L25.0378 83.0591L23.5415 73.455Z" fill="#FFE7BA"/>
<path d="M27.2619 98.1774C27.1144 97.1317 27.1614 96.1475 27.4028 95.2247C27.6624 94.3156 28.0971 93.5029 28.7071 92.7867C29.317 92.0704 30.0964 91.4676 31.0454 90.9783C31.9966 90.5048 33.1059 90.1786 34.3733 89.9999C35.6408 89.8211 36.7891 89.8287 37.8183 90.0229C38.8656 90.2306 39.7655 90.5965 40.5181 91.1205C41.2865 91.6423 41.9054 92.3064 42.3746 93.1127C42.8619 93.9327 43.1793 94.8655 43.3268 95.9112C43.4743 96.9568 43.4262 97.9331 43.1825 98.84C42.9569 99.7606 42.547 100.578 41.9529 101.292C41.3588 102.006 40.5862 102.6 39.635 103.073C38.7019 103.56 37.6096 103.892 36.3579 104.069C35.0905 104.247 33.9331 104.233 32.8858 104.025C31.8408 103.833 30.9261 103.477 30.1419 102.958C29.3576 102.438 28.7139 101.77 28.2108 100.952C27.7257 100.148 27.4094 99.223 27.2619 98.1774ZM30.28 97.7516C30.4297 98.8131 30.9997 99.5891 31.9899 100.08C32.996 100.568 34.2833 100.701 35.8517 100.48C37.4044 100.261 38.5728 99.7812 39.357 99.0404C40.1412 98.2995 40.4584 97.3984 40.3087 96.3369C40.159 95.2754 39.6048 94.4972 38.6463 94.0022C37.6878 93.5073 36.4322 93.3693 34.8796 93.5883C33.3111 93.8096 32.111 94.294 31.2792 95.0415C30.4633 95.7867 30.1303 96.6901 30.28 97.7516Z" fill="#FFE7BA"/>
<path d="M28.475 107.462L44.0341 105.797L45.0841 115.605L42.125 115.922L41.4506 109.622L37.8472 110.007L38.4245 115.4L35.4654 115.717L34.8881 110.324L28.8506 110.97L28.475 107.462Z" fill="#FFE7BA"/>
<path d="M30.3414 134.564L45.8391 129.364L45.9524 133.106L38.7164 135.198C37.8601 135.464 37.0268 135.698 36.2165 135.898C35.4227 136.115 34.5976 136.356 33.7412 136.622L33.7441 136.718C34.6145 136.915 35.4524 137.098 36.2579 137.266C37.0793 137.433 37.9252 137.616 38.7956 137.813L46.1442 139.44L46.2532 143.038L30.47 138.81L30.3414 134.564Z" fill="#FFE7BA"/>
<path d="M38.3289 148.623L36.8875 148.245L36.8998 151.581L38.3385 151.215C39.1217 150.988 39.9529 150.769 40.8321 150.558C41.7113 150.347 42.5586 150.136 43.3738 149.925L43.3734 149.829C42.5407 149.64 41.684 149.443 40.8032 149.238C39.9385 149.049 39.1138 148.844 38.3289 148.623ZM30.3881 142.989L46.0541 147.827L46.0697 152.075L30.4399 157.028L30.4261 153.284L34.1425 152.311L34.1249 147.535L30.4014 146.588L30.3881 142.989Z" fill="#FFE7BA"/>
<path d="M30.4809 159.128L46.1166 159.747L45.9769 163.273L33.3149 162.771L33.0697 168.958L30.096 168.84L30.4809 159.128Z" fill="#FFE7BA"/>
<path d="M29.5927 176.449C29.7252 174.421 30.4252 172.911 31.6926 171.92C32.96 170.929 34.9029 170.518 37.5213 170.69L45.9034 171.238L45.6717 174.782L36.9303 174.211C35.3976 174.11 34.3127 174.272 33.6758 174.695C33.0388 175.119 32.6916 175.769 32.6342 176.647C32.5768 177.526 32.8358 178.224 33.4112 178.743C33.9865 179.261 35.0406 179.571 36.5733 179.671L45.3147 180.242L45.0923 183.643L36.7102 183.095C34.0918 182.924 32.2183 182.272 31.0898 181.14C29.9602 180.024 29.4612 178.46 29.5927 176.449Z" fill="#FFE7BA"/>
<path d="M29.0661 185.145L44.6473 186.589L43.7436 196.339L40.7803 196.064L41.3584 189.827L38.2278 189.537L37.7361 194.842L34.7967 194.57L35.2884 189.265L31.7038 188.932L31.1036 195.409L28.1403 195.134L29.0661 185.145Z" fill="#FFE7BA"/>
<path d="M26.936 204.478C26.9524 203.454 27.1529 202.433 27.5373 201.415C27.9375 200.413 28.5199 199.519 29.2847 198.731L31.676 200.785C31.187 201.338 30.7852 201.947 30.4704 202.614C30.1554 203.297 29.9926 203.967 29.982 204.623C29.9702 205.359 30.0974 205.905 30.3637 206.261C30.6298 206.633 30.9948 206.823 31.4587 206.831C31.6987 206.835 31.9076 206.782 32.0853 206.673C32.2629 206.58 32.4173 206.43 32.5486 206.224C32.6957 206.035 32.8275 205.797 32.9441 205.51C33.0765 205.241 33.2174 204.939 33.3668 204.605L34.2634 202.579C34.4299 202.182 34.6441 201.793 34.9063 201.413C35.1682 201.05 35.4695 200.718 35.8104 200.42C36.167 200.138 36.5706 199.912 37.0214 199.743C37.4882 199.575 38.0095 199.495 38.5854 199.504C39.2254 199.515 39.8231 199.66 40.3787 199.941C40.9502 200.223 41.44 200.614 41.8479 201.117C42.2719 201.62 42.5985 202.209 42.8276 202.885C43.0565 203.577 43.1644 204.331 43.1513 205.147C43.1367 206.058 42.9463 206.951 42.5802 207.826C42.2138 208.716 41.6893 209.492 41.0065 210.153L38.8038 208.317C39.212 207.812 39.5242 207.305 39.7404 206.796C39.9726 206.288 40.0937 205.721 40.1037 205.098C40.1135 204.49 39.9933 204 39.7433 203.628C39.509 203.272 39.1679 203.09 38.7199 203.083C38.48 203.079 38.2711 203.132 38.0933 203.241C37.9313 203.366 37.7766 203.532 37.6293 203.738C37.4977 203.959 37.3656 204.213 37.233 204.499C37.1163 204.785 36.9833 205.095 36.834 205.429L35.9856 207.432C35.5863 208.385 35.0462 209.129 34.3656 209.662C33.6847 210.211 32.7923 210.477 31.6884 210.459C31.0325 210.448 30.4108 210.302 29.8232 210.021C29.2514 209.756 28.7457 209.363 28.3059 208.844C27.882 208.341 27.5399 207.72 27.2798 206.98C27.0357 206.24 26.9211 205.406 26.936 204.478Z" fill="#FFE7BA"/>
</svg>

}