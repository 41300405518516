import { StyleSheet, css } from 'aphrodite';
import React, {useEffect} from 'react';
import { useBlur, usePage } from '../../stores';
import { useThree } from '@react-three/fiber';

export const Blur = () => {
    const {gl} = useThree()
    const canvas = gl.domElement
    const blur =useBlur()
    const pageState = usePage()
    
    const opacityKeyframes = {
        'from': {
            filter: 'blur(0px)',
        },
        
        'to': {
            filter: 'blur(4px)',
        }
    };
    const revOpacityKeyframes = {
        'from': {
            filter: 'blur(4px)',
        },
        'to': {
            filter: 'blur(0px)',
        },
    };
    const blurStyles = StyleSheet.create({
        Blur:{
            animationName: blur.blur?opacityKeyframes:revOpacityKeyframes,
            animationDuration:'0.5s',
            animationFillMode :'forwards'
        },
    })
    useEffect(()=> {
        // canvas.style.filter = 'blur(5px)'
        // canvas.style.filter = fade.state.to(x=>`blur(${x})`)
        // canvas.className = blur.blur || pageState.page==='introduction'?(css(blurStyles.Blur)):(css(blurStyles.unBlur))
        // canvas.style = {...canvas.style,animationName:blur.blur?opacityKeyframes:revOpacityKeyframes,animationDuration:'2s',animationFillMode:'fowards'}
        if(pageState.page==="panorama" || pageState.page==='location0'){
            blur.setBlur(false)
        }else{
            blur.setBlur(true)
        }
    },[pageState.page])
    useEffect(()=> {
        // canvas.style.animationName = opacityKeyframes
        canvas.className = css(blurStyles.Blur)
    })
    return null
}

