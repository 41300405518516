import { Html } from '@react-three/drei'
import React, { useEffect, useRef, useState } from 'react'
import { useAudio, useBlur, useLocations, usePage, useSound } from '../../stores'
import { Animate, CloserLook, TidBits, VideoIcon, AudioIcon } from '../assets/svgs/Buttons'
import { PopupManager } from './PopupManager'
import { useSpring } from '@react-spring/core'
import { PopupScreen } from '../popups/PopupScreen'
import { animated as a } from '@react-spring/web'
import { AllMuseumLocations, debug, springConfigs } from '../../constants'
import { FloorIcons } from '../assets/svgs/FloorIcons'
import { useThree } from '@react-three/fiber'
import { Station1, Station2, Station3, Station4, Station5, Station6 } from '../assets/svgs/Stations'
import { FloorRightArrow, RightArrow } from '../assets/svgs/Arrows'
import { css, StyleSheet } from 'aphrodite'
import { isMobile, isMobileOnly } from 'react-device-detect'
import { MuseumHotspot, TimelinesHotspot } from '../assets/svgs/HomepageHotspots'
// import { PopupScreen } from './PopupScreen'

// import hotspotsprite from '/sprites/hotspot.png'
const getStation = (station) => {
    switch(station){
        case 1:
            return <Station1 />
        case 2:
            return <Station2 />
        case 3:
            return <Station3 />
        case 4:
            return <Station4 />
        case 5:
            return <Station5 />
        case 6:
            return <Station6 />
    }
}
const Hotspot = (props) => {
    const [showPopup,setShow] = useState(false)
    const media = useRef()
    const blur = useBlur()
    const pageState = usePage()
    const audio = useAudio()
    // const [firstTime, setFirstTime] = useState(true)
    const locationsState = useLocations()
    const soundState = useSound()
    const [hover,setHover] = useState(false)
    const showPopUp = () => {   
        soundState.play('hotspot')
        if(props.type === 'valuewheel'){
            pageState.setPage('valuewheel')
        }else if(props.type==='audio'){
            if(audio.title === props.title){
                audio.clear()
            }else if(audio.title!==''){
                audio.clear()
            }
            else{
                audio.clear()
                audio.setTitle(props.title)
                audio.setUrl(props.url)
                audio.setText(props.text)
                audio.setSubtext(props.subtext)
                audio.setSubsubtext(props.subsubtext)
            }
        }else if(props.type==='location'){
            
            locationsState.setCurrentLocation(
                locationsState.allLocations[Math.floor((locationsState.currentLocation.locationId-1)/3)*3+props.number]
            )
        }else if(props.type==='era'){
            locationsState.setCurrentLocation(locationsState.allLocations.find(x=>x.eraid===props.era))
        }else if(props.type==='timelines' || props.type==='museum'){
            return
        }else{
            audio.clear()  
            setShow(true)
            props.setShowHotspots(false)
        }
    
    }
    const pulse = useSpring( {from:{state:0}, state:1, loop:true } )
    const fade = useSpring( {
        opacity:blur.blur?0:1, 
        from:{ opacity:0 },
        // reset:firstTime,
        // config:springConfigs.precision,
        // onRest: () => setFirstTime(false)
    } )
    // const hoverSpring = useSpring( {width:hover?'7.2vh':'6.2vh',} )
    // const hoverSpring = useSpring( {transform:hover?'scale(1.2)':'scale(1)',} )
    const hoverSpring = useSpring( {transform:hover?'scale(1.2)':'scale(1)',} )
        // config:springConfigs.precision
    // const hover = useSpring({
    //     from
    // })
    const {camera} = useThree()
    const closePopup = () => {
        setShow(false)
        props.showHotspotsFunction()
        blur.setBlur(false)
        
    }
    const [temp, setTemp] = useState([0,0,0])
    const [rotation,setRotation] = useState([0,0,0])
    const [scale,setScale] = useState(1)
    const handleHelper = () => {
        
        
        
    }
    return (
         <>
            {(props.test ) && 
            <>
            {/* Helper for setting Hotspots */}
            <Html  style={{marginTop:'-20vh',marginLeft:'-20vh'}}>
                    <p>position</p>
                    <input style={{width:'30vw'}} width={200} type='range' value={temp[0]} onChange={(e)=>setTemp(temp.map((x,i)=>i===0?e.target.value:x))} min={-400} max={400}/>
                    <input style={{width:'30vw'}} width={200} type='range' value={temp[1]} onChange={(e)=>setTemp(temp.map((x,i)=>i===1?e.target.value:x))} min={-200} max={200}/>
                    <input style={{width:'30vw'}} width={200} type='range' value={temp[2]} onChange={(e)=>setTemp(temp.map((x,i)=>i===2?e.target.value:x))} min={-400} max={400}/>
                    {!props.normalHotspot && <>
                        <p>rotation</p>
                        <input style={{width:'20vw'}} width={100} type='range' value={rotation[0]} onChange={(e)=>setRotation(rotation.map((x,i)=>i===0?e.target.value:x))} step={0.01} min={0} max={10}/>
                        <input style={{width:'20vw'}} width={100} type='range' value={rotation[1]} onChange={(e)=>setRotation(rotation.map((x,i)=>i===1?e.target.value:x))} step={0.01} min={0} max={10}/>
                        <input style={{width:'20vw'}} width={100} type='range' value={rotation[2]} onChange={(e)=>setRotation(rotation.map((x,i)=>i===2?e.target.value:x))} step={0.01} min={0} max={10}/>
                        <p>scale</p>
                        <input style={{width:'20vw'}} width={100} type='range' value={scale} onChange={(e)=>setScale(e.target.value)}  min={100} max={1000}/>  
                    </>}
                    {/* <button onClick={handleHelper}>print to console</button> */}
                    <button onClick={()=>console.log('position:',temp,'\nrotation:',rotation,'\nscale:',scale)}>print</button>
            </Html>
            <mesh scale={scale/10} position={temp} rotation={rotation}>
                <Html transform={!props.normalHotspot}    style={{position:'absolute',zIndex:10}} > 
                        {  

                            <a.div onMouseEnter={()=>setHover(true)} onMouseLeave={()=>setHover(false)} style={{position:'absolute',zIndex:40,width:window.innerWidth>1000?'6.2vh':'7vh',transform:hoverSpring.transform ,pointerEvents:blur.blur?'none':'auto',opacity:fade.opacity}} onClick={showPopUp}>
                            {props.type==='textheavy' || props.type==='image' || props.type==='comic' || props.type==='collage'?(
                                // <TimelinesHotspot />

                                <CloserLook pulse={pulse} eraid={props.eraid} />
                            ):props.type==='video'?(
                                <VideoIcon eraid={props.eraid}/>
                            ):props.type==='audio'?(
                                <AudioIcon title={props.title} eraid={props.eraid}/>
                            ):props.type==='location'?(
                                // <TimelinesHotspot />

                                <FloorIcons number={3} eraid={props.eraid}/>
                            ):props.type==='era'?(
                                <div className={css(styles.RightArrow)}>
                                    <FloorRightArrow noCircle text={'To 1940'}/>
                                </div>
                            ):props.type==='timelines'?(
                                <TimelinesHotspot style={{
                                    transform: `
                                        rotateX(${4.61*57.2958}deg)
                                    
                                    `
                                }} />
                            ):props.type==='museum'?(
                                <MuseumHotspot />
                            ):(
                                <Animate eraid={props.eraid}/>
                            )
                        }
                            
                            </a.div> 
                        }
                    </Html>
             </mesh>
            </>}
            {/* <mesh scale={scale/10} position={temp} rotation={rotation}> */}
            <mesh rotation={props.rotation || [0,0,0]} scale={props.scale/10 || 0} position={!props.nested?props.position:[100,0,0]}>
                
                
                <Html center  zIndexRange={[3, 0]} prepend transform={props.rotation} style={{position:'absolute',zIndex:10}} > 
                    {/* {props.showHotspots && <img style={{zIndex:0}} onClick={showPopUp} src='sprites/hotspot.png' alt=''/>} */}
                    {/* {props.showHotspots && !blur.blur &&   */}
                    {  
                        <a.div onMouseEnter={()=>setHover(true)} onMouseLeave={()=>setHover(false)} style={{position:'absolute',zIndex:40,width:window.innerWidth>1000?'6.2vh':props.type==='location'?'23vh':'7vh',transform:hoverSpring.transform ,pointerEvents:blur.blur?'none':'auto',opacity:fade.opacity}} onClick={showPopUp}>
                        {props.station?(
                            getStation(props.station)
                        ):props.type==='textheavy' || props.type==='image' || props.type==='comic' || props.type==='carousel' || props.type==='collage'?(
                            <CloserLook pulse={pulse} eraid={props.eraid} />
                        ):props.type==='video'?(
                            <VideoIcon eraid={props.eraid}/>
                        ):props.type==='audio'?(
                            <AudioIcon title={props.title} eraid={props.eraid}/>
                        ):props.type==='location'?(
                            <FloorIcons number={props.number} eraid={props.eraid}/>
                        ):props.type==='era'?(
                            !isMobile?<div className={css(styles.RightArrow)}>
                                <FloorRightArrow noCicle text={props.text}/>
                            </div>:null
                        ):props.type==='timelines'?(
                            <TimelinesHotspot onClick={()=>{
                                pageState.setPage('timelines')
                            }} />
                        ):props.type==='museum'?(
                            <MuseumHotspot onClick={()=>{
                                pageState.setPage('panorama')
                                locationsState.setCurrentLocation(locationsState.allLocations[1])
                            }} />
                        ):(
                            <Animate eraid={props.eraid}/>
                        )
                    }
                          
                        </a.div> 
                    }
                </Html>
             </mesh>
            {
                showPopup && <PopupScreen closePopup={closePopup} {...props} setShow={setShow}/>
            }
        </>
    )
}


export const HotspotManager = (props) => {

    const [showHotspots, setShowHotspots] = useState(true)
    
    const showHotspotsFunction = () => {
        setShowHotspots(true)
    }
    const {camera} = useThree()
    const [position, setPositions] = useState([100,0,100])
    useEffect(()=>{
        
    },[])
    
    return (
        <>
            {/* UNCOMMENT BOTTOM SNIPPET FOR HELPER HUD */}

            { debug && <Hotspot 

                test
                normalHotspot={false}  //Set to true for normal hotspots, false for floor hotspots
                eraid={props.eraid}
                // {...hotspot}
                showHotspots={showHotspots} 
                setShowHotspots={setShowHotspots} 
                showHotspotsFunction={showHotspotsFunction}

             {...{...props.hotspots[0],type:'textheavy',text:'To 1940',era:1}}
            /> }

            { 
                props.hotspots.map( (hotspot,id) =>(
                    <Hotspot 
                        key={id}
                        eraid={props.eraid}
                        {...hotspot}
                        showHotspots={showHotspots} 
                        setShowHotspots={setShowHotspots} 
                        showHotspotsFunction={showHotspotsFunction}
                    />
                    )
                )
            }
            {/* {<Hotspot 
                eraid={1}

            /> } */}
        </>
    )
}

const styles = StyleSheet.create({
    RightArrow:{
        '@media (max-width: 1000px)':{
            transform:'scale(0.8)'
        }
    }
})