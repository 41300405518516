import { css, StyleSheet } from 'aphrodite'
import React, { useEffect } from 'react'
import { useState } from 'react'
import animationData from '../../lotties/ClickAndDrag.json'
import Lottie from 'react-lottie'
import {CloserLook} from '../../assets/svgs/Buttons'
import { colorThemes } from '../../../constants'
import { useDrag, useLocations, usePage } from '../../../stores'
import { DirectionalLightHelper } from 'three'
import { config, useSpring } from '@react-spring/core'
import { animated as a } from '@react-spring/web'
export const Arrow = () => (
    <svg width="12" height="17" viewBox="0 0 12 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.53884 15.6077C2.00522 16.4577 0.691892 16.0796 0.691892 15.076L0.691888 1.64048C0.691888 1.08819 1.1396 0.640479 1.69189 0.640479L10.1261 0.64048C10.9124 0.64048 11.3911 1.50618 10.9731 2.17216L2.53884 15.6077Z" fill="#FFE7BA"/>
    </svg>
)
export const ClickToDrag = () => {
    const [shouldShow,setShow] = useState(false)
    const pageState = usePage()
    const drag = useDrag()
    const {opacity} = useSpring({opacity:drag.showHint?1:0,config:config.stiff})
    useEffect(()=>{
        console.log('should show hint',drag.showHint)
    },[drag.showHint])
    const options = {
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }

    }
    return (
        <>

            <a.div style={{top:'31%',left:'50%',opacity,pointerEvents:'none'}} className={css(styles.Box)}>
                <p className={css(styles.Text)}>Click and drag to look around</p>
                <div className={css(styles.LeftBot)}><Arrow /></div>
            </a.div>
            <a.div style={{pointerEvents:'none',position:'absolute',left:'50%',top:'50%',transform:'translate(-50%,-50%)',zIndex:12, opacity}}>
                <Lottie 
                    options={options}
                    width='40vw'
                />
            </a.div>
        </>
    )
}
export const Hints = (props) => {
    // const [current,setCurrent] = useState(0)
    // const current = useRef
    const locationsState = useLocations()
    const options = {
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }

    }
    const handleClick = () => {
        // setCurrent(state=>state+1)
        props.incHints()
    }
    useEffect(()=>{
        window.addEventListener('click',handleClick)
        window.addEventListener('touchstart',handleClick)
        return () => {
            window.removeEventListener('click',handleClick)
            window.removeEventListener('touchstart',handleClick)
        }
    },[])
    return (
        <div className={css(styles.Container)} style={{color:colorThemes[locationsState.currentLocation.eraid]}}>
        {   <>
                {
                    props.hints===0?(
                        <>
                            <div style={{top:'31%',left:'50%'}} className={css(styles.Box)}>
                                <p className={css(styles.Text)}>Click and drag to look around</p>
                                <div className={css(styles.LeftBot)}><Arrow /></div>
                            </div>
                            <div style={{position:'absolute',left:'50%',top:'50%',transform:'translate(-50%,-50%)',zIndex:50, }}>
                                <Lottie 
                                    options={options}
                                    width='40vw'
                                />
                            </div>
                        </>):
                    props.hints===1?(
                        <>
                            <div style={{bottom:'13%',left:'49.5%'}} className={css(styles.Box)}>
                                <p className={css(styles.Text)}>Visit different areas from here</p>
                                <div className={css(styles.LeftBot)}><Arrow /></div>
                            </div>
                        </>):
                        props.hints===2?(
                            <>
                                <div style={{bottom:'14%',right:'3%'}} className={css(styles.Box)}>
                                    <p className={css(styles.Text)}>Open up the minimap</p>
                                    <div className={css(styles.RightBot)}><Arrow /></div>
                                </div>
                            </>
                        ):props.hints===3?(
                            <>
                                <div style={{top:'52%',left:'46%'}} className={css(styles.Box)}>
                                    <p className={css(styles.Text)}>Click hotspots to know more</p>
                                    <div className={css(styles.LeftTop)}><Arrow /></div>
                                </div>
                                <div className={css(styles.Hotspot)}>
                                    <CloserLook eraid={locationsState.currentLocation.eraid}/>
                                </div>
                            </>
                        ):props.hints===4?(
                            <>
                            <div style={{bottom:'11.5%',left:'3.7%'}} className={css(styles.Box)}>
                            <p className={css(styles.Text)}>Go here for help</p>
                            <div className={css(styles.LeftBot)}><Arrow /></div>
                            </div>

                            </>
                        ):(
                            <ClickToDrag />
                        )
                }
                

            </>}
        </div>
    )
}

const styles = StyleSheet.create({
    Container:{

    },
    Box:{
        /* Rectangle 151 */
    zIndex:12,
    position: 'absolute',
    cursor:'pointer',
    // width: ,
    // height: '4vh',
    padding:'.7% .7% .7% .7%',
    background: '#FFE7BA',
    borderRadius: '10px',

    },
    Text:{
        margin:0,
        fontFamily:'Source Sans Pro',
        fontSize:17,
        // color:'#36AA9D',
        '@media (max-width: 1000px)':{
            fontSize:13
        }
    },
    LeftBot:{
        position:'absolute',
        top:'85%'
    },
    LeftTop:{
        position:'absolute',
        transform:'scaleY(-1)',
        top:'-41%'
    },
    RightBot:{
        position:'absolute',
        transform:'scaleX(-1)',
        top:'85%',
        right:'5%'
    },
    Hotspot:{
        position:'absolute',
        zIndex:100,
        width:'6.2vh',
        left:'43.5%',
        bottom:'50%'
    }
})