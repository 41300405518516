import React from 'react'
import { useLocations, usePage, useSound } from '../../stores'
import { RightArrow } from '../assets/svgs/Arrows'
import { AllMuseumLocations } from '../../constants'


export const NextArrowComponent = ({className,credits,setCredits}) => {

    const locationsState = useLocations()
    const pageState = usePage()
    const soundState = useSound()
    return (
        
        <div
          onPointerDown={()=>soundState.play('hud')}
          style={{top:locationsState.currentLocation.name==='videobooth'?'14%':'43.3%'}}
          onClick={() =>
            credits?pageState.setPage('credits'):locationsState.setCurrentLocation(
              locationsState.allLocations.find(
                (x) => x.eraid === locationsState.currentLocation.eraid + 1
              )
            )
          }
          className={className}
        >
          {locationsState.currentLocation.eraid !== 3 &&
            locationsState.currentLocation.eraid !== undefined && (
              <RightArrow
                text={`Go to ${
                  AllMuseumLocations[
                    locationsState.currentLocation.eraid + 2
                  ].era.split("-")[0]
                }`}
              />
            )}
            {credits && 
              <RightArrow
              text={'Credits'}
            />}
        </div>
        
    )
}
