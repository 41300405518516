import { useFrame, useThree } from '@react-three/fiber'
import { Easing, Tween } from '@tweenjs/tween.js'
import React, { useEffect, useState } from 'react'
import { Vector3 } from 'three'
import { cameraStore, useLocations, usePage } from '../../stores'
// import  * as TWEEN  from '@tweenjs/tween.js'
import { TWEEN } from 'three/examples/jsm/libs/tween.module.min'

const findThirdPoint = (x1,y1,x2,y2) => {
    var Tx = -x1;
    var Ty = -y1;

    var Tx2 = Tx + x2;
    var Ty2 = Ty + y2;

    var Tx3 = (Tx2 * Math.cos(60 * Math.PI/180)) - (Ty2 * Math.sin(60 * Math.PI/180));				
    var Ty3 = (Tx2 * Math.sin(60 * Math.PI/180)) + (Ty2 * Math.cos(60 * Math.PI/180));

    var x3 = Tx3 + x1;
    var y3 = Ty3 + y1;
    return [x3,0,y3]
}
export const CameraTween = () => {
    const locationsState = useLocations()
    const pageState = usePage()
    const { camera, invalidate, gl } = useThree()
    const cameraState = cameraStore()
    const [isTweening, setTweening] = useState(false)
    // .to(new Vector3(...([0,0,-4])),800)
    // .easing(TWEEN.Easing.Quadratic.In)
    // .play()
    useEffect(()=>{
        const cameraTween = new TWEEN.Tween(camera.position)
        const YTween = new TWEEN.Tween(camera.position.y)
        const lookAt = locationsState.currentLocation.lookAt || [0,0,10]
        // const [i,j,k] = findThirdPoint(camera.position.x,camera.position.z,lookAt[0],lookAt[2])
        
        // YTween.to(
        //     camera.position.distanceTo(new Vector3(...lookAt))>8?lookAt[1]:lookAt[1]
        // )
        // if(locationsState.currentLocation.locationId in locationsState.visited)console.log('visited')
        cameraTween.to(
            // lookAt,
            // camera.position.distanceTo(new Vector3(...lookAt))>8?
            // {x:[i,lookAt[0]], y:camera.position.y, z:[k,lookAt[2]]}
            new Vector3(...lookAt),
            //  [new Vector3(0,0,-4),new Vector3(...lookAt)],
             window.innerWidth<1000?1500:1500)
        .easing(TWEEN.Easing.Quadratic.InOut)
        // .interpolation(TWEEN.Interpolation.Bezier)
        .onStart(()=>setTweening(true))
        .onStop(()=>setTweening(false))
        pageState.page!=='introduction' && cameraTween.start()

        // const XZTween = new TWEEN.Tween(camera.position)
        //     .to(
        //         // locationsState.currentLocation.loading === false && camera.position.distanceTo(new Vector3(...lookAt))>8? {x:[i,lookAt[0]],y:[camera.position.y], z:[k,lookAt[2]]} : {x:k,z:lookAt[2]}
        //         ...(new Vector3(...lookAt))
        //     )
        //     .easing(TWEEN.Easing.Quadratic.InOut)
        //     .interpolation(TWEEN.Interpolation.Bezier)
        //     .onStart(()=>setTweening(true))
        //     .onStop(()=>setTweening(false))
        // pageState.page!=='introduction' && XZTween.start()
    },[locationsState.currentLocation,pageState])
    useEffect(()=>{
        // gl.domElement.addEventListener('click',cameraTween.stop())
    },[])
    useFrame((state,delta)=>{
        (pageState.page==='panorama' || pageState.page==='splashscreen')  && TWEEN.update() //to tween before fade in of valuewheel location
        // regress()
        
    })
    return (
        <>     

        </>
    )
}
