import React, { useEffect } from 'react'
import { useAudio, useSound } from '../stores'

export const HudSounds = () => {
    const soundState = useSound()
    const audio = useAudio()
    useEffect(()=>{
        
    },[soundState.currentPlaying])
    return (
        soundState.currentPlaying && <div>
            <audio onEnded={()=>soundState.clear()} muted={audio.isMuted}  autoPlay>
                <source src={soundState.currentPlaying} type='audio/mpeg'/>
            </audio>
        </div>
    )
}
