import { StyleSheet, css } from 'aphrodite'
import React, { useEffect, useRef } from 'react'
import { colorThemes } from '../../constants'
import { useAudio, useLocations } from '../../stores'

export const AudioPlayer = () => {
    const audio = useAudio()
    const locationsState = useLocations()
    const lastTitle = useRef()
    useEffect(()=>{
        if(lastTitle.current !== '' ){
            audio.clear()
            lastTitle.current = audio.title
            // audio.setTitle()
        }else{
            // lastTitle.current = 'idk'
            lastTitle.current = ''
        }
    },[audio.title])
    return (
        audio.title ? <div style={{textAlign:'center',position:'absolute',top:0,left:0,height:'100%',width:'100%'}}>
            <audio onEnded={()=>audio.clear()} muted={audio.isMuted}  autoPlay style={{zIndex:130}}>
                <source src={audio.url} type='audio/mpeg'/>
            </audio>
            {/* <div style={{backgroundColor:colorThemes[locationsState.currentLocation.eraid]}} className={css(css(styles.Container))}>
                <h1  className={css(styles.Heading)}>asdasd{audio.title}</h1>
                <p className={css(styles.Text)}>asdasd{audio.text}</p>
            </div> */}
        </div> : null
    )
}

const styles = StyleSheet.create({
    Text:{
            fontSize:"1.8vw"
        },
    Heading:{

    }
    
})