import React, { useEffect, useState, useRef } from 'react';
import {  useFrame, useThree } from '@react-three/fiber';
import {Vector3, Color } from 'three';
import { AdaptiveDpr, AdaptiveEvents, OrbitControls } from '@react-three/drei';
import { useBlur, useLocations, usePage } from '../../stores';
import { useSpring } from '@react-spring/core';
import { lerp } from 'three/src/math/MathUtils';
import { CameraTween } from './CameraTween';
import { cameraStore } from '../../stores';
import { Performance } from './Performance';
import { debug } from '../../constants';
// extend({OrbitControls})
// const circularLerp = (camPosition,target,factor) => {
//     let {x,y,z} = camPosition
//     let [i,j,k] = target
//     if(j>y){
//         y+= Math.abs(y-j)*factor
//     }else{
//         y-= Math.abs(y-j)*factor
//     }
//     if(x>i){
//         z += Math.sin( Math.abs((z-i)) )
//         x += Math.cos( Math.abs((x-i)) )
//     }else{
//         x -= Math.cos( Math.abs((x-i)) )
//         z -= Math.sin( Math.abs((z-i)) )
//     }
//     
//     // if(z>i){
//     //     z += Math.cos( Math.abs((x-i)) )
//     // }else{
//     //     x -= Math.cos( Math.abs((x-i)) )
//     // }
//     return new Vector3(x,y,z)
// }
const vec = new Vector3();
export default function Camera(props){
    const ref = useRef()
    const { invalidate } = useThree()
    const locationsState = useLocations()
    const [rotate, setRotate] = useState(true)
    const pageState = usePage()
    const cameraState = cameraStore()
    const {scene, gl, camera} = useThree();
    const blur = useBlur()
    const [shouldTween, setTween] = useState(false)
    let time
   
    const pauseRotation = () => {
        
        cameraState.stopAndResume()
    }
    useEffect(()=> {
        camera.position.set(locationsState.allLocations[0].lookAt)
        gl.domElement.addEventListener('click',pauseRotation)
        gl.domElement.addEventListener('click',()=>setTween(false))
        gl.domElement.addEventListener('clickup',pauseRotation)
        gl.domElement.addEventListener('touchstart',pauseRotation)
        gl.domElement.addEventListener('touchend',pauseRotation)
        gl.setClearColor(new Color('black'))
        
    },[])
    useEffect(() =>{
        locationsState.currentLocation.name==='location0' && camera.position.set(...locationsState.allLocations[0].lookAt)
    },[locationsState.currentLocation])
    
    useEffect(()=>{
        if(pageState.page==='introduction' || (pageState.page==='panorama' && locationsState.currentLocation.name=='location0'))cameraState.resume()
        else cameraState.stopAndResume()
    },[pageState.page,locationsState])
    useEffect(()=>{

    },[blur])
    
    useFrame((state,delta)=>{

        cameraState.rotate && invalidate()
        debug && console.log(Object.values(camera.position))
    })

    return(
        <>
            {/* <PerspectiveCamera  makeDefault /> */}
            <CameraTween />
            <OrbitControls  
                
                position={locationsState.currentLocation.lookAt} 
                autoRotate={cameraState.rotate} 
                autoRotateSpeed={window.innerWidth<1000?0.32:0.30} rotateSpeed={window.innerWidth<1000?-0.7:-1} 
                enablePan={false} 
                enableZoom={false} 
                ref={ref} 
                enableDamping={false}   
            />
            {/* <AdaptiveDpr /> */}
            <Performance controls={ref}/>
            {/* <AdaptiveDpr pixelated />
            <AdaptiveEvents /> */}
            {/* <TweenManager controls={ref}/> */}
        </>
    )
}

