import React, { Suspense, useState } from 'react';
import { useTexture, PerspectiveCamera} from "@react-three/drei"
import { useEffect, useRef } from "react";
import * as THREE from 'three';
// import { LoadingSc } from '../Loading';
import {  useThree } from '@react-three/fiber';
import { useBlur, useLoader, useLocations, useModelViewer, usePage, usePanoramaLoading, usePreloader, useSky } from '../../stores';
import { HotspotManager } from './HotspotManager';
import { useFrame } from '@react-three/fiber';
import { useSpring } from '@react-spring/core';
import { animated as a } from '@react-spring/three';
import { springConfigs } from '../../constants';
import { Blur } from './Blur';

export const Panorama = (props) => {
    // const {scene} = useThree()
    // const skyboxState = useSky()
    // const url = props.currentLocation.url.slice(1,props.currentLocation.url.length);
    const url = props.currentLocation.url

    // console.log(url)
    const textures = usePreloader()
    const pageState = usePage()
    // 
    // const skybox = textures.textures[Math.round(Math.random()*10)]
    let fadedInOnce = useRef(false)
    const skybox = useTexture(url);
    const modelState = useModelViewer()
    const skyboxState = useSky()
    const mesh = useRef();
    const group = useRef();
    const loader = useLoader()
    const panoramaLoadingState = usePanoramaLoading()
    const {camera, gl,  scene} = useThree()
    const blur = useBlur()
    // const locationsState = useLocations()
    // function initialize(){
    //     mesh.current.geometry.scale(-1,1,1);
    //     skybox.encoding = THREE.sRGBEncoding;
    //     let mat = new THREE.MeshBasicMaterial({map:skybox});
    //     mesh.current.material = mat;
    //     mesh.current.material.transparent = true;
    //     mesh.current.material.opacity = 1;
    // }
    const canvas = gl.domElement
    
    

    useEffect(() =>{
        skybox.encoding = THREE.sRGBEncoding
        mesh.current.geometry.scale(-1,1,1)
        // camera.rotation.y=-40
        // 
        
    },[])
    useEffect(()=>{
        loader.reportDone(props.currentLocation.name)
        fadedInOnce.current = true
    },[props.currentLocation])
    useFrame((state)=>{
        // 
        // 
        // raycaster.setFromCamera(camera,state.mouse)

    })
    const fadeOut = useSpring( { 
        from:{opacity:1}, 
        config:springConfigs.precision, 
        opacity:0,
        reset:true, 
        onRest:()=>props.exit && props.setLastLocationToCurrent() 
    } )
    const fadeIn = useSpring( {from :{opacity:0},config:springConfigs.precision, opacity:1, reset:true})
    return(
        <group ref={group}>
            {/* {(blur.blur || pageState.page==='introduction') && <Blur />} */}
            <Blur />
            {/* <PerspectiveCamera makeDefault /> */}
            <a.mesh rotation={[0,0,0]} ref={mesh}>
                <a.sphereBufferGeometry attach="geometry" args={[300,60,40]} />
                <a.meshBasicMaterial 
                    transparent 
                    opacity={
                        props.exit?fadeOut.opacity.to(x=>x):(
                            props.shouldAnimate && props.currentLocation!==props.lastLocation && props.currentLocation.era!=='era0' 
                            )?fadeIn.opacity.to(x=>x):1
                        } 
                    map={skybox}/>
            </a.mesh>
            {!props.exit && <HotspotManager eraid={props.currentLocation.eraid} hotspots={props.currentLocation.hotspots}/>}
        </group>
    )
}





function PanoramaViewer(props){
    const locationsState = useLocations()
    const loader = useLoader()
    const pageState = usePage()
    const setLastLocationToCurrent = () => {
        locationsState.setLastLocation(locationsState.currentLocation, pageState.page==='panorama')
    }
    useEffect(()=>{
        // panoramaLoadingState.setLoading(true)
    },[locationsState.currentLocation])
    return(
        locationsState.currentLocation!=={} && <group>
            <Suspense fallback={null}>
                {
                    (locationsState.lastLocation && locationsState.lastLocation!==locationsState.currentLocation && loader.items.length===0) && 
                    <Panorama 
                        setLastLocationToCurrent={setLastLocationToCurrent} 
                        {...props} 
                        exit 
                        currentLocation={locationsState.lastLocation} 
                    />
                }
                <Panorama 
                    shouldAnimate={locationsState.lastLocation} 
                    exit={false} 
                    currentLocation={locationsState.currentLocation} 
                    lastLocation={locationsState.lastLocation}
                />
            </Suspense>
        </group>
    )
}

export default PanoramaViewer;