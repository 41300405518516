import React from 'react'
import { useLocations, useSound } from '../../../stores'
import { NavigationBar } from '../../assets/svgs/NavigationBar'

export const LocationSlider = (props) => {
    const locationsState = useLocations()
    const soundState = useSound()
    const setLocation = (id) => {
        soundState.play('hud')
        const current = locationsState.currentLocation.locationId%3
        const newId = locationsState.currentLocation.eraid*3+id+1
        locationsState.setCurrentLocation( locationsState.allLocations.find(x=>x.locationId===newId) )
    }
    return (
        <NavigationBar 
            two={locationsState.currentLocation.eraid===3}
            current={locationsState.currentLocation.locationId%3}
            setLocation={setLocation}
            eraid={locationsState.currentLocation.eraid}
            fadeOut={props.fadeOut}
        />
    )
}
