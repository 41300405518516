import { NoToneMapping } from "three";
import create from "zustand";
import { AllMuseumLocations, flattenedLocations } from "./constants";

export const useDrag = create(set => ({
    showHint:false,
    time:null,
    trigger: () => set(state => {
        // console.log('triggering')
        state.showHint=false
        clearTimeout(state.time)
        state.time=setTimeout(state._show,[20000])

    }),
    _show: () => set(state => {
        state.showHint = true
    })
}))
export const usePage = create(set => ({
    page:'mangrooves',
    setPage: (newPage) => set(state => state.page = newPage)
}))
export const useModelViewer = create(set => ({
    isViewing: false,
    setIsViewing: () => set(state => ({isViewing:!state.isViewing}))
}))
export const useSky = create(set => ({
    opacity:1,
    fadeOutTick: () => set(state => ({opacity:state.opacity-=.06}) ),
    fadeInTick: () => set(state => ({opacity:state.opacity+=1}) ),
    setOpacity: () => set(state=>state.opacity=1)
}))
export const usePanoramaLoading = create(set => ({
    isLoading: false,
    setLoading: (loading) => set(state=>state.loading=loading) 
}))

export const useBlur = create(set => ({
    blur:false,
    setBlur: (newState) => set(state => state.blur=newState)
}))
export const useLocations = create(set => ({
    lastLocation : {},
    visited:[],
    allLocations : flattenedLocations,
    currentLocation : flattenedLocations[flattenedLocations.length-1],
    setCurrentLocation: (loc) => set(state=>{
        if(!state.currentLocation.loading)state.lastLocation = state.currentLocation
        state.currentLocation = loc
    }),
    setDisplay : (flag) => set((state)=>state.displayLocation=flag),
    setLastLocation: (loc, ispano) => set(state=>{
        state.lastLocation = loc
        // console.log(loc)
        // if(loc && ispano)state.visited = [...state.visited, loc.locationId]
    })
}))

export const useCamera = create(set => ({
    controls:null,
    position:[0,0,0],
    setCameraRef: (ref) => set(state=>state.controls=ref),
    setCameraPosition: (pos) => set(state=>state.position=pos)
}))
export const usePreloader = create(set => ({
    textures: [],
    setTextures : (tex) => set(state => state.textures = tex)
}))

export const useLoader = create(set => ({
    loading:false,
    items:flattenedLocations,
    reportDone: (name) => set(state => {
        return {...state, items:state.items.filter(x => x.name !== name)}
    }),
    
    addItems: (newItems) => set(state => state.items = [...state.items, ...newItems])
}))

export const useAudio = create(set => ({
    isMuted:false,
    title:'',
    text:'',
    url:'',
    subtext:'',
    subsubtext:'',
    setMuted:() => set(state => state.isMuted = !state.isMuted),
    setTitle:(title) => set(state => state.title = title),
    setUrl:(url) => set(state => state.url=url),
    setText:(text) => set(state => state.text = text),
    setSubtext:(t) => set(state => state.subtext=t),
    setSubsubtext:(t) => set(state => state.subsubtext = t), 
    clear:() => set(state=>{
        state.title=''
        state.url=''
        state.text=''
        state.subsubtext=''
        state.subtext=''
    })
}))

export const useHud = create(set => ({
    moveUpArrow:false,
    setMoveUpArrow:(bool) => set(state => state.moveUpArrow = bool)
}))

export const cameraStore = create(set => ({
    rotate:false,
    enableControls:true,
    time: setTimeout(()=>null,100000),
    setRotate:(bool) => set(state => state.rotate = bool),
    setEnableControls:(bool) => set(state => state.enableControls=bool),
    stopAndResume:(time)=>set(state=> {
        clearTimeout(state.time)
        state.rotate = false
        state.time = setTimeout(()=> {
            state.resume()
        },time || 30000)
    }),
    stop:()=> set(state=>{
        clearTimeout(state.time)
        state.rotate = false

    }),
    resume:()=> set(state=> {
        state.rotate=true
    }),
    
}))

export const useSound = create((set)=>({
    currentPlaying:'',
    sounds:{
        hud:'./content/sounds/HUDClick.mp3',
        hotspot:'./content/sounds/HotspotClick.mp3',
        type:'./content/sounds/type.mp3',
        close:'./content/sounds/cancel.mp3',
    },
    play:(x) => set(state=>{
        state.currentPlaying = ''
        state.currentPlaying = state.sounds[x]
    }),
    clear:() => set(state=>{
        state.currentPlaying  = ''
    })

}))


