import React, { useEffect, useState } from 'react'
import { StyleSheet, css } from 'aphrodite'
import {
  CarouselArrowLeft,
  CarouselArrowRight,
} from '../assets/svgs/CarouselArrows'
import { ImagePopup } from './ImagePopup'
import { TextHeavy } from './TextHeavy'
import { useSpring } from '@react-spring/core'
import { animated as a } from '@react-spring/web'
import { springConfigs } from '../../constants'
export const Carousel = (props) => {
  const [current, setCurrent] = useState(0)
  const [lastIdx, setLast] = useState()
  // useEffect(()=>{

  // })
  const fadeOut = useSpring({
    from: { opacity: 1 },
    opacity: 0,
    reset: true,
    config: springConfigs.precision,
  })
  const fadeIn = useSpring({
    from: { opacity: 0 },
    opacity: 1,
    reset: true,
    config: springConfigs.precision,
  })
  const handleClick = (no) => {
    setLast(current)
    setCurrent((state) => state + no)
  }
  return (
    <>
      {current !== 0 && (
        <div onClick={() => handleClick(-1)} className={css(styles.Left)}>
          <CarouselArrowLeft />
        </div>
      )}
      {current !== props.content.length - 1 && (
        <div onClick={() => handleClick(1)} className={css(styles.Right)}>
          <CarouselArrowRight />
        </div>
      )}
      <p className={css(styles.Page)}>{`${current + 1}/${
        props.content.length
      }`}</p>
      <a.div style={{ ...fadeIn, overflow: 'auto' }}>
        {props.content[current].type === 'landscape' ? (
          <ImagePopup {...{ ...props.content[current], type: 'image' }} />
        ) : (
          <TextHeavy
            imagetype={props.content[current].type}
            {...props.content[current]}
          />
        )}
      </a.div>

      {lastIdx !== undefined && (
        <a.div style={fadeOut}>
          {props.content[lastIdx].type === 'landscape' ? (
            <ImagePopup {...{ ...props.content[lastIdx], type: 'image' }} />
          ) : (
            <TextHeavy
              imagetype={props.content[lastIdx].type}
              {...props.content[lastIdx]}
            />
          )}
        </a.div>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  Left: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    padding: '0.8%',
    width: '3%',
    zIndex: 70,
    '@media (max-width: 1000px)': {
      width: '3.3%',
    },
  },
  Right: {
    position: 'absolute',
    top: '50%',
    right: 0,
    transform: 'translateY(-50%)',
    padding: '0.8%',
    width: '3%',
    zIndex: 70,
    '@media (max-width: 1000px)': {
      width: '3.3%',
    },
  },
  Page: {
    position: 'absolute',
    right: '5%',
    bottom: 0,
    fontSize: 18,
    fontFamily: 'futura',
    color: '#FFE7BA',
    marginBottom: '0.8%',
    '@media (max-width: 1000px)': {
      fontSize: 12,
    },
  },
})
