import { animated as a } from '@react-spring/web'
import React from 'react'
export const CircleWithBorder = (props) => {
    return (
        <>
        <svg width="70%" height="70%" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="32.1366" cy="32.1022" r="21" transform="rotate(-30 32.1366 32.1022)" fill="#FFE7BA"/>
        <circle cx="32.1366" cy="32.1022" r="21" transform="rotate(-30 32.1366 32.1022)" fill="#FFE7BA"/>
        <circle cx="32.1366" cy="32.1022" r="21" transform="rotate(-30 32.1366 32.1022)" fill="#FFE7BA"/>
       
            
            
        {props.selected && <a.circle  cx="32.1367" cy="32.1022" r="30" stroke="#FFE7BA" stroke-width="5"/>}
            
            
        
        </svg>
        </>
    )
}
