import React from 'react'
import { colorThemes } from '../../../constants'
import { useSound } from '../../../stores'

export const BackArrow = (props) => {
    return (
        
        <svg  {...props} width="89%" height="19%" viewBox="0 0 89 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.79834 9.40479H85.9428" stroke="#FFE7BA" stroke-width="2"/>
<path d="M10.5494 1.40479L1.79834 9.40478L10.5494 17.4048" stroke="#FFE7BA" stroke-width="2"/>
<circle cx="22.9429" cy="9.5" r="3" fill="#FFE7BA"/>
<circle cx="43.9429" cy="9.5" r="3" fill="#FFE7BA"/>
<circle cx="64.9429" cy="9.5" r="3" fill="#FFE7BA"/>
<circle cx="85.9429" cy="9.5" r="3" fill="#FFE7BA"/>
</svg>

)
}

export const BetterBackArrow = (props) => {
    const getCoords = (eraid) => {
        switch(eraid){
            case 0:
                return "28.9434"
            case 1:
                return "49.9434"
            case 2:
                return "70.9434"
            case 3:
                return "91.9434"
            }
    }
    const soundState = useSound()
                    return (
                        <svg onPointerDown={()=>soundState.play('close')} {...props} overflow='visible' height="100%" viewBox="0 0 98 31" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.79883 15.4043H88.9433" stroke="#FFE7BA" stroke-width="4"/>
        <path d="M17.4023 2.23828L3 15.4044L17.4023 28.5706" stroke="#FFE7BA" stroke-width="4"/>
        <circle cx="28.9434" cy="15.4043" r="6" fill="#FFE7BA"/>
        <circle cx="49.9434" cy="15.4043" r="6" fill="#FFE7BA"/>
        <circle cx="70.9434" cy="15.4043" r="6" fill="#FFE7BA"/>
        <circle cx="91.9434" cy="15.4043" r="6" fill="#FFE7BA"/>
        {!props.noFill && <circle cx={getCoords(props.eraid)} cy="15.4043" r="7.5" fill={(props.eraid && colorThemes[props.eraid]) || colorThemes[0]} stroke="#FFE7BA" stroke-width="3"/>}
        </svg>

    )
}