import { useThree,useFrame } from '@react-three/fiber'
import React, {useEffect, useState, useRef} from 'react'
import { lerp } from 'three/src/math/MathUtils'
import { cameraStore, useCamera, useDrag } from '../../stores'
function AdaptivePixelRatio() {
    const current = useThree((state) => state.performance.current)
    const setPixelRatio = useThree((state) => state.setDpr)
    const [dpr, setDpr] = useState(1)
    // const three = useThree(state => 
    const currentLerp = useRef(1)
    useEffect(() => {
        setPixelRatio(window.devicePixelRatio * current)
        // setPixelRatio(dpr)
    }, [current])
    return null
  }

export const Performance = ({controls}) => {
    
    const regress = useThree(state => state.performance.regress)
    const performance = useThree(state => state.performance.current)
    const setDpr = useThree(state => state.setDpr)
    const {invalidate} = useThree()
    const cameraState =  cameraStore()
    const drag = useDrag()
    // 
    useEffect(()=> {
        controls.current?.addEventListener('change',invalidate)
        controls.current?.addEventListener('change',regress)
        controls.current?.addEventListener('change',()=>{
            if(!cameraState.rotate)drag.trigger()
        })
        return () => {
            controls.current?.removeEventListener('change',invalidate)
            controls.current?.removeEventListener('change',regress)
            controls.current?.removeEventListener('change',()=>{
                if(!cameraState.rotate)drag.trigger()
            })
        }
    },[])
    useFrame(()=>{
        // 
        // setDpr(performance>0.5?lerp())
    })
    return (
        // <></>
        <AdaptivePixelRatio />
    )
}
