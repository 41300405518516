import { Html } from '@react-three/drei'
import React, { useEffect } from 'react'
import { useAudio, useBlur } from '../../stores'

export const AudioPopup = (props) => {
  //
  const audio = useAudio()
  const blur = useBlur()


  useEffect(() => {
    if (audio.title === props.title) {
      audio.clear()
    } else {
      audio.setTitle(props.title)
      audio.setUrl(props.url)
      audio.setText(props.text)
      console.log('setting', props.subsubtext)
      audio.setSubsubtext(props.subsubtext)
    }
    blur.setBlur(false)
    props.closePopup()
  }, [])

  useEffect(() => {}, [audio.isMuted])

  return null
}
