import { useSpring, useTransition } from "@react-spring/core";
import React, { useEffect, useRef, useState } from "react";
import { usePage } from "../../stores";
import { animated } from "@react-spring/web";
import { Values, zindexes } from "../../constants";
import { css, StyleSheet } from "aphrodite";
import { ClickToRotate, CurvedText, SelectedCircle, ValueWheelSVG } from "../assets/svgs/ValueWheelSVG";
import { springConfigs } from "../../constants";
import { EnterMuseum } from "../assets/svgs/EnterStuff";
import { AnimatedButton } from "../assets/AnimatedButton";

export const ValueWheel = (props) => {
  const pageState = usePage();
  const [showPage, set] = useState(false);
  const [currentIdx, setIdx] = useState(0);
  const [exitAnimation, setExit] = useState(false);
  const [lastIdx, setLast] = useState(-1);
  const [expand,setExpand] = useState(false)
  const [shouldAnimate,setShouldAnimate] = useState(true)
  const [scrollHeight,setScroll] = useState(window.innerHeight)
  const textRef = useRef()
  const last = useRef(-1)
  const semiCircle = useRef()
  useEffect(() => {
    if (pageState.page === "valuewheel") set(true);
  }, [pageState.page]);
  useEffect(()=>{
    
  })
  const fadeIn = useSpring({
    opacity: pageState.page === "valuewheel" ? 1 : 0,
    // delay:pageState.page !== "valuewheel"?500:0,
    onRest: () => {
      pageState.page !== "valuewheel" && set((state) => !state)

    }
  });

  // const exit = useSpring( {state: })
  useEffect( () => {
    setShouldAnimate(true)
  },[currentIdx])
  const enter = useSpring({
    from: { left: 0 },
    left: 1,
    reset: shouldAnimate,
    config: springConfigs.precision,
    onRest:()=>{
      setShouldAnimate(false)
      setLast(currentIdx)
    }
  });
  const exitSpring = useSpring({
    from: { top: 1 },
    top: 0,

    reset: shouldAnimate,
    config: springConfigs.precision,
  });
  const delayedEnter = useSpring({
    from: { left: 0 },
    left: 1,
    delay: 200,
    reset: shouldAnimate,
    config: springConfigs.precision,
  });
  const rotate = useSpring({
    from: { state: currentIdx },
    state: currentIdx + 1,
    // delay:expand?
    config: springConfigs.precision,
    onStart:()=>expand && setExpand(false)

  });
  const expandSpring = useSpring({
    height:expand?textRef.current.scrollHeight:window.innerHeight/4,
  })
  const handleClick = () => {
    pageState.setPage("timelines");
  };
  const handleExit = (e) => {
    if (currentIdx === 4 || e.target === semiCircle.current) {
      // handleClick();
      return;
    }
    setExit(true);
    setIdx((state) => state + 1);
  };
  const goBack = () => {
    
    if(currentIdx === 0){
      return
    }
    setIdx(state =>state-1)
  }
  return (
     <animated.div
      style={fadeIn}
      className={showPage ? css(styles.Container) : css(styles.ClickThrough)}
    >
      {/* <div onClick={handleClick} className={css(styles.EnterMuseum)}>
        <EnterMuseum/>

      </div> */}
      <AnimatedButton 
          // fade={fadeSpring} 
          value={currentIdx===4}
          clickEvent={props.goBack} 
          top='1%' 
          left='8%' 
          text='BACK' 
        />
      <animated.img
        style={{ opacity: exitSpring.top.to((x) => 1-x) }}
        // style={{ opacity: rotate.state.to(x=>x)-currentIdx-1 }}
        className={css(styles.Img)}
        height='100%'
        src={Values[currentIdx].image}
        alt=""
      />
      <a href='#' className={css(styles.Expand)} style={{position:'absolute',zIndex:100}} onClick={() => setExpand(state => !state)}>{expand?'See Less':'See More'}</a>

      {lastIdx >= 0 && (
        <animated.img
          style={{ opacity: exitSpring.top.to((x) => x) }}
          // style={{ opacity: currentIdx-rotate.state.to(x=>x) }}
          className={css(styles.Img)}
          src={Values[lastIdx].image}
          // height='100%'
          // width='100%'
          alt=""
        />
      )}


      <animated.p
        ref={textRef}
        style={{ marginBottom: enter.left.to((x) => `${-40+ x*40}vh`),height: expandSpring.height.to(x=>x) }}
        className={css(styles.Text)}
      >
        {Values[currentIdx].text}
      </animated.p>
      {lastIdx!==-1 && (
        <animated.p
          style={{ marginBottom:'0vh',marginLeft: exitSpring.top.to((x) => `${(1 - x) * 80}vw`), height:expand?scrollHeight:window.innerHeight/4 }}
          className={css(styles.Text)}
        >
          {Values[lastIdx].text}
        </animated.p>
      )}
      <div className={css(styles.SlantedDiv)}>
        {/* entry animation div */}
        <animated.div
          className={css(styles.TitleContainer)}
          style={{
            left: enter.left.to((x) => `${30 + (1 - x) * 70}%`),
            position: "absolute",
          }}
        >
          <h1 className={css(styles.Title)}>{Values[currentIdx].title}</h1>
        </animated.div>

        {lastIdx >= 0 && (
          <animated.div
            className={css(styles.TitleContainer)}
            style={{
              left: "30%",
              position: "absolute",
              opacity: exitSpring.top.to((x) => x),
              // opacity: exitSpring..to((x) => x),
            }}
          >
            <h1 className={css(styles.Title)}>{Values[lastIdx].title}</h1>
          </animated.div>
        )}
        {/* <div className={css(styles.Bar)}>
          <PercentageBar />
        </div> */}
      </div>
      <div
        
        // onClick={() => handleExit()}
        className={css(styles.Wheel)}
      > 
        <div onClick={(e)=>handleExit(e)} className={css(styles.CircleDiv)}>
        <div ref={semiCircle} onClick={goBack} className={css(styles.SemiCircle)}></div>

          <div className={css(styles.ClickToRotate)}>
            <ClickToRotate  />
          </div>
          <div className={css(styles.CurvedText)}>
            <CurvedText />
          </div>
          <div className={css(styles.Selected)}>
            <SelectedCircle />
          </div>
        </div>
        <ValueWheelSVG style={{zIndex:140,
          transform: exitAnimation
            ? rotate.state.to((x) => `rotate(${(x - 1) * -72}deg)`)
            : `rotate(${currentIdx * -72}deg)`,
            // backgroundColor:'red',
            borderRadius:'100%'
        }} />
      </div>

    </animated.div>
  );
};

const styles = StyleSheet.create({
  Container: {
    textAlign: "left",
    position: "absolute",
    height: "100%",
    width: "100%",
    backgroundColor: "#36AA9D",
    overflow: "hidden",
    zIndex: zindexes.valueWheel,
    // '@media (max-height: 370px)':{
    //     height:'60vh'
    // }
  },
  ClickThrough: {
    textAlign: "center",
    position: "absolute",
    height: "100%",
    width: "100%",
    backgroundColor: "#36AA9D",

    // zIndex:zindexes.valueWheel,
    pointerEvents: "none",
    overflow: "hidden",
  },
  Wheel: {
    position: "absolute",
    top: "-10vh",
    left: "-35.5vw",
    width: "94vw",
    height: "94vw",
    color: "red",
    zIndex: 130,
    pointerEvents:'none',

    // "@media (min-width: 565px)": {
    //   top: "-6vh",
    // },
    // "@media (max-width: 1250px)": {
    //   top: "23vh",
    //   transform:'scale(1.2)'
    // },
    "@media (min-width: 860px)": {
      top: "5vh",
      transform:'scale(1.2)'

    },
    "@media (min-width: 1400px)": {
      top: "-10vh",
      transform:'scale(1.0)'
      
    },
    // "@media (maxn-width: 1200px)": {
    //   top: "-25vh",
    //   transform:'scale(1.2)'
      
    // },
    "@media (max-height: 350px)": {
      top: "-21vh",
    
    },
    "@media (max-height: 270px)": {
      top: "-34vh",
      transform:'scale(0.8)'
    },
    
  },
  SlantedDiv: {
    backgroundColor: "#36AA9D",
    zIndex: zindexes.valueWheel + 3,
    position: "absolute",
    width: "100vw",
    height: "60vh",
    top: "-36vh",
    borderBottom:'#FFE7BA solid 1vw',
    transform: "rotate(-30deg)",
    // "@media (min-width:960px)": {
    //   top:"-25vh"
    // },
  },
  SemiCircle:{
    position:'absolute',
    width: '64vw',
    height: '20vw',
    marginLeft:'-18%',
    marginTop:'17%',
    cursor:'pointer',
    transform:'rotate(-25deg)',
    // borderTop: '64vw solid blue',
    // backgroundColor:'grey',
    borderRadius:'48vw 48vw 0 0',
    pointerEvents:'auto',
    zIndex:9000

  },
  CircleDiv: {
    backgroundColor: "#36AA9D",
    position: "absolute",
    width: "64vw",
    height: "64vw",
    top: "17%",
    left: "15%",
    borderRadius: "100%",
    transform: "rotate(-25deg)",
    // backgroundColor:'red',
    pointerEvents:'auto',
    cursor:'pointer',
  },
  Selected: {
    position: "absolute",
    width: "11%",
    left:"63.9%",
    top:"27%",
    pointerEvents:'none'
  },
  Img: {
    // filter: 'grayscale(100%)',
    position: "absolute",
    right:0,
    top:0,
    width: "70vw",
    height:'110%',
    bottom:0,
    zIndex:5,
    // backgroundColor: "grey",
    background: 'rgba(104, 88, 58, 0.55)',
    backdropFilter: 'blur(4px)',
  },
  TitleContainer: {
    position: "absolute",
    // backgroundColor:'black',
    bottom:'-6%',
    width:'35vw',
    marginLeft: "-2%",
    fontFamily: "Bungee Inline",
    color: "#FFE7BA",
    fontWeight: "1",
    // fontSize: "4.5vh",
    // whiteSpace:'nowrap'
    '@media (max-height: 400px)':{
      marginLeft:0
    }
  },
  Title: {
    fontSize:'5vh',
    fontWeight: 300,

    lineHeight:'6vh'
  },
  Expand:{
    fontSize:18,
    fontFamily: "futura",
    fontWeight: 400,
    color: "#FFE7BA",
    position:'absolute',
    right:'2.3vw',
    bottom:'1vh',
    '@media (max-width: 1100px)':{
      fontSize:14
    },
    '@media (max-height: 400px)':{
      fontSize:13
    }
  },
  Text: {
    width: "47vw",
    overflow:'hidden',
    textAlign: "right",
    fontFamily: "futura",
    fontWeight: 400,
    color: "#FFE7BA",
    fontSize: 22,
    position: "absolute",
    // lineHeight:'30px',
    left: "52vw",
    bottom: "5vh",
    zIndex: 6,
    lineHeight:'110%',
    "@media (max-width: 1100px)": {
      fontSize: 14,

    },
    "@media (max-height: 420px)":{
      width:'54vw',
      left:"45vw"
    },
    "@media (max-height: 320px)":{
      width:'54vw',
      left:"45vw",
      fontSize:12,

    }
  },
  ClickToRotate:{
    position:'absolute',
    width:'15%',
    top:'5.9%',
    left:'63.4%',
    transform:'rotate(26deg)'
    
  },
  CurvedText:{
    position:'absolute',
    width:'8%',
    top:'40%',
    left:'92.5%',
    transform:'rotate(10deg)'
  },
  EnterMuseum: {
    position:'absolute',
    width:'7vw',
    left:'1vw',
    top:'1vw',
    zIndex:200
  }
});
