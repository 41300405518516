import { css,StyleSheet } from 'aphrodite'
import React, { useEffect } from 'react'
import { colorThemes } from '../../../constants'
import { useAudio } from '../../../stores'
import { CloseIcon } from '../../assets/svgs/Buttons'


export const AudioText = ({title,text,eraid,subsubtext}) => {
    const audio = useAudio()
    useEffect(()=>{
        
    })
    return (
        <div className={css(styles.Container)} style={{backgroundColor:colorThemes[eraid]}}>
            <div onClick={()=>audio.clear()}><CloseIcon  className={css(styles.Close)}/></div>
            <h1 className={css(styles.Title)}>{title}</h1>
            <p className={css(styles.Text)}>{text}</p>
            {/* <p>{}</p> */}
            <p className={css(styles.italised)}>{subsubtext}</p>
        </div>
    )
}

const styles = StyleSheet.create({
    Container:{
        position:'absolute',
        width:'80%',
        // height:'30%',
        marginLeft:'10%',
        marginRight:'10%',
        bottom:'15%',
        // backgroundColor:'green',
        zIndex:100,
        // fontSize:'2vw'
        paddingLeft:'2%',
        paddingRight:'2%',
        paddingTop:'1%',
        paddingBottom:'1.2%',
        fontFamily:'futura',
        textAlign:'left',
        color:'#FFE7BA',

        
    },
    Title:{
        fontSize:18,
        lineHeight:'90%',
        fontFamily:'Bungee inline',
        fontWeight:1,
        '@media (max-width: 900px)':{
            fontSize:14
        }
    },
    Text:{
        fontSize:16,
        margin:0,
        paddingBottom:'1%',
        marginTop:'-0.5%',
        '@media (max-width: 900px)':{
            fontSize:12
        }
    },
    Close:{
        position:'absolute',
        top:'3.2%',
        zIndex:620,
        right:'-0.1%',
        height:'2.1vh',
        width:'1.9vw',
        '@media (max-width: 900px)':{
            height:'3.1vh'
        }
        // }

    },
    italised:{
        fontFamily:'futura',
        fontStyle:'italic',
        fontSize:16,
        margin:0,
        lineHeight:19 + 'px',
        '@media (max-width: 900px)':{
            fontSize: 12,
            lineHeight:14 + 'px'
        }
    }
})