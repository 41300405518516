import React from "react"
import { StyleSheet,css } from "aphrodite"
export const Heading = ({currentLocation}) => {
    return (
        <div style={{
            pointerEvents:'none',
            position:'absolute',
            top:0,
            textAlight:'center',
            width:'100%',
            zIndex:80
        }}>
            <h3 className={css(styles.Title)}><b>{currentLocation.eraTitle}</b></h3>
            <p className={css(styles.Text)}>{currentLocation.name==='videobooth'?'Video Booth':currentLocation.name}</p>
        </div>
    )
} 

const styles = StyleSheet.create({
    Title:{
        color:'#FFE7BA',
        fontFamily: 'Source Sans Pro',
        fontWeight:18,
        // fontSize:'1.4vw',
        
        fontSize:'18',
        lineHeight:'0.6vw',
        '@media (max-width: 1100px)':{
            fontSize:'1.7vw'
        }
    },
    Text:{
        color:'#FFE7BA',
        fontFamily:'Source Sans Pro',
        fontSize:16,
        fontWeight:1,
        lineHeight:'0',
        '@media (max-width: 1100px)':{
            fontSize:'1.5vw'
        }
    }
})