import React, {useState } from 'react'
import { StyleSheet,css } from 'aphrodite'
import { animated } from '@react-spring/web'
import { useLocations, useSound } from '../../../stores'
import { CircleWithBorder } from '../../assets/svgs/CircleWithBorder'
import { useSpring } from '@react-spring/core'
import {
    TimelineDates1897,
    TimelineDates19401,
    TimelineDates19402,
    TimelineDates1970,
} from "../../assets/svgs/TimelineDates";

const getSVG = (eraid) => {
    if (eraid === 0) return <TimelineDates1897 full />;
    if (eraid === 1) return <TimelineDates19401 full />;
    if (eraid === 2) return <TimelineDates19402 full />;
    if (eraid === 3) return <TimelineDates1970 full />;
};
export const EraItem = ({fadeSpring,handleClick,era,selected}) => {
    const locationsState = useLocations()
    const soundState = useSound()
    // const [selected,set] = useState(false)
    // const handleRest = () => {
        
    //     handleClick(era.era)
    // }
    // const spring = useSpring({ opacity:selected?1:0,config:{}, onStart:()=> handleRest()})
    return (
        <animated.div
            onPointerDown={()=>soundState.play('hud')}
            style={{ opacity: fadeSpring.opacity.to((x) => x) }}
            onClick={() => handleClick(era.era)}
        >
            <div className={css(styles.Items)}>
                <div className={css(styles.Circle)}>
                    <CircleWithBorder
                        era={era}
                        handleClick={handleClick}
                        // spring={spring}
                        selected={
                            selected
                        }
                    />
                </div>

                {getSVG(era.eraid)}
            </div>
        </animated.div>
    )
}

const styles = StyleSheet.create({
    Items: {
        width: "7vw",
        lineHeight: "20vh",
        marginLeft: "2vw",
        "@media (max-height: 370px)": {
            width: "5vw",
        },
    },
    Circle: {
        position: "absolute",
        width: "6vw",
        marginTop:'1%',
        left: "-2.1%",
        '@media (max-width: 800px)':{
            left: "-2.43%"
        },
        "@media (min-width: 565px)": {
        },
        "@media (min-width: 768px)": {
        },
        "@media (min-width: 902px)": {
            left:'-1.73%'
        },
        "@media (min-width: 1350px)": {
            left: "-2.1%",

        },
        "@media (max-height: 370px)":{
            left:"-2.5%"
        },
        "@media (max-height: 320px)":{
            left:"-2.6%"
        },
    },
})