import React, { Suspense, useRef, useEffect, useState } from 'react'
import { useCamera, useSound } from '../../stores'
import { CloseIcon } from '../assets/svgs/Buttons'
import Model from '../Models/Model'
import { Html, OrbitControls, PerspectiveCamera } from '@react-three/drei'
import { Canvas } from '@react-three/fiber'
import { css, StyleSheet } from 'aphrodite'
import { rgba } from '@react-spring/shared'
import { useSpring } from '@react-spring/core'
import { animated } from '@react-spring/web'
import AR from './Ar'
import { Tada } from '../assets/svgs/Tada'
import Ballot_box from '../Models/Ballot_box'
import Refrigerator from '../Models/Refrigerator'
import { springConfigs } from '../../constants'
import Skybox from '../360Viewer/Skybox'
import QRImage from './textures/QRMDC.png'
import Navtal from '../Models/Navtal'
import SquareLock from '../Models/Lock4'
import Typewriter from '../Models/Typewriter'
import QRCode from 'react-qr-code'
import { CubeRefractionMapping } from 'three'
import ReactGA from 'react-ga'
import { isDesktop, isMobile } from 'react-device-detect'
import { sendEvent } from '../GA/google-analytics'
const ThreePoint = () => {
  return
}

export const ModelViewer3D = (props) => {
  const [active, setActive] = useState(false)
  const [finishedFadein, setFinishedFadeIn] = useState(false)
  const cameraState = useCamera()
  const [startLoading, setStartLoading] = useState(false)
  const [showQR, setShowQR] = useState(false)
  const [rotate, setRotate] = useState(true)

  const soundState = useSound()

  const handleClose = () => {
    props.setShowHotspots(true)
    props.setShow(false)
  }
  let typeTime
  const type = (e) => {
    if (!shouldType) {
      soundState.play('type')
      setShouldType(true)
      typeTime = setTimeout(() => {
        setShouldType(false)
      }, 1400)
    }
  }
  const onClickAR = () => {
    setShowQR((state) => !state)
    console.log('clicked')
    sendEvent('Opened AR', 'AR', '3DModel-AR')
    // ReactGA.event({
    //     category:"AR",
    //     action:'Opened AR'
    // })
  }
  useEffect(() => {
    props.name === 'typewriter' && window.addEventListener('keydown', type)

    return () =>
      props.name === 'typewriter' && window.removeEventListener('keydown', type)
  }, [])

  const notDefaultCamera = useRef()

  const spring = useSpring({
    state: 1,
    from: { state: 0 },
    config: springConfigs.precision,
    onRest: () => setFinishedFadeIn(true),
  })
  const [loaded, setLoaded] = useState(false)
  const [QRLink, setLink] = useState({})
  const [shouldType, setShouldType] = useState(false)
  const showQRSpring = useSpring({
    marginTop: showQR ? '-14%' : '24%',
    opacity: showQR ? 1 : 0,
  })
  return (
    <>
      <div className={css(styles.Container)}>
        <animated.div style={{}} className={css(styles.TextContainer)}>
          <animated.h2
            style={{ opacity: spring.state.to((x) => x) }}
            className={css(styles.Heading)}
          >
            {props.title}
          </animated.h2>
          <animated.p
            style={{ opacity: spring.state.to((x) => x) }}
            className={css(styles.Paragraph)}
          >
            {props.text}
          </animated.p>
          {/* <animated.img style={{opacity:showQRSpring.opacity}} className={css(styles.QR)} width='50%' height='50%' src={QRImage} alt='' /> */}
          {props.name === 'typewriter' && (
            <animated.a
              target="_blank"
              href={
                'https://www.amazon.in/Great-Truth-Regard-Story-Typewriter/dp/8193274008'
              }
              style={{ opacity: spring.state.to((x) => x), margin: 0 }}
              className={css(styles.Paragraph, styles.link)}
            >
              Learn more
            </animated.a>
          )}
          {isDesktop && (
            <animated.div
              style={{ textAlign: 'center', marginTop: '8%', width: '100%' }}
            >
              {/* <animated.div style={{textAlign:'center'}} > */}
              <p style={{ textAlign: 'center' }}>Scan me for AR!</p>
              <QRCode value={QRLink.android || 'couldnt find intent'} />

              {/* </animated.div> */}
              {/* <QRCode value={QRLink}/> */}
            </animated.div>
          )}
        </animated.div>
      </div>
      <div
        onTouchStart={() => setRotate(false)}
        onClick={() => setRotate(false)}
        className={css(styles.Canvas)}
      >
        {
          <AR
            setLink={setLink}
            style={{ left: 40, position: 'absolute' }}
            onClick={onClickAR}
            name={props.name}
          />
        }
        {/* {loaded && <Tada />} */}
        <Canvas>
          <PerspectiveCamera
            position={[0, 2, -5]}
            makeDefault
          ></PerspectiveCamera>
          <Skybox name={props.name} />
          {/* <pointLight position={[10, 7, 10]} />
                            <pointLight position={[-10,5,10]} /> */}
          {/* <pointLight position={[-10,0,-10]} /> */}
          {/* <pointLight position={[10,-7,-10]} /> */}
          <ambientLight intensity={props.name === 'ballot' ? 0.2 : 0} />
          <OrbitControls
            enableZoom={false}
            autoRotateSpeed={-1}
            autoRotate={rotate}
            enableDamping={false}
          />
          {finishedFadein && (
            <Suspense fallback={null}>
              {props.name === 'ballot' ? (
                <Ballot_box finishedLoading={() => setLoaded(true)} />
              ) : props.name === 'refrigerator' ? (
                <Refrigerator finishedLoading={() => setLoaded(true)} />
              ) : props.name === 'navtal' ? (
                <Navtal finishedLoading={() => setLoaded(true)} />
              ) : props.name === 'squarelock' ? (
                <SquareLock finishedLoading={() => setLoaded(true)} />
              ) : (
                <Typewriter
                  shouldType={shouldType}
                  finishedLoading={() => setLoaded(true)}
                />
              )}
            </Suspense>
          )}
        </Canvas>
      </div>
    </>
  )
}

const styles = StyleSheet.create({
  Canvas: {
    position: 'absolute',
    width: '48%',
    height: '100%',
  },
  Container: {
    position: 'absolute',
    left: '48%',
    // backgroundColor:'rgba(51,150,139,0.6)',
    width: '52%',
    height: '100%',
    // backgroundColor:'red',
    // overflowY:'scroll',
    '@media (max-width: 800px)': {
      overflow: 'auto',
      marginTop: '5%',
      height: '90%',
      marginBottom: '5%',
      marginRight: '5%',
      top: '-5%',
      transform: 'translateY(0%)',
    },
    // overflow:'auto',
  },
  TextContainer: {
    // paddingTop:'20%',
    textAlign: 'left',
    marginTop: '5.4%',
    overflowY: 'auto',
    position: 'absolute',
    height: '87%',
    // width:'100%',
    // backgroundColor:'red',
    // textAlign:'right',
    marginRight: '1.6%',
    paddingRight: '5%',
    color: '#FFE7BA',
    overflow: 'auto',
    fontSize: 18,
    zIndex: 500,
    pointerEvents: 'auto',
    cursor: 'auto',
    top: '0%',

    // transform:'translateY(-50%)',
  },
  Heading: {
    fontFamily: 'Bungee Inline',
    fontWeight: '1',
    lineHeight: '17px',
    fontSize: 22,
    // '@media (max-height: 400px)':{
    //     lineHeight:'0%',

    // },
    '@media (max-width: 1100px)': {
      fontSize: 17,
      lineHeight: '13px',
    },
  },
  Paragraph: {
    fontSize: 18,
    fontFamily: 'futura',
    // '@media (max-width: 900px)':{
    //     fontSize:12,
    '@media (max-width: 1100px)': {
      fontSize: 12,
    },
    // },
  },
  Close: {
    position: 'absolute',
    top: '5%',
    left: '95%',
    zIndex: 60,
    // '@media (max-width: 1000px)':{
    //     transform:'scale(0.7)'

    // }
  },
  QR: {
    // position:'absolute',
    // marginRight:'20%',
    // marginTop:'10%'
    opacity: 0,
    width: '100%',
  },
  QRPic: {
    padding: '1%',
    paddingRight: '6%',
    float: 'left',
  },
  QRText: {
    // textAlign:'center'
  },
  link: {
    color: '#FFE7BA',

    ':link': {
      color: '#FFE7BA',
    },
  },
})
