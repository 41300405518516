import { Html } from '@react-three/drei'
import { StyleSheet, css } from 'aphrodite'
import React, { useEffect, useRef, useState } from 'react'
import { CloseIcon, TidBits } from '../assets/svgs/Buttons'
import { popupColorThemes as colorThemes, springConfigs } from '../../constants'
import { TextHeavy } from '../popups/TextHeavy'
import { ImagePopup } from '../popups/ImagePopup'
import { VideoPopup } from '../popups/VideoPopup'
import { ModelViewer3D } from '../popups/3DModelViewer'
import { AudioPopup } from '../popups/AudioPopup'
import { cameraStore, useAudio, useBlur, useDrag, useSound } from '../../stores'
import { usePage } from '../../stores'
import { useSpring } from '@react-spring/core'
import { animated as a } from '@react-spring/web'
import { Carousel } from './Carousel'
import { HorizontalScroll } from './HorizontalScroll'
import ReactGA from 'react-ga'
import { sendEvent } from '../GA/google-analytics'
export const PopupScreen = (props) => {
  // const [media, s]
  // const handleClose = () => {
  //     props.setShowHotspots(true)
  //     props.setShow(false)
  // }
  const [fadeout, setFadeout] = useState(false)
  const spring = useSpring({ opacity: 1, from: { opacity: 0 } })
  const soundState = useSound()
  const pageState = usePage()
  const drag = useDrag()
  const audio = useAudio()
  const blur = useBlur()
  const child = useRef()
  const cameraState = cameraStore()
  const fadeoutSpring = useSpring({
    opacity: fadeout ? 0 : 1,
    from: { opacity: 0 },
    onRest: () => {
      fadeout && props.closePopup()
      fadeout && cameraState.stopAndResume()
    },
    // config:springConfigs.linear
  })
  useEffect(() => {
    fadeout && drag.trigger()
  }, [fadeout])

  useEffect(() => {
    blur.setBlur(true)
    cameraState.stop()
    window.addEventListener('click', handleClickOutside)
    // window.addEventListener('touchstart',handleClickOutside)
    sendEvent(
      'opened popup' ,
      props.type,
      'popup title: ' + props.title
    )
    // console.log('onCLick' + props.type)
    return () => {
      window.removeEventListener('click', handleClickOutside)
      // cameraState.stopAndResume()
      sendEvent(
        'closed popup',
        props.type,
        'title: ' + props.title
      )
    }
  }, [])

  const switchRender = (type) => {
    switch (type) {
      case 'image':
      case 'comic':
        return <ImagePopup {...props} />
      case 'carousel':
        return <Carousel {...props} />
      case 'textheavy':
        return <TextHeavy {...props} />
      case 'video':
        return <VideoPopup {...props} />
      case 'audio':
        return <AudioPopup {...props} />
      case 'object':
        return <ModelViewer3D {...props} />
      case 'collage':
        return <HorizontalScroll {...props} />
      default:
        return null
    }
  }
  const handleClick = () => {
    blur.setBlur(false)
    setFadeout(true)
    soundState.play('close')
  }
  const handleClickOutside = (e) => {
    //
    if (!props.noDrei && e.target === child.current.parentElement.parentElement)
      handleClick()
  }

  return !props.noDrei ? (
    <Html style={{ textAlign: 'center', pointerEvents: 'auto' }} fullscreen>
      {/* style={{backgroundColor:{colorThemes[props.eraId]}}} */}
      <a.div style={spring}>
        <a.div
          ref={child}
          style={{
            backgroundColor: colorThemes[props.eraid],
            opacity: fadeoutSpring.opacity,
          }}
          className={css(styles.Container)}
        >
          <div onClick={() => handleClick()}>
            <CloseIcon className={css(styles.Close)} />
          </div>

          {switchRender(props.type)}
        </a.div>
      </a.div>
    </Html>
  ) : (
    <a.div
      onClick={(e) =>
        e.target === child.current.parentElement && props.closePopup()
      }
      style={{
        opacity: fadeoutSpring.opacity,
        position: 'absolute',
        zIndex: 3400,
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        backgroundColor: '#3B3B3B',
      }}
    >
      <a.div
        ref={child}
        style={{ backgroundColor: colorThemes[1] }}
        className={css(styles.Container)}
      >
        <div onClick={() => setFadeout(true)}>
          <CloseIcon className={css(styles.Close)} />
        </div>

        {switchRender(props.type)}
      </a.div>
    </a.div>
  )
}

const styles = StyleSheet.create({
  Container: {
    // textAlign:'center',
    position: 'absolute',
    left: '50%',
    top: '50%',
    // marginBottom:'2%',
    transform: 'translateY(-51.5%) translateX(-50%)',
    height: '79%',
    minWidth: '92%',
    // padding:"1.8%",
    // background:'crimson',
    zIndex: 3300,
    overflow: 'hidden',
    textAlign: 'center',
    '@media (max-width: 1300px)': {
      transform: 'translateY(-50%) translateX(-50%)',

      height: '90%',
      width: '90%',
    },
  },
  Image: {
    // posit',
    border: '#FFE7BA 4px solid',

    position: 'absolute',
    width: '96.3%',
    height: '85.45%',
    // overflow:'auto',
  },
  TextContainer: {
    zIndex: 100,
    position: 'absolute',
    width: '96.5%',
    height: '8%',
    bottom: 0,
    textAlign: 'center',
  },
  Heading: {
    color: '#FFE7BA',

    bottom: 0,
    fontFamily: 'Bungee Inline',
    fontWeight: '1',
    lineHeight: '2%',
    '@media (max-height: 400px)': {
      lineHeight: '0%',
    },
  },
  Close: {
    position: 'absolute',
    top: '1.2%',
    zIndex: 620,
    right: '0.3%',
    height: '2.5vh',
    width: '1.9vw',
    '@media (max-width: 900px)': {
      height: '3.5vh',
    },
    // background:'black'
    // '@media (max-width: 1000px)':{
    //     transform:'scale(0.7)'
    // },
    // '@media (max-width: 600px)':{
    //     transform:'scale(0.55)',
    //     top:0,
    //     right:0
    // }
  },
})
