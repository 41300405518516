import { css, StyleSheet } from "aphrodite";
import React, { useEffect, useState } from "react";
import { fadeOut, pulse } from "react-animations";
import fadeIn from "react-animations/lib/fade-in";
import { isAndroid, isDesktop, isIOS } from "react-device-detect";
import ButtonImgDark from "./textures/ar-dark.png";
import ButtonImg from "./textures/ar.png";
import QRCode from "react-qr-code";
import { cameraStore } from "../../stores";

/*
Change only the below two
*/
const getGlb = (name) => {
    switch(name){
        case 'ballot':
            return 'ballot_box.glb'
        case 'refrigerator':
            return 'Refrigerator_10.glb'
        case 'navtal':
            return 'NAVTAL_02.glb'
        case 'squarelock':
            return 'Lock_10.glb'
        case 'typewriter':
            return 'Typewriter_06.glb'
    }
}
const getUsdz = (name) => {
    switch(name){
        case 'ballot':
            return 'ballot_box.usdz'
        case 'refrigerator':
            return 'refrigerator.usdz'
        case 'navtal':
            return 'navtal.usdz'
        case 'squarelock':
            return 'squarelock.usdz'
        case 'typewriter':
            return 'typewriter.usdz'
    }
}
export default function AR(props) {
    const baseurl = window.location.href+'Models/';
    console.log('base url', baseurl)
    // const baseurl =window.location.href + `/Models/`;
    console.log('baseurl', process.env.PUBLIC_URL)
    const camera = cameraStore()
    // let baseurl = window.location.href
    // console.log('url', url)
    const AndroidURL =  baseurl + getGlb(props.name)
    //   "https://aws.autovrse.in/godrej-lock/_webar/AdvantisLock.glb";
    // const AndroidURL = `./Models/ballot_box.glb`
    const IOSIntent = baseurl + getUsdz(props.name)
    //   "https://aws.autovrse.in/godrej-lock/_webar/AdvantisLock.usdz";
    
    const AndroidIntent =
    "intent://arvr.google.com/scene-viewer/1.0?file=" +
    AndroidURL +
    "#Intent;scheme=https;package=com.google.android.googlequicksearchbox;action=android.intent.action.VIEW;S.browser_fallback_url=https://developers.google.com/ar;end;";
  const [QR, setQR] = useState(false);

  function OnRequestAR() {
    window.open(AndroidIntent);
  }
  useEffect(()=>{
    // props.setLink({android:'https://ar-redirect.autovrse.app/'+AndroidIntent,ios:IOSIntent})
    // props.setLink({android:`https://ar-redirect.autovrse.app/?android=${AndroidURL}&ios=${IOSIntent}`,ios:IOSIntent})
    props.setLink({android:`https://ar-redirect.autovrse.app/?android=${AndroidURL}&ios=${IOSIntent}`})
  },[props.name])
  useEffect(()=> {
    camera.stop()
  },[QR])
  function OnRequestARDesktop() {
    let temp = !QR;
    window.location.
    setQR(temp);
  }

  return (
    <div style={{display:isDesktop?'none':'auto'}} className={css(styles.container)}>
      {isAndroid && !isIOS && (
        <button onClick={OnRequestAR} className={css(styles.button)}>
          <img alt="Start AR" className={css(styles.image)} src={ButtonImg} />
        </button>
      )}
      {isIOS && (
        <a rel="ar" href={IOSIntent}>
          <img
            alt="Start AR"
            src={ButtonImgDark}
            className={css(styles.image)}
          />
        </a>
      )}

      {isDesktop && (
        <>
          {!QR && (
            <button onClick={props.onClick} className={css(styles.button)}>
              <img
                alt="Start AR"
                className={css(styles.image)}
                src={ButtonImg}
              />
            </button>
          )}
          {QR && (
            <div className={css(styles.qrcontainer)}>
              <button onClick={props.onClick} className={css(styles.qrButton)}>
                {/* <img alt="Open on Mobile" className={css(styles.qr)}src={QRImage}></img> */}
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    top: 10,
    left: 10,
    height: 45,
    textAlign: "center",
    zIndex: 120,
    "@media (max-width: 1100px)": {
      transform: "scale(0.7)",
    },
  },
  button: {
    outline: "none",
    background: "none",
    border: "none",
    animationName: pulse,
    animationDuration: "1s, 1200ms",
    animationIterationCount: "infinite",
    animationFillMode: "forwards",
    ":active": {
      transform: "scale(0.8)",
    },
  },
  image: {
    width: 45,
    animationName: pulse,
    animationDuration: "1s, 1200ms",
    animationIterationCount: "infinite",
    animationFillMode: "forwards",
  },
  qrcontainer: {
    animationName: fadeIn,
    animationDuration: "1s, 1200ms",
    animationIterationCount: "once",
    animationFillMode: "forwards",
  },
  qr: {
    height: 150,
  },
  qrButton: {
    outline: "none",
    background: "none",
    border: "none",
  },
});
