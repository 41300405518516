/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useEffect, useState, useRef } from "react";
import { useGLTF, useAnimations, Html } from "@react-three/drei";
import { useSpring } from "@react-spring/core";
import { animated as a } from "@react-spring/three";
import { animated } from "@react-spring/web";
import * as THREE from "three";
import { Color, DoubleSide, MultiplyBlending } from "three";
import { useSound } from "../../stores";
export default function Refrigerator(props) {
  const group = useRef();
  const object = useGLTF("./Models/Refrigerator_10.glb");
  const { actions } = useAnimations(object.animations, group);
  const soundState = useSound();

  const [open, setOpen] = useState(false);
  const handleClick = () => {
    soundState.play("hud");
    actions.Animation.reset();
    actions.Animation.play();
  };
  useEffect(() => {
    actions.Animation.paused = false;
    actions.Animation.loop = THREE.LoopOnce;
    // actions.Animation.play()
    // actions.Animation.
    actions.Animation.clampWhenFinished = true;
    // actions.Animation.paused=true
    props.finishedLoading();
  }, []);

  const spring = useSpring({ state: 2.0, from: { state: 0 } });

  return (
    <>
      <a.group
        onPointerDown={handleClick}
        rotation={[0, Math.PI + Math.PI / 6, 0]}
        scale={spring.state.to((x) => x)}
        ref={group}
        {...props}
        dispose={null}
      >
        <primitive object={object.scene} ref={group} />
        {/* <group scale={3.28}>
        <group position={[0, 0.13, 0.09]} scale={0.3}>
          <mesh geometry={nodes.Cube002_1.geometry} material={nodes.Cube002_1.material} />
          <mesh geometry={nodes.Cube002_2.geometry} material={nodes.Cube002_2.material} />
          <mesh geometry={nodes.Cube007.geometry} material={nodes.Cube007.material} position={[0.01, -0.42, -0.28]} />
          <mesh geometry={nodes.Cube025.geometry} material={nodes.Cube025.material} position={[0.01, -0.42, -0.28]} />
        </group>
        <group position={[0.1, 0.01, 0.1]} rotation={[0, -Math.PI / 2, 0]} scale={[0.15, 0.3, 0.3]}>
          <mesh geometry={nodes.Cube003_1.geometry} material={materials.Door_CreamPastic} />
          <mesh geometry={nodes.Cube003_2.geometry} material={nodes.Cube003_2.material} />
          <mesh geometry={nodes.Cube003_3.geometry} material={materials.Door_Rubber} />
          <mesh geometry={nodes.Cube003_4.geometry} material={nodes.Cube003_4.material} />
          <group position={[-0.06, 0.25, 0.33]} rotation={[0, 0.02, 0]} scale={[2.08, 1.02, 1]}>
            <mesh geometry={nodes.Cube038.geometry} material={nodes.Cube038.material} />
            <mesh geometry={nodes.Cube038_1.geometry} material={nodes.Cube038_1.material} />
            <mesh
              geometry={nodes.Text.geometry}
              material={nodes.Text.material}
              position={[-0.01, -0.02, 0.03]}
              rotation={[-Math.PI / 2, 0, Math.PI / 2]}
              scale={[-0.01, -0.01, -0.01]}
            />
          </group>
          <group position={[0.15, 0.43, 0.2]} rotation={[-Math.PI, 1.55, -Math.PI]} scale={[0.11, 0.11, 0.22]}>
            <mesh
              geometry={nodes.Plane025.geometry}
              material={materials.Logo}
              position={[-0.15, 0.24, 0]}
              scale={[-0.12, -0.1, -0.12]}
            />
          </group>
          <mesh geometry={nodes.Cube035_1.geometry} material={nodes.Cube035_1.material} />
          <mesh geometry={nodes.Cube035_2.geometry} material={nodes.Cube035_2.material} />
          <mesh geometry={nodes.Cube035_3.geometry} material={materials['PinkPlastic.001']} />
          <mesh geometry={nodes.Cube035.geometry} material={nodes.Cube035.material} />
        </group>
        <group position={[0, 0, -0.05]} scale={[0.3, 0.3, 0.3]}>
          <mesh geometry={nodes.Cube027_1.geometry} material={materials['Material.001']} />
          <mesh geometry={nodes.Cube027_2.geometry} material={nodes.Cube027_2.material} />
          <mesh geometry={nodes.Cube027_3.geometry} material={nodes.Cube027_3.material} />
        </group>
        <group name="Cube030" scale={0.3}>
          <mesh geometry={nodes.Cube031.geometry} material={nodes.Cube031.material} />
          <mesh geometry={nodes.Cube031_1.geometry} material={nodes.Cube031_1.material} />
          <mesh geometry={nodes.Cube031_2.geometry} material={nodes.Cube031_2.material} />
          <mesh
            geometry={nodes.Cylinder002.geometry}
            material={nodes.Cylinder002.material}
            position={[-0.01, 0.39, -0.01]}
            rotation={[0, 0, -Math.PI / 2]}
            scale={[0, 0.22, 0]}
          />
        </group>
        <mesh
          geometry={nodes.Butter.geometry}
          material={materials.Frezzer}
          position={[0.09, 0.08, 0.2]}
          rotation={[0, -0.02, 0]}
          scale={[0.15, 0.3, 0.3]}
        />
        <group scale={0.3}>
          <mesh geometry={nodes.Cube024.geometry} material={nodes.Cube024.material} />
          <mesh geometry={nodes.Cube024_1.geometry} material={nodes.Cube024_1.material} />
          <mesh geometry={nodes.Cube024_2.geometry} material={nodes.Cube024_2.material} />
          <mesh geometry={nodes.Cube024_3.geometry} material={nodes.Cube024_3.material} />
        </group>
        <mesh
          geometry={nodes.Cube026.geometry}
          material={nodes.Cube026.material}
          position={[-0.03, 0.15, 0.05]}
          scale={[0.51, 0.51, 0.33]}>
          <mesh
            geometry={nodes.Cube028.geometry}
            material={nodes.Cube028.material}
            position={[0, 0.01, -0.08]}
            rotation={[0.91, 0, 0]}
            scale={[0, 0, 0]}
          />
          <mesh
            geometry={nodes.Plane005.geometry}
            material={nodes.Plane005.material}
            position={[0, 0, -0.07]}
            rotation={[0, 0, -Math.PI / 2]}
            scale={[0, 0.01, 0.01]}
          />
          <mesh
            geometry={nodes.Plane007.geometry}
            material={nodes.Plane007.material}
            position={[0, 0, 0.06]}
            rotation={[1.49, 0, 0]}
            scale={[0.01, 0.02, 0]}
          />
          <mesh
            geometry={nodes.Plane008.geometry}
            material={nodes.Plane008.material}
            position={[0, 0, -0.03]}
            scale={[1.01, 1, 1.57]}
          />
          <mesh
            geometry={nodes.Plane009.geometry}
            material={nodes.Plane009.material}
            position={[0, 0, -0.03]}
            scale={[0.59, 0.59, 0.92]}
          />
          <mesh
            geometry={nodes.Plane010.geometry}
            material={nodes.Plane010.material}
            position={[0, 0, -0.05]}
            rotation={[1.49, 0, 0]}
            scale={[0.01, 0.02, 0]}
          />
          <mesh
            geometry={nodes.Plane011.geometry}
            material={nodes.Plane011.material}
            position={[0, 0, 0.04]}
            scale={[0.59, 0.59, 0.92]}
          />
          <mesh
            geometry={nodes.Plane012_1.geometry}
            material={nodes.Plane012_1.material}
            position={[0, 0, 0.02]}
            scale={[0.59, 0.59, 0.92]}
          />
          <mesh
            geometry={nodes.Plane013.geometry}
            material={nodes.Plane013.material}
            position={[0, 0, 0]}
            scale={[0.59, 0.59, 0.92]}
          />
          <mesh
            geometry={nodes.Plane014.geometry}
            material={nodes.Plane014.material}
            position={[0, 0, -0.05]}
            scale={[0.59, 0.59, 0.92]}
          />
          <mesh
            geometry={nodes.Plane016.geometry}
            material={nodes.Plane016.material}
            position={[0, 0, -0.07]}
            scale={[0.59, 0.59, 0.41]}
          />
        </mesh>
        <mesh
          geometry={nodes.Cube027.geometry}
          material={nodes.Cube027.material}
          position={[0.01, 0.15, 0.05]}
          scale={[0.51, 0.51, 0.33]}>
          <mesh
            geometry={nodes.Cube029_1.geometry}
            material={nodes.Cube029_1.material}
            position={[0, 0.01, -0.08]}
            rotation={[0.91, 0, 0]}
            scale={[0, 0, 0]}
          />
          <mesh
            geometry={nodes.Plane006.geometry}
            material={nodes.Plane006.material}
            position={[0, 0, -0.07]}
            rotation={[0, 0, -Math.PI / 2]}
            scale={[0, 0.01, 0.01]}
          />
          <mesh
            geometry={nodes.Plane015.geometry}
            material={nodes.Plane015.material}
            position={[0, 0, 0.06]}
            rotation={[1.49, 0, 0]}
            scale={[0.01, 0.02, 0]}
          />
          <mesh
            geometry={nodes.Plane017.geometry}
            material={nodes.Plane017.material}
            position={[0, 0, -0.03]}
            scale={[1.01, 1, 1.57]}
          />
          <mesh
            geometry={nodes.Plane018.geometry}
            material={nodes.Plane018.material}
            position={[0, 0, -0.05]}
            rotation={[1.49, 0, 0]}
            scale={[0.01, 0.02, 0]}
          />
          <mesh
            geometry={nodes.Plane019.geometry}
            material={nodes.Plane019.material}
            position={[0, 0, 0.04]}
            scale={[0.59, 0.59, 0.92]}
          />
          <mesh
            geometry={nodes.Plane020.geometry}
            material={nodes.Plane020.material}
            position={[0, 0, -0.07]}
            scale={[0.59, 0.59, 0.41]}
          />
          <mesh
            geometry={nodes.Plane021.geometry}
            material={nodes.Plane021.material}
            position={[0, 0, 0.02]}
            scale={[0.59, 0.59, 0.92]}
          />
          <mesh
            geometry={nodes.Plane022.geometry}
            material={nodes.Plane022.material}
            position={[0, 0, 0]}
            scale={[0.59, 0.59, 0.92]}
          />
          <mesh
            geometry={nodes.Plane023.geometry}
            material={nodes.Plane023.material}
            position={[0, 0, -0.03]}
            scale={[0.59, 0.59, 0.92]}
          />
          <mesh
            geometry={nodes.Plane024.geometry}
            material={nodes.Plane024.material}
            position={[0, 0, -0.05]}
            scale={[0.59, 0.59, 0.92]}
          />
        </mesh>
        <mesh
          geometry={nodes.Plane003.geometry}
          material={nodes.Plane003.material}
          position={[0, -0.07, 0.06]}
          scale={[0.09, 0.07, 0.05]}
        />
      </group> */}
      </a.group>
      <Html fullscreen style={{ pointerEvents: "none" }}>
        <animated.div
          style={{
            zIndex: 100,
            position: "absolute",
            width: "100%",
            height: "14%",
            left: 0,
            bottom: 0,
            pointerEvents: "none",
            textAlign: "center",
          }}
        >
          <p style={{ color: "#FFE7BA", fontFamily: "futura" }}>Tap to open</p>
        </animated.div>
      </Html>
    </>
  );
}

setTimeout(() => useGLTF.preload("./Models/Refrigerator_10.glb"), 30000);
// setTimeout(()=>useGLTF.preload('./Models/Lock_10.glb'),30000)
