import React,{useEffect, useState } from 'react'
import {RGBELoader} from 'three/examples/jsm/loaders/RGBELoader';
import * as THREE from 'three'
import { PMREMGenerator, ReinhardToneMapping, RGBEEncoding, sRGBEncoding } from 'three';
import { useThree } from '@react-three/fiber';

let toneMappingOptions = {

    None: THREE.NoToneMapping,
    Linear: THREE.LinearToneMapping,
    Reinhard: THREE.ReinhardToneMapping,
    Cineon: THREE.CineonToneMapping,
    ACESFilmic: THREE.ACESFilmicToneMapping

};
// None,Linear,Reinhard,Cineon,ACESFilmic
let tonemappingParams = {
    exposure: 1,
    toneMapping: 'None'
};

const getHDRI = (name) => {
    switch(name){
        case 'ballot':
            return "HDRI_Ballot.hdr"
        case 'refrigerator':
            return "HDRI_Fridge_RoundLock.hdr"
        case 'squarelock':
            return 'HDRI_SquareLock.hdr'
        case 'typewriter':
            return 'HDRI_Type.hdr'
        default:
            return 'photo_studio_01_256_1A.hdr'
    }
}
export default function Skybox(props) {

    const {gl,scene} = useThree();

    useEffect(() =>{

        // gl.toneMapping = toneMappingOptions[ tonemappingParams.toneMapping ];
        // gl.toneMappingExposure = tonemappingParams.exposure;
        gl.outputEncoding = THREE.sRGBEncoding;
    
        let pmremGenerator = new PMREMGenerator( gl );
        pmremGenerator.compileEquirectangularShader();
        new RGBELoader()
        .setDataType( THREE.UnsignedByteType )
        .setPath( './textures/' )
        .load( getHDRI(props.name) , function ( texture ) {
            const envMap = pmremGenerator.fromEquirectangular( texture ).texture;
            if(props.name==='typewriter'){ 
                // envMap.encoding = sRGBEncoding
                gl.toneMapping = toneMappingOptions.ACESFilmic
                gl.toneMappingExposure = 1
            }else if(props.name==='ballot'){
                // envMap.encoding = sRGBEncoding
                gl.toneMapping = toneMappingOptions.ACESFilmic
                gl.toneMappingExposure = 1
            }
            scene.environment = envMap;
            texture.dispose();
            pmremGenerator.dispose();
        });
    },[gl])

    return null;
    
}