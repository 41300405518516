/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useEffect } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";
import { useSpring } from "@react-spring/core";
import { animated as a } from "@react-spring/three";
import { animated } from "@react-spring/web";
import { Html } from "@react-three/drei";
import * as THREE from "three";
import { useSound } from "../../stores";
export default function Model(props) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF("./Models/Lock_10.glb");
  const { actions } = useAnimations(animations, group);
  const sound = useSound();
  const handleClick = () => {
    sound.play("hud");
    actions.Animation.reset();
    actions.Animation.play();
  };
  useEffect(() => {
    actions.Animation.paused = false;
    actions.Animation.loop = THREE.LoopOnce;
    // actions.Animation.play()
    // actions.Animation.
    // actions.Animation.clampWhenFinished = true
    // actions.Animation.paused=true
    props.finishedLoading();
  }, []);
  const spring = useSpring({ state: 30.0, from: { state: 0 } });
  return (
    <>
      <a.group
        onPointerDown={handleClick}
        rotation={[0, Math.PI + Math.PI / 6, 0]}
        position={[0, 0.4, 0]}
        scale={spring.state.to((x) => x)}
        ref={group}
        {...props}
        dispose={null}
      >
        <group name="Scene">
          <group name="Empty" scale={0.6686}>
            <group
              name="BezierCurve"
              position={[0.00656, 0.02744, 0]}
              rotation={[0, 0, 1.57079]}
              scale={0.03247}
            >
              <mesh
                name="BezierCurve_1"
                geometry={nodes.BezierCurve_1.geometry}
                material={nodes.BezierCurve_1.material}
              />
              <mesh
                name="BezierCurve_2"
                geometry={nodes.BezierCurve_2.geometry}
                material={materials.Key_Lock}
              />
            </group>
            <mesh
              name="Key"
              geometry={nodes.Key.geometry}
              material={materials.Key}
              position={[0.07776, -0.03606, -0.00704]}
              rotation={[-Math.PI / 2, 0, 1.53831]}
              scale={[1.93495, 1.93495, 1.93495]}
            />
            <mesh
              name="Cylinder003"
              geometry={nodes.Cylinder003.geometry}
              material={materials.Material}
              position={[0.00264, -0.05284, 0.02665]}
              scale={1.49565}
            />
            <mesh
              name="BezierCurve004"
              geometry={nodes.BezierCurve004.geometry}
              material={nodes.BezierCurve004.material}
              position={[0.00656, 0.0339, 0]}
              rotation={[0, 0, 1.57079]}
              scale={0.03207}
            />
            <mesh
              name="Cube004"
              geometry={nodes.Cube004.geometry}
              material={nodes.Cube004.material}
              position={[-0.01872, -0.00634, 0.00322]}
              scale={[0.00581, 0.00362, 0.00633]}
            />
          </group>
        </group>
      </a.group>
      <Html fullscreen style={{ pointerEvents: "none" }}>
        <animated.div
          onTouchStart={handleClick}
          onClick={handleClick}
          style={{
            zIndex: 120,
            position: "absolute",
            width: "100%",
            height: "17%",
            left: 0,
            bottom: 0,
            textAlign: "center",
          }}
        >
          <p style={{ color: "#FFE7BA", fontFamily: "futura" }}>Tap to open</p>
        </animated.div>
      </Html>
    </>
  );
}

// useGLTF.preload('./Models/Lock_10.glb')
setTimeout(() => useGLTF.preload("./Models/Lock_10.glb"), 30000);
