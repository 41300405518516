import React, { useEffect, useRef, useState } from 'react'
import Lottie from 'react-lottie'
import { useAudio, useSound } from '../../../stores'
import animationData from '../../lotties/MuteUnmute.json'
import { StyleSheet,css } from 'aphrodite'
export const MuteUnmute = () => {
    const lottie = useRef()
    const [muted,setMuted] = useState(false)
    const audio = useAudio()
    const soundState = useSound()
    useEffect(()=>{
        // lottie.current.anim.goToAndStop(45)
    },[])
    useEffect(()=> {
        if(!muted){
            audio.setMuted()
            lottie.current.anim.direction = 1
            lottie.current.anim.playSegments([52,90])
        }else{
            audio.setMuted()
            lottie.current.anim.direction = -1
            lottie.current.anim.playSegments([0,45])
        }
    },[muted])
    const defaultOptions = {
        animationData:animationData,
        autoplay:false,
        
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        },
        loop:false
    }
    
    return (
        <div onPointerDown={()=>soundState.play('hud')} className={css(styles.Mute)} onClick={()=>setMuted(state => !state)}>
            <Lottie 
                speed={2}
                ref={lottie}
                options={defaultOptions}
                width='4vw'
                // eventListeners={[{
                //     eventName:'click',callback:() => setMuted(state => !state),
                //     eventName:'touchstart',callback:() => setMuted(state => !state)
                // }]}
            />
        </div>
    )
}

const styles = StyleSheet.create({
    Mute:{
        position:'absolute',
        bottom:'2vh',
        right:'11vw',
        zIndex:110,
        "@media (max-width: 900px)": {
            right:'13vw',

            transform:'scale(1.5)',
          //   top:'34vh'
          },
    }
})