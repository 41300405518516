import React from 'react'
import { animated } from '@react-spring/web'

export const Bar = (props) => {
    return (
        
        <svg style={{transform:'scaleX(3)'}} width="1152" height="13%" viewBox="0 0 1152 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M-126 6.19727L1152.45 6.19727" stroke="#FFE7BA" stroke-width="12"/>
        </svg>
        

        
    )
}
export const PercentageBar = () => {
    return (
        <svg width="1952%" height="11%" viewBox="0 0 1752 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M-187.668 5.802L1246.34 5.80192" stroke="#FFE7BA" stroke-width="10"/>
        </svg>
    )
}
