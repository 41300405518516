import React from 'react'
import { colorThemes } from '../../../constants'
import { useAudio } from '../../../stores'
import { config, useSpring } from '@react-spring/core'
import { animated as a } from '@react-spring/web'
const inner = "#36AA9D"

export const CloseIcon = (props) => {
    return (
        
        <svg className={props.className} width="100%"  viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.0281982 17.8076L5.54773 9.24121L0.286011 0.862305H2.66492L6.76648 7.28418L10.7626 0.862305H13.3055L7.98523 9.20605L13.4813 17.8076H10.9266L6.74304 11.1514L2.51257 17.8076H0.0281982Z" fill="#FFE7BA"/>
</svg>

        

    )
}
export const CloserLook = ({selected, eraid}) => {
    const pulse = useSpring( {from:{transform:'scale(1)'},reset:true ,config:{tension:10,mass:10,velocity:0.005,clamp:true}, transform:'scale(1.01)',loop:{reverse:true} } )
    return (
        <a.div >

        <a.svg   width="100%"  viewBox="0 0 45 44" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="22.5314" cy="22" r="22" fill={colorThemes[eraid]}/>
<path d="M25.3696 21.4087C23.6373 21.1274 23.1131 19.7212 22.9725 19.1331C22.8958 18.8263 22.6145 18.6281 22.3077 18.6473C20.5371 18.7623 19.1564 20.2389 19.1564 22.0032C19.1564 23.8633 20.6713 25.3783 22.5314 25.3783C24.366 25.3783 25.8809 23.8889 25.9065 22.0479C25.9065 21.7347 25.6828 21.4598 25.3696 21.4087Z" fill="#FFE7BA"/>
<path d="M38.3584 21.594C38.0708 21.2552 31.2056 13.2905 22.5314 13.2905C13.8636 13.2905 6.99198 21.2552 6.70432 21.594C6.49976 21.8305 6.49976 22.1821 6.70432 22.4186C6.99198 22.7574 13.8636 30.7092 22.5314 30.7092C31.2056 30.7092 38.0708 22.7574 38.3584 22.4186C38.563 22.1821 38.563 21.8305 38.3584 21.594ZM22.5314 28.4848C18.9582 28.4848 16.0561 25.5763 16.0561 22.0095C16.0561 18.4299 18.9582 15.5214 22.5314 15.5214C26.1046 15.5214 29.0067 18.4299 29.0067 22.0095C29.0067 25.5763 26.1046 28.4848 22.5314 28.4848Z" fill="#FFE7BA"/>
</a.svg>
        </a.div>

    )
}
export const Animate = ({eraid}) => {
    const pulse = useSpring( {from:{transform:'scale(1)'},to:[{transform:'scale(1.35)'},{transform:'scale(1)'}],loop:{reverse:true} } )
    // const pulse = useSpring( {from:{width:'6vh',transform:'translate(-50%)'},to:[{width:'9vh',transform:'translate(-50%)'},{width:'6vh',transform:'translate(-50%)'}],loop:{reverse:true} } )
    
    // 
    return (
    <a.svg style={eraid===-1?pulse:{}} width="100%"  viewBox="0 0 45 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="22.5315" cy="22" r="22" fill={(eraid==-1?'#FFE7BA':colorThemes[eraid])}/>
    <path d="M23.7224 15.5963V20.8592C23.7224 20.8592 28.4388 22.3763 29.3164 22.5936C30.1941 22.8109 30.5776 22.8517 31.0326 23.3075C31.4875 23.7634 31.743 24.3811 31.743 25.0251V26.9054C31.7449 27.4148 30.9524 28.4526 30.6788 29.59C30.4053 30.7273 30.3223 32.5524 30.3223 32.5524V35.3859C30.3223 35.5833 30.2439 35.7727 30.1043 35.9123C29.9647 36.0518 29.7754 36.1303 29.5779 36.1303H21.2909C21.0935 36.1303 20.9042 36.0518 20.7646 35.9123C20.625 35.7727 20.5466 35.5833 20.5466 35.3859V32.6914L16.7919 29.59L16.7026 29.5106C16.251 29.0573 15.9976 28.4434 15.9979 27.8035V23.7493C15.9992 23.1048 16.2558 22.4871 16.7116 22.0314C17.1673 21.5757 17.785 21.3191 18.4295 21.3178C18.7536 21.3178 19.0744 21.3836 19.3723 21.5113V15.5963C19.3723 14.9514 19.6285 14.3329 20.0845 13.8769C20.5405 13.4209 20.9185 13.229 21.5634 13.229C22.2083 13.229 22.5543 13.4209 23.0103 13.8769C23.4663 14.3329 23.7224 14.9514 23.7224 15.5963Z" fill={eraid===-1?colorThemes[2]:"#FFE7BA"}/>
    <path d="M18.8817 21.0474V27.4086" stroke={(eraid==-1?'#FFE7BA':colorThemes[eraid])} stroke-linecap="round"/>
    <path d="M22.0452 10.6537V8.61422C22.0452 8.41681 21.9668 8.22748 21.8272 8.08789C21.6876 7.94829 21.4983 7.86987 21.3009 7.86987C21.1035 7.86987 20.9141 7.94829 20.7745 8.08789C20.6349 8.22748 20.5565 8.41681 20.5565 8.61422V10.6537C20.5565 10.8511 20.6349 11.0405 20.7745 11.1801C20.9141 11.3196 21.1035 11.3981 21.3009 11.3981C21.4983 11.3981 21.6876 11.3196 21.8272 11.1801C21.9668 11.0405 22.0452 10.8511 22.0452 10.6537Z" fill={eraid===-1?colorThemes[2]:"#FFE7BA"}/>
    <path d="M25.9552 10.1229L24.5111 11.5669C24.4072 11.671 24.3364 11.8036 24.3077 11.9479C24.2791 12.0922 24.2938 12.2418 24.3501 12.3777C24.4064 12.5136 24.5017 12.6298 24.624 12.7116C24.7462 12.7934 24.89 12.8371 25.0371 12.8373C25.1349 12.8375 25.2317 12.8183 25.322 12.7808C25.4123 12.7433 25.4943 12.6883 25.5632 12.6189L27.0072 11.1749C27.0803 11.1068 27.139 11.0246 27.1797 10.9333C27.2203 10.842 27.2422 10.7434 27.244 10.6435C27.2457 10.5435 27.2274 10.4442 27.1899 10.3516C27.1525 10.2589 27.0968 10.1747 27.0261 10.104C26.9554 10.0333 26.8712 9.9776 26.7785 9.94016C26.6858 9.90272 26.5866 9.88434 26.4866 9.8861C26.3867 9.88787 26.2881 9.90974 26.1968 9.95042C26.1055 9.99111 26.0233 10.0498 25.9552 10.1229Z" fill={eraid===-1?colorThemes[2]:"#FFE7BA"}/>
    <path d="M25.6377 15.6953C25.6377 15.8927 25.7161 16.082 25.8557 16.2216C25.9953 16.3612 26.1846 16.4396 26.382 16.4396H28.4215C28.619 16.4396 28.8083 16.3612 28.9479 16.2216C29.0875 16.082 29.1659 15.8927 29.1659 15.6953C29.1659 15.4979 29.0875 15.3085 28.9479 15.1689C28.8083 15.0294 28.619 14.9509 28.4215 14.9509H26.382C26.1846 14.9509 25.9953 15.0294 25.8557 15.1689C25.7161 15.3085 25.6377 15.4979 25.6377 15.6953Z" fill={eraid===-1?colorThemes[2]:"#FFE7BA"}/>
    <path d="M16.8446 15.6953C16.8446 15.4979 16.7662 15.3085 16.6266 15.1689C16.487 15.0294 16.2977 14.9509 16.1003 14.9509H14.0658C13.8683 14.9509 13.679 15.0294 13.5394 15.1689C13.3998 15.3085 13.3214 15.4979 13.3214 15.6953C13.3214 15.8927 13.3998 16.082 13.5394 16.2216C13.679 16.3612 13.8683 16.4396 14.0658 16.4396H16.1102C16.3059 16.437 16.4927 16.3574 16.6301 16.2181C16.7676 16.0788 16.8447 15.891 16.8446 15.6953Z" fill={eraid===-1?colorThemes[2]:"#FFE7BA"}/>
    <path d="M17.4942 12.8373C17.6413 12.8371 17.7851 12.7934 17.9074 12.7116C18.0297 12.6298 18.125 12.5136 18.1813 12.3777C18.2376 12.2418 18.2523 12.0922 18.2236 11.9479C18.195 11.8036 18.1242 11.671 18.0202 11.5669L16.5812 10.1229C16.513 10.0498 16.4308 9.99111 16.3395 9.95042C16.2482 9.90974 16.1497 9.88787 16.0497 9.8861C15.9498 9.88434 15.8505 9.90272 15.7578 9.94016C15.6651 9.9776 15.5809 10.0333 15.5103 10.104C15.4396 10.1747 15.3838 10.2589 15.3464 10.3516C15.309 10.4442 15.2906 10.5435 15.2924 10.6435C15.2941 10.7434 15.316 10.842 15.3567 10.9333C15.3974 11.0246 15.456 11.1068 15.5291 11.1749L16.9682 12.6189C17.1086 12.7572 17.2972 12.8355 17.4942 12.8373Z" fill={eraid===-1?colorThemes[2]:"#FFE7BA"}/>
</a.svg>

)}
export const VideoIcon = ({eraid}) => (
    <svg width="100%"  viewBox="0 0 45 44" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="22.1333" cy="22" r="22" fill={colorThemes[eraid]}/>
<path d="M10.1591 31.7275H35.5639V12.2725H10.1591V31.7275ZM15.0571 30.2032H12.0365V28.026H15.0571V30.1981V30.2032ZM24.3807 30.2032H21.3524V28.026H24.3858V30.2083L24.3807 30.2032ZM30.676 13.6164H33.7017V15.7987H30.676V13.6164ZM30.676 28.0336H33.6967V30.2007H30.6709V28.0311L30.676 28.0336ZM25.938 13.6164H28.9587V15.7936H25.938V13.6164ZM25.938 28.026H28.9587V30.2032H25.9355V28.026H25.938ZM21.3372 13.6164H24.3858V15.7936H21.3372V13.6164ZM20.8875 18.9514L24.838 21.2378C25.5646 21.657 25.5646 22.3429 24.838 22.7621L20.8875 25.0486C20.161 25.4677 19.5665 25.1248 19.5665 24.2864V19.7135C19.5589 18.8803 20.161 18.5373 20.8875 18.9514ZM16.6144 13.6164H19.6224V15.7961H16.6144V13.6164ZM16.6144 28.026H19.6224V30.2058H16.6144V28.0235V28.026ZM12.0263 13.6164H15.0571V15.7987H12.0263V13.6164Z" fill="#FFE7BA"/>
</svg>

)
export const AudioIcon = ({eraid, title}) => {
    const audio = useAudio()
    return (
    <svg width="100%" viewBox="0 0 45 44" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="22.7488" cy="22" r="22" fill={audio.title!==title?colorThemes[eraid]:"#FFE7BA"}/>
<path d="M15.9611 28.2448L25.878 32.7596V11.2405L15.9611 16.6943V28.2448Z" fill={audio.title!==title?"#FFE7BA":colorThemes[eraid]}/>
<path d="M7.69604 18.3443V26.5948C7.69604 27.5062 8.43472 28.245 9.34799 28.245H14.3073V16.6943H9.34793C8.43466 16.6943 7.69604 17.433 7.69604 18.3443Z" fill={audio.title!==title?"#FFE7BA":colorThemes[eraid]}/>
<path d="M30.9476 15.7791L29.0089 17.6257C29.9461 18.618 30.4586 19.9061 30.4586 21.2769C30.4586 22.644 29.946 23.9303 29.0089 24.9225L30.9476 26.7729C32.3055 25.3396 33.1396 23.4047 33.1396 21.2769C33.1397 19.1472 32.3054 17.2123 30.9476 15.7791Z" fill={audio.title!==title?"#FFE7BA":colorThemes[eraid]}/>
<path d="M34.3158 12.5618L32.3783 14.4122C33.2116 15.285 33.8693 16.2883 34.3361 17.3927C34.8589 18.622 35.1207 19.9303 35.1207 21.2772C35.1207 22.626 34.8589 23.9288 34.3361 25.158C33.8694 26.2661 33.2116 27.2639 32.3783 28.1403L34.3158 29.987C36.4767 27.7177 37.8015 24.649 37.8015 21.2772C37.8015 17.8998 36.4767 14.8329 34.3158 12.5618Z" fill={audio.title!==title?"#FFE7BA":colorThemes[eraid]}/>
</svg>

)}
export const MuteIcon = ({muted}) => (
    <svg width="100%"  viewBox="0 0 47 37" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M31.0693 9.95605C31.0693 9.95605 34.9186 12.8556 35.0227 18.1368C35.1267 23.418 31.0693 27.146 31.0693 27.146" stroke={muted?'grey':"#FFE7BA"} stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M39.126 2.2666C39.126 2.2666 44.849 7.77362 45.0037 17.8043C45.1584 27.835 39.126 34.9154 39.126 34.9154" stroke={muted?'grey':"#FFE7BA"} stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10.5011 12.0769L21.8159 3.21875V34.4752L10.5011 25.7436H1.47461V12.0769H10.5011Z" stroke={muted?'grey':"#FFE7BA"} stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
)

export const ExternalIcon = (props) => (
    <svg width="100%"  viewBox="0 0 41 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="path-1-outside-1" maskUnits="userSpaceOnUse" x="1.0813" y="1.44971" width="39" height="44" fill="black">
        <rect fill="white" x="1.0813" y="1.44971" width="39" height="44"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M20.3476 37.7216C29.3312 37.7216 36.6138 30.2734 36.6138 21.0857C36.6138 11.8979 29.3312 4.44971 20.3476 4.44971C11.364 4.44971 4.0813 11.8979 4.0813 21.0857C4.0813 26.3934 6.51176 31.1206 10.2965 34.1666L9.99635 39.5701L14.5586 36.6372C16.3571 37.3379 18.3085 37.7216 20.3476 37.7216Z"/>
        </mask>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M20.3476 37.7216C29.3312 37.7216 36.6138 30.2734 36.6138 21.0857C36.6138 11.8979 29.3312 4.44971 20.3476 4.44971C11.364 4.44971 4.0813 11.8979 4.0813 21.0857C4.0813 26.3934 6.51176 31.1206 10.2965 34.1666L9.99635 39.5701L14.5586 36.6372C16.3571 37.3379 18.3085 37.7216 20.3476 37.7216Z" fill={colorThemes[props.eraid]}/>
        <path d="M10.2965 34.1666L13.2919 34.333L13.3774 32.7952L12.1775 31.8295L10.2965 34.1666ZM9.99635 39.5701L7.00097 39.4037L6.67497 45.2717L11.6186 42.0936L9.99635 39.5701ZM14.5586 36.6372L15.6476 33.8419L14.2226 33.2867L12.9363 34.1137L14.5586 36.6372ZM33.6138 21.0857C33.6138 28.6805 27.6111 34.7216 20.3476 34.7216V40.7216C31.0512 40.7216 39.6138 31.8664 39.6138 21.0857H33.6138ZM20.3476 7.44971C27.6111 7.44971 33.6138 13.4908 33.6138 21.0857H39.6138C39.6138 10.3049 31.0512 1.44971 20.3476 1.44971V7.44971ZM7.0813 21.0857C7.0813 13.4908 13.084 7.44971 20.3476 7.44971V1.44971C9.6439 1.44971 1.0813 10.3049 1.0813 21.0857H7.0813ZM12.1775 31.8295C9.08147 29.3378 7.0813 25.4613 7.0813 21.0857H1.0813C1.0813 27.3255 3.94204 32.9033 8.41561 36.5037L12.1775 31.8295ZM12.9917 39.7365L13.2919 34.333L7.30116 34.0002L7.00097 39.4037L12.9917 39.7365ZM12.9363 34.1137L8.37408 37.0465L11.6186 42.0936L16.1808 39.1608L12.9363 34.1137ZM20.3476 34.7216C18.6879 34.7216 17.1054 34.4098 15.6476 33.8419L13.4695 39.4326C15.6088 40.266 17.9291 40.7216 20.3476 40.7216V34.7216Z" fill="#FFE7BA" mask="url(#path-1-outside-1)"/>
        <path d="M27.3073 11.7368H23.9934V13.7252H27.3073C27.3952 13.7252 27.4795 13.7601 27.5416 13.8222C27.6038 13.8844 27.6387 13.9687 27.6387 14.0565V17.3705H29.627V14.0565C29.627 13.4413 29.3826 12.8513 28.9476 12.4163C28.5126 11.9812 27.9225 11.7368 27.3073 11.7368Z" fill="#FFE7BA"/>
        <path d="M16.7018 11.7368H13.3878C12.7726 11.7368 12.1826 11.9812 11.7475 12.4163C11.3125 12.8513 11.0681 13.4413 11.0681 14.0565V17.3705H13.0565V14.0565C13.0565 13.9687 13.0914 13.8844 13.1535 13.8222C13.2157 13.7601 13.3 13.7252 13.3878 13.7252H16.7018V11.7368Z" fill="#FFE7BA"/>
        <path d="M13.3878 30.2945H16.7018V28.3062H13.3878C13.3 28.3062 13.2157 28.2713 13.1535 28.2091C13.0914 28.147 13.0565 28.0627 13.0565 27.9748V24.6609H11.0681V27.9748C11.0681 28.59 11.3125 29.1801 11.7475 29.6151C12.1826 30.0501 12.7726 30.2945 13.3878 30.2945Z" fill="#FFE7BA"/>
        <path d="M27.6387 27.9748C27.6387 28.0627 27.6038 28.147 27.5416 28.2091C27.4795 28.2713 27.3952 28.3062 27.3073 28.3062H23.9934V30.2945H27.3073C27.9225 30.2945 28.5126 30.0501 28.9476 29.6151C29.3826 29.1801 29.627 28.59 29.627 27.9748V24.6609H27.6387V27.9748Z" fill="#FFE7BA"/>
        <path d="M21.3426 20.0215H19.3542V22.0098H21.3426V20.0215Z" fill="#FFE7BA"/>
        <path d="M23.9931 17.3704H22.0048V19.3587H23.9931V17.3704Z" fill="#FFE7BA"/>
        <path d="M18.6906 17.3704H16.7023V19.3587H18.6906V17.3704Z" fill="#FFE7BA"/>
        <path d="M23.9931 22.6726H22.0048V24.6609H23.9931V22.6726Z" fill="#FFE7BA"/>
        <path d="M18.6906 22.6726H16.7023V24.6609H18.6906V22.6726Z" fill="#FFE7BA"/>
    </svg>

)
export const TidBits = ({selected,eraid}) => (
    <svg width="100%"  viewBox="0 0 41 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="path-1-outside-1" maskUnits="userSpaceOnUse" x="1.32715" y="1.28125" width="39" height="44" fill="black">
<rect fill={selected?colorThemes[eraid]:"white"} x="1.32715" y="1.28125" width="39" height="44"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M20.5671 37.4992C29.5361 37.4992 36.807 30.0631 36.807 20.8902C36.807 11.7173 29.5361 4.28125 20.5671 4.28125C11.598 4.28125 4.32715 11.7173 4.32715 20.8902C4.32715 26.1894 6.75366 30.9089 10.5323 33.95L10.2326 39.3447L14.7874 36.4166C16.583 37.1161 18.5313 37.4992 20.5671 37.4992Z"/>
</mask>
<path fill-rule="evenodd" clip-rule="evenodd" d="M20.5671 37.4992C29.5361 37.4992 36.807 30.0631 36.807 20.8902C36.807 11.7173 29.5361 4.28125 20.5671 4.28125C11.598 4.28125 4.32715 11.7173 4.32715 20.8902C4.32715 26.1894 6.75366 30.9089 10.5323 33.95L10.2326 39.3447L14.7874 36.4166C16.583 37.1161 18.5313 37.4992 20.5671 37.4992Z" fill={selected?"#FFE7BA":colorThemes[eraid]}/>
<path d="M10.5323 33.95L13.5277 34.1164L13.6131 32.5785L12.4132 31.6129L10.5323 33.95ZM10.2326 39.3447L7.23721 39.1783L6.91121 45.0463L11.8549 41.8682L10.2326 39.3447ZM14.7874 36.4166L15.8764 33.6212L14.4515 33.0661L13.1651 33.8931L14.7874 36.4166ZM33.807 20.8902C33.807 28.4702 27.8161 34.4992 20.5671 34.4992V40.4992C31.2562 40.4992 39.807 31.6561 39.807 20.8902H33.807ZM20.5671 7.28125C27.8161 7.28125 33.807 13.3103 33.807 20.8902H39.807C39.807 10.1244 31.2562 1.28125 20.5671 1.28125V7.28125ZM7.32715 20.8902C7.32715 13.3103 13.3181 7.28125 20.5671 7.28125V1.28125C9.87795 1.28125 1.32715 10.1244 1.32715 20.8902H7.32715ZM12.4132 31.6129C9.32338 29.1261 7.32715 25.2572 7.32715 20.8902H1.32715C1.32715 27.1215 4.18394 32.6916 8.65136 36.2871L12.4132 31.6129ZM13.228 39.5111L13.5277 34.1164L7.53691 33.7836L7.23721 39.1783L13.228 39.5111ZM13.1651 33.8931L8.61032 36.8211L11.8549 41.8682L16.4096 38.9401L13.1651 33.8931ZM20.5671 34.4992C18.9107 34.4992 17.3313 34.188 15.8764 33.6212L13.6983 39.2119C15.8347 40.0442 18.1519 40.4992 20.5671 40.4992V34.4992Z" fill={selected?colorThemes[eraid]:"#FFE7BA"} mask="url(#path-1-outside-1)"/>
<path d="M19.2467 28.1204V17.2084H22.4807V28.1204H19.2467ZM20.8527 15.6024C20.3101 15.6024 19.8627 15.4484 19.5107 15.1404C19.1587 14.8177 18.9827 14.407 18.9827 13.9084C18.9827 13.4097 19.1587 13.0064 19.5107 12.6984C19.8627 12.3904 20.3101 12.2364 20.8527 12.2364C21.4101 12.2364 21.8574 12.3904 22.1947 12.6984C22.5467 13.0064 22.7227 13.4097 22.7227 13.9084C22.7227 14.407 22.5467 14.8177 22.1947 15.1404C21.8574 15.4484 21.4101 15.6024 20.8527 15.6024Z" fill={selected?colorThemes[eraid]:"#FFE7BA"}/>
</svg>

)
export const GuideIcon = () => (
    <svg width="100%"  viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.4021 22.3743H16.4305V19.4341H13.4021V22.3743ZM17.7974 7.7697C16.9124 7.32453 15.8836 7.10156 14.711 7.10156C13.3702 7.10156 12.2433 7.45854 11.3315 8.17058C10.2511 9.02355 9.7113 10.2832 9.7113 11.9506H12.6404V11.9314C12.6404 11.2952 12.7776 10.7734 13.051 10.3664C13.4285 9.81915 14.0408 9.54535 14.8871 9.54535C15.408 9.54535 15.8513 9.67898 16.2154 9.94585C16.6709 10.3155 16.8994 10.8751 16.8994 11.626C16.8994 12.097 16.7816 12.5167 16.5475 12.886C16.3521 13.2168 16.0396 13.5411 15.6094 13.8595C14.698 14.4703 14.1054 15.0749 13.8323 15.6733C13.5979 16.1697 13.4801 16.9588 13.4801 18.0401H16.2351C16.2351 17.3273 16.332 16.7932 16.5282 16.4366C16.6843 16.1439 17.0101 15.8324 17.505 15.5012C18.3647 14.8773 18.9763 14.2982 19.3411 13.7636C19.7836 13.1275 20.0054 12.3827 20.0054 11.5297C20.0054 9.76216 19.2698 8.5083 17.7974 7.7697Z" fill="#FFE7BA"/>
<ellipse cx="15" cy="15.0001" rx="14" ry="13.5758" stroke="#FFE7BA" stroke-width="2"/>
</svg>

)