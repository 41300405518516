import React, { useState } from 'react'
import { useSpring } from '@react-spring/core'
import { animated as a } from '@react-spring/web'
import { StyleSheet, css } from 'aphrodite'
import { colorThemes, springConfigs } from '../../constants'
import { useSound } from '../../stores'
export const AnimatedButton = ({nobg, top,left,text,fade,setShowOverview,clickEvent,eraid,value, intro}) => {
    const [clicked,setClicked] = useState(false)
    let buttonColor= colorThemes[eraid] || '#36AA9D'
    if(intro)buttonColor = '#282560'
    const soundState = useSound()
    const buttonAnimation = useSpring( {
        transform:clicked?'translate(-50%)':'translate(-50%)',
        // width:clicked?'0%':'100%',
        backgroundColor:nobg?'rgba(0,0,0,0)':(!clicked && !value)?buttonColor:(intro?'#F7941D':'#FFE7BA'),
        textColor:(!clicked && !value)?(intro?'#F7941D':'#FFE7BA'):buttonColor,
        config:springConfigs.precision,
        opacity:clicked?1:0,
        onRest:()=>setClicked(false)
    } )
    const handleClick = () => {
        setClicked(true)
        setShowOverview && setShowOverview(true)
        clickEvent && clickEvent()
        soundState.play('hud')
        
    }
    return (
        // <>
        //      {/* <animated.div style={{left:left,top:top,cursor:'pointer'}} className={css(styles.Button)}> */}
        //         <animated.div onClick={handleClick} style={{zIndex:200,transform:buttonAnimation.transform,position:'absolute',top:top,left:left,opacity:fade!==undefined?fade.opacity:1}}>
        //             <h2 className={css(styles.Heading)}>{text}</h2>
        //             <animated.div style={{width:buttonAnimation.width}} className={css(styles.Line)}></animated.div>
        //         </animated.div>
        //     {/* </animated.div> */}
        // </>
        <>
        <a.div style={{left:left,top:top,cursor:'pointer'}} className={css(styles.Button)}>
        <a.div onClick={handleClick} style={{zIndex:200,transform:buttonAnimation.transform,position:'absolute',top:top,left:left,opacity:fade!==undefined?fade.opacity:1}}>
            <a.button 
                className={css(styles.Heading)}
                style={{backgroundColor:buttonAnimation.backgroundColor, border:!intro?'0.3vh solid #FFE7BA':'0.3vh solid #F7941D'}} 
            >
                <a.span style={{color:buttonAnimation.textColor,letterSpacing:'0.01em'}}>{text}</a.span>
            </a.button>
        </a.div>
        </a.div>
        </>
    )
}

const styles = StyleSheet.create({
    Button:{
        cursor:'pointer',
        position:'absolute',
        fontFamily:'Source Sans Pro',
        color:'#FFE7BA',
        zIndex:1000,
        // width:'100%',
        // height:'10%',
        // textAlign:'center',
        // top:'50%',
        // transform:'translateY(-60%)'

    },
    Line:{
        // position:'absolute',
        // position:'relative',
        // width:'10px',
        backgroundColor:'#FFE7BA',
        position:'absolute',
        // width:'100%',
        top:'88%',
        height:'5.5%',
        // left:'-500%',
    // backgroundColor:'#FFE7BA'
    },
    Heading:{
        cursor:'pointer',
        margin:0,
        letterSpacing:'5px',
        
    whiteSpace:'nowrap',
        padding:'0.6vw 1.3vw 0.6vw 1.3vw',
        borderRadius:'5px',
        fontSize:'1.2vw',
        letterSpacing:'-0.9px',
        fontWeight:367,
        color:'#FFE7BA',
        marginBottom:'2px',
        minWidth:'12vw',
        "@media (max-width:1000px)":{
            fontSize:"1.5vw"
        }
    },
})

