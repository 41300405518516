import React, {useEffect} from 'react'
import { css, StyleSheet } from 'aphrodite'
import { animated } from '@react-spring/web'
import { ScrollDown,ScrollUp } from '../assets/svgs/Scroll'
import { useSpring } from '@react-spring/core'
import { springConfigs } from '../../constants'
import { useSound } from '../../stores'


export const Scrollers = ({fadeSpring, scroll, setScroll, scrollDown}) => {
    const soundState = useSound()
    const scrollUpSpring = useSpring({
        marginTop:scrollDown?'20vh':'-140vh',
        config:springConfigs.precision
    })
    const scrollDownSpring = useSpring({
        marginTop:scrollDown?'160vh':'46vh',
        config:springConfigs.precision
    })
    useEffect(()=>{
        
    },[scrollDown])
    return (
        <>
        <animated.div
            style={{
                opacity: fadeSpring.opacity.to((x) => x),
                marginTop: scrollDownSpring.marginTop,
                // marginLeft: scroll.state.to((x) => `${(1 - x) * 8.5}vw`),
                
            }}
            onClick={() => setScroll((state) => !state)}
            onPointerDown={()=>soundState.play('hud')}
            className={css(styles.ScrollDown)}
            >
                <ScrollDown />
            </animated.div>
            <animated.div
                style={{
                    opacity: fadeSpring.opacity.to((x) => x),
                    marginTop: scrollUpSpring.marginTop,

                    // marginTop: scroll.state.to((x) => `${x * -30}vh`),
                    // marginLeft: scroll.state.to((x) => `${x * -8.5}vw`),
                }}
                onPointerDown={()=>soundState.play('hud')}
                onClick={() => setScroll((state) => !state)}
                className={css(styles.ScrollUp)}
            >
                <ScrollUp onClick={() => setScroll((state) => !state)} />
            </animated.div>
        </>
    
    )
}
const styles = StyleSheet.create({
    ScrollUp: {
        position: "absolute",
        // width: "7vw",
        transform:'rotate(30deg)',
        zIndex:'100',
        // top:"20vh",
        left:'-6.5vw',
        height:'15vh',
        '@media (max-width:1350px)':{
            left:'-8vw'
        }

    },
    ScrollDown: {
        zIndex:'100',
        left:'-6.5vw',
        transform:'rotate(30deg)',
        // bottom:'40vh',
        position: "absolute",
        height:'15vh',
        '@media (max-width:1350px)':{
            left:'-8vw'
        }
    },
})