import { css, StyleSheet } from "aphrodite";
import React, { useEffect, useRef, useState, useMemo } from "react";
import { AllMuseumLocations, zindexes } from "../../constants";
import { useLocations, usePage, useSound } from "../../stores";
import { useSpring } from "@react-spring/core";
import { animated } from "@react-spring/web";
import { Bar } from "../assets/svgs/Bar";
import { config } from "@react-spring/core";
import { springConfigs } from "../../constants";
import { ScrollDown, ScrollUp } from "../assets/svgs/Scroll";
import { CircleWithBorder } from "../assets/svgs/CircleWithBorder";
import { colorThemes, bgcolorThemes } from "../../constants";
import { TimelinesOverview } from "./TimelinesOverview";
import { Overview } from "../assets/svgs/EnterStuff";
import { AnimatedButton } from "../assets/AnimatedButton";
import { Scrollers } from "./Scrollers";
import { EraItem } from "./components/EraItem";
import { Arrow } from "../360Viewer/HUD/Hints";


export const TimeLines = () => {
    const soundState = useSound()
    const firstRender = useRef(true)
    const [showHint, setShowHint] = useState(false)
    const size = window.outerWidth>1366?'xl':
        window.outerWidth>930?'lg':
        window.outerWidth>760?'md':window.outerWidth>576?'sm':'xs'
    // 
    const ref = useRef();
    const pageState = usePage();
    const [innerHeight, setHeight] = useState(window.innerHeight)
    // useEffect(()=>{
    //     setHeight
    // },[window.innerHeight])
    const locationsState = useLocations();
    const eras = useMemo(()=>{
        return AllMuseumLocations.map(({ era, eraid, title, text }) => ({
            era,
            eraid,
            title,
            text,
        }));
    },[AllMuseumLocations])
    const handleClick = (era) => {
        setShowHint(false)
        if (era)
            locationsState.setCurrentLocation(
                locationsState.allLocations.find((location) => location.era === era)
            );
        pageState.setPage("introduction");
        // alert('ayy')
    };
    const lastColorIdx = useRef(4);

    const [fade, setFade] = useState(true);
    const [show, setShow] = useState(false);
    const [showOverview, setShowOverview] = useState(false)
    const fadeEnd = () => {
        setShow(false);
    };

    useEffect(() => {
        // pageState.page === "timelines" ? setFade(false) : setFade(true);
        if (pageState.page === "timelines") {
            if(firstRender.current){
                setShowHint(true)
                firstRender.current = false
            }
            setFade(false);
            setShow(true);
        } else if(pageState.page==='introduction' ){
            setFade(true);
        }
    }, [pageState.page]);
    const handleResize = () => {
        setHeight(window.innerHeight)
    }
    useEffect(()=> {
        window.addEventListener('resize',handleResize)
        return ()=> window.removeEventListener('resize',handleResize)
    },[])
    const colorSpring = useSpring({
        from: { backgroundColor: colorThemes[lastColorIdx.current] },
        backgroundColor: colorThemes[locationsState.currentLocation.eraid],
        onRest: () => (lastColorIdx.current = locationsState.currentLocation.eraid),
        config: springConfigs.precision,
    });
    const bgcolorSpring = useSpring({
        from: { backgroundColor: bgcolorThemes[lastColorIdx.current] },
        backgroundColor: bgcolorThemes[locationsState.currentLocation.eraid],
        onRest: () => (lastColorIdx.current = locationsState.currentLocation.eraid),
        config: springConfigs.precision,
    });
    const [scrollDown, setScroll] = useState(false);
    const fadeSpring = useSpring({
        opacity: fade ? 0 : 1,
        onStart: () => fade && pageState.setPage("introduction"),
        onRest: () => fade && fadeEnd(),
        config: springConfigs.precision,
    });
    const fullFadeOut = useSpring({
        opacity: pageState.page === "exitanimation" ? 0 : 1,
        config: springConfigs.precision,
    });
    const scroll = useSpring({ state: scrollDown ? 0 : 1 , config:springConfigs.precision});
    return (
        (pageState.page === "timelines" ||
        pageState.page === "introduction" ||
        pageState.page === "exitanimation") && (
            <div
            style={{
                position: "absolute",
                    width: "100%",
                    height: "100%",
                    overflow: "hidden",
                    zIndex: zindexes.timelinesContainer,
                    pointerEvents: pageState.page === "timelines" ? "auto" : "none",
                }}
                >
                {showHint && <div style={{top:'31%',left:'60%'}} className={css(styles.Box)}>
                    <p className={css(styles.Text)}>Click on an era to know more</p>
                    <div className={css(styles.LeftBot)}><Arrow /></div>
                </div>}
                {/* <animated.div style={fadeSpring}> */}
                    <AnimatedButton nobg eraid={locationsState.currentLocation.eraid} fade={fadeSpring} setShowOverview={setShowOverview} top='90%' left='9%' text='QUICK LOOK' />
                {/* </animated.div> */}
                <TimelinesOverview setShowOverview={setShowOverview} showOverview={showOverview}/>
                {/* <animated.div onClick={()=>setShowOverview(true)} style={{opacity: fadeSpring.opacity.to(x=>x)}} className={css(styles.Overview)}>
                    <Overview />
                </animated.div> */}
                
                <animated.div
                    ref={ref}
                    style={{
                        
                        // top: fadeSpring.opacity.to((x) => `${(1 - x) * - 5}vh`),
                        opacity: fullFadeOut.opacity.to((x) => x),
                    }}
                    className={
                        pageState.page === "timelines"
                            ? css(styles.Container)
                            : css(styles.ContainerClickThrough)
                    }
                >
                    <animated.div
                        style={{
                            backgroundColor: bgcolorSpring.backgroundColor.to((x) => x),
                            opacity: fadeSpring.opacity.to((x) => x + 0.5),
                        }}
                        className={css(styles.Background)}
                    ></animated.div>


                    <animated.div
                        style={{
                            transform: fadeSpring.opacity.to(
                                (x) => `rotate(${(1 - x) * -60}deg)`
                            ),
                            top: fadeSpring.opacity.to((x) => `${(1 - x) * -80}%`),
                            marginBottom:'500px'
                        }}
                    >
                        <animated.div
                            style={{
                                backgroundColor: colorSpring.backgroundColor.to((x) => x),
                                left: fadeSpring.opacity.to((x) => `${((1 - x) * ((innerHeight<410 && window.innerWidth>886)?18.9:innerHeight>400?18.5:15.9))+35}vw`),
                                // left:'53.4vw',
                                marginTop:fadeSpring.opacity.to((x) => `${((1 - x) * -10)}vw`)
                                
                            }}
                            className={css(styles.TimeLines)}
                        >
                            <div className={css(styles.Eras)}>
                                {/* <div className={css(styles.Bar)}>
                                    <Bar />
                                </div> */}
                                <Scrollers scrollDown={scrollDown} scroll={scroll} fadeSpring={fadeSpring} setScroll={setScroll}/>

                                    
                                <animated.div
                                    style={{
                                        marginTop: scroll.state.to(
                                            (x) => -21.5 + (1 - x) * -29 + "vh"
                                        ),
                                    }}
                                >
                                    {eras.map((era,id) => {
                                        if (era.era === "era0") return undefined;
                                        return (
                                            <EraItem  key={id} handleClick={handleClick} selected={locationsState.currentLocation.era===era.era} fadeSpring={fadeSpring} era={era}/>
                                        );
                                    })}
                                </animated.div>
                            </div>
                        </animated.div>
                    </animated.div>
                </animated.div>
            </div>
        )
    );
};

const styles = StyleSheet.create({
    Background: {
        // backgroundColor:'black',
        // opacity:'0.5',
        position: "absolute",
        height: "200%",
        width: "100%",
    },
    Container: {
        position: "absolute",
        // backgroundColor:'#33968B',
        height: "200%",
        width: "100%",
        zIndex: zindexes.timelinesContainer,
        overflow: "hidden",
    },
    ContainerClickThrough: {
        position: "absolute",
        // backgroundColor:'#33968B',
        height: "200%",
        width: "100%",
        zIndex: zindexes.timelinesContainer,
        pointerEvents: "none",
        overflow: "hidden",
    },
    None: {
        display: "none",
    },
    TimeLines: {
        position: "absolute",
        width: "100%",
        outline:'0.8vw solid #FFE7BA',

        height: "calc(100% + 200vw)",
        // height:'100%',
        // left: "35vw",
        // right:'-5vw',
        top: "-65.8vw",
        // backgroundColor: '#36AA9D',
        transform: "rotate(-30deg)",
        zIndex: zindexes.timelines,
        "@media (max-height: 400px)": {
            top: "-73vw",
            width:'80%'
          },
        "@media (min-width: 565px)": {
            top: "-73vw",
        },
        "@media (min-width: 768px)": {
            top: "-73vw",
        },
        "@media (min-width: 900px)": {
            top: "-73vw", 
            width:'120%'        
        },
        "@media (min-width: 1400px)": {
            top: "-73vw",    
            width:'100%'        

        },
        
    },
    Eras: {
        position: "relative",
        top: "54vw",
        "@media (min-width: 565px)": {
            top: "58vw",
        },
        "@media (min-width: 768px)": {
            top: "59vw",
        },
        "@media (min-width: 902px)": {
            top: "63vw",          
        },
        "@media (min-width: 1400px)": {
            top: "64vw",         
        },
        "@media (max-height: 370px)":{
            top:"69vw"
        },
        "@media (max-height: 320px)":{
            top:"66vw"
        },
    },
    
    Overview: {
        position:'absolute',
        bottom:'2vh',
        left:'0.5vw',
        width:'14vw',
        zIndex:100,
        '@media (max-width:1050px)':{
            // transform:'scale(0.7)',
            
            bottom:"0.5vh"
        }
    },
    Box:{
        /* Rectangle 151 */
    zIndex:100,
    position: 'absolute',
    cursor:'pointer',
    // width: ,
    // height: '4vh',
    padding:'.7% .7% .7% .7%',
    background: '#FFE7BA',
    borderRadius: '10px',

    },
    Text:{
        margin:0,
        fontFamily:'Source Sans Pro',
        fontSize:17,
        color:'#36AA9D',
        '@media (max-width: 1000px)':{
            fontSize:13
        }
    },
    LeftBot:{
        position:'absolute',
        top:'85%'
    },
});