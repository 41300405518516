import React, { Suspense, useEffect, useState } from "react";
import { flattenedLocations } from "../../constants";
import { useLoader, useLocations, usePreloader } from "../../stores";

export const PanoramaLoader = () => {
  const loader = useLoader();
  const [currentLoading, setCurrentLoading] = useState();
  const locationsState = useLocations();

  useEffect(() => {
    if (loader.items.length !== 0) {
      // 
      locationsState.setCurrentLocation({loading:true , ...loader.items[loader.items.length - 1]});
      setCurrentLoading(loader.items[loader.items.length - 1]);
    }
  }, [loader.items]);

  useEffect(() => {
    // 
    // loader.addItems(flattenedLocations);
    // 
  }, []);
  return null;
};
