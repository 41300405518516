/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useEffect, useState } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";
import { useSpring } from "@react-spring/core";
import { animated as a } from "@react-spring/three";
import { springConfigs } from "../../constants";
import { animated } from "@react-spring/web";
import { Html } from "@react-three/drei";
import * as THREE from "three";
import { useSound } from "../../stores";
export default function Navtal(props) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF("./Models/NAVTAL_02.glb");
  const { actions } = useAnimations(animations, group);
  const [open, setOpen] = useState(false);
  const sound = useSound();
  const scale = useSpring({
    from: { x: 1 },
    x: 34,
    config: springConfigs.precision,
  });
  const handleClick = () => {
    sound.play("hud");
    actions.Animation.reset();
    actions.Animation.play();
  };
  useEffect(() => {
    actions.Animation.paused = false;
    actions.Animation.loop = THREE.LoopOnce;
    // actions.Animation.play()
    // actions.Animation.
    // actions.Animation.clampWhenFinished = true
    // actions.Animation.paused=true
    props.finishedLoading();

    materials.NavTal_BAse.color = new THREE.Color("rgb(226,204,126)");
  }, []);
  return (
    <>
      <a.group
        onPointerDown={handleClick}
        rotation={[0, Math.PI + Math.PI / 18, 0]}
        position={[0, -2, 0]}
        scale={scale.x}
        ref={group}
        {...props}
        dispose={null}
      >
        <group ref={group} {...props} dispose={null}>
          <group name="Scene">
            <group name="NavTalglb" position={[0, 0.05, 0]}>
              <group name="scene(2)glb" scale={0.1}>
                <group name="NavTal1Fglb" scale={0.005}>
                  <group
                    name="Empty"
                    position={[36.44525, 33.50444, 0]}
                    rotation={[0, 1.57053, 0]}
                    scale={[100.00002, 100, 100.00002]}
                  >
                    <mesh
                      name="Body"
                      geometry={nodes.Body.geometry}
                      material={materials.NavTal_BAse}
                      position={[-0.14337, 0, -0.4441]}
                      scale={0.01}
                    />
                    <mesh
                      name="Key"
                      geometry={nodes.Key.geometry}
                      material={materials["Material.001"]}
                      position={[0, -0.26303, 0.48548]}
                      scale={0.01}
                    />
                    <mesh
                      name="KeyHole"
                      geometry={nodes.KeyHole.geometry}
                      material={materials.Keyhole}
                      position={[-0.19118, -0.61257, -0.35613]}
                      scale={0.01}
                    />
                    <mesh
                      name="Lock"
                      geometry={nodes.Lock.geometry}
                      material={materials.Handle}
                      position={[0.00908, -0.4021, 0.02112]}
                      scale={0.01}
                    />
                  </group>
                </group>
              </group>
            </group>
          </group>
        </group>
      </a.group>
      <Html fullscreen style={{ pointerEvents: "none" }}>
        <animated.div
          style={{
            zIndex: 100,
            position: "absolute",
            width: "100%",
            height: "14%",
            left: 0,
            bottom: 0,
            pointerEvents: "none",
            textAlign: "center",
          }}
        >
          <p style={{ color: "#FFE7BA", fontFamily: "futura" }}>Tap to open</p>
        </animated.div>
      </Html>
    </>
  );
}

// useGLTF.preload('./Models/NAVTAL_02.glb')

setTimeout(() => useGLTF.preload("./Models/NAVTAL_02.glb"), 30000);
