import React, { useRef, useState, useEffect, Suspense } from 'react'
import { StyleSheet, css } from 'aphrodite'
import { BackArrow } from './assets/svgs/Arrows'
import { usePage, useSound } from '../stores'
import { StreamCopyUsage } from 'three'
import { useSpring } from '@react-spring/core'
import { animated } from '@react-spring/web'
import { BetterBackArrow } from './assets/svgs/BackArrow'
import { credits, springConfigs } from '../constants'
import { AnimatedButton } from './assets/AnimatedButton'
import { Canvas } from '@react-three/fiber'
import Typewriter from './Models/Typewriter'
import Skybox from './360Viewer/Skybox'
import { Submit } from './assets/svgs/Submit'
import emailjs from 'emailjs-com'
emailjs.init('qc3a663JyEiqvbCoj')
export const Credits = () => {
    const pageState = usePage()
    const [fade,setFade] = useState(false)
    const ref = useRef()
    const [name,setName] = useState('')
    const [email,setEmail] = useState('')
    const [feedback,setFeedback] = useState('')
    const soundState = useSound()
    const [shouldType,setShouldType] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [suggestions,setSuggestions] = useState(false)
    const spring = useSpring( {from:{opacity:0},opacity:fade?0:1, onRest:()=>fade && pageState.setPage('panorama')} )
    let time = useRef()
    const [isScrolling, setScrolling] = useState(true)
    const fadeCredits = useSpring( {opacity:suggestions?0:1, config:springConfigs.precision } )
    useEffect(()=>{
        // window.addEventListener('mousewheel',(e)=>console.log(window.scrollY))
        clearInterval(time.current)
        time.current = setInterval(()=>{

            ref.current.scrollTop+=1.5
        },50)
        return () =>{
            clearInterval(time.current)
            // ref.current.removeEventListener('scrollup',()=>setScrolling(false))
        } 
    },[])
    useEffect(()=>{
        // if(time.current)clearInterval(time.current)
        if(!fade)ref.current.scrollTop=0
    },[fade])
    useEffect(()=>{
        !isScrolling && clearInterval(time.current)
    },[isScrolling])
    const handleBack = () => {
        setSuggestions(false)
        setFade(true)
    }
    let typeTime
    const type = () => {
        if(!shouldType){
            soundState.play('type')
            setShouldType(true)
            typeTime = setTimeout(()=>{
                setShouldType(false)
            },1400)

        }
    }
    useEffect(()=>{
        if(suggestions){
            window.addEventListener('keydown',type)
        }else{
            window.removeEventListener('keydown',type)
        }
        return ()=> window.removeEventListener('keydown',type)
    },[suggestions])
    const handleSubmit = async () => {
        if(name && feedback){
            soundState.play('hud')
            try{
                await emailjs.send("service_dg3qru9","template_31b3nsk",{
                    from_name: name,
                    message: feedback,
                    from_email: email?`, ${email}`:'  ',
                });
                setSubmitted(true)

            }catch(err){
                console.log(err)
            }
        }
    }

    return (
        
        
        <animated.div style={spring} className={css(styles.Container)}>
            <div onClick={()=>handleBack()} className={css(styles.Back)}>
                {/* <BackArrow /> */}
                <BetterBackArrow noFill />
            </div>
            <div className={css(styles.Suggestions)}>
                <AnimatedButton clickEvent={!suggestions?()=>setSuggestions(true):()=>window.location.reload()} text={!suggestions?'Suggestions':'Restart Journey'} eraid={2} />
            </div>
            <animated.div style={fadeCredits}>
                <h1 className={css(styles.Credits)} style={{fontSize:24,marginTop:'3vh',fontWeight:1}}>Credits</h1>
                
                <div onWheel={()=>clearInterval(time.current)} ref={ref} className={css(styles.Scroll)}>
                    {
                        credits.titles.map((x,i) => {
                            return (
                                <div className={css(styles.ParaContainer)}>
                                    <h3 className={css(styles.Heading)}><b>{credits.titles[i]}</b></h3>
                                    <p className={css(styles.Para)}>{credits.texts[i]}</p>
                                </div>
                            )
                        })
                    }
                </div>
            </animated.div>
            <animated.div on style={{opacity:fadeCredits.opacity.to(x=>1-x),pointerEvents:suggestions?'auto':'none'}}>
                <div className={css(styles.Canvas)}>
                    {!fade && <Canvas>
                        <perspectiveCamera position={[0,2,-5]} />
                        <group scale={1.2} rotation={[Math.PI/10,Math.PI+Math.PI/24,0]}>
                            <Suspense fallback={null}>
                                <Typewriter credits shouldType={shouldType} />
                            </Suspense>
                        </group>
                        <Skybox />
                    </Canvas>}
                </div>
                <div className={css(styles.FormContainer)}>
                    
                        {!submitted?(<form onSubmit={handleSubmit} className={css(styles.Form)}>
                            <p className={css(styles.Text)}>Send us your feedback!</p>
                            <input value={name} onChange={(e)=>setName(e.target.value)} placeholder='Name' className={css(styles.FormElement)} />
                            <input value={email} onChange={(e)=>setEmail(e.target.value)} placeholder='Email (optional)' style={{marginTop:'-15px'}} className={css(styles.FormElement)} />
                            <textarea value={feedback} onChange={(e)=>setFeedback(e.target.value)} placeholder='Feedback' className={css(styles.TextArea)} />
                            <div style={{textAlign:'right',width:'100%'}}>
                                <div onPointerDown={handleSubmit} style={{width:'5.4vw',position:'absolute',right:0}}>
                                    <Submit shouldSubmit={name && feedback}/>
                                </div>
                            </div>
                        </form>):(
                            <form className={css(styles.Form)}>

                                <p className={css(styles.Text)}>Thank you for your feedback!</p>
                            </form>
                        )
                        }
                </div>
            </animated.div>
            
        </animated.div>
    )
}


const styles = StyleSheet.create({
    Container:{
        backgroundColor:'#CE492A',
        position:'absolute',
        top:0,
        left:0,
        width:'100%',
        height:'100%',
        zIndex:200,
        color:'#FFE7BA',
        textAlign:'center',
        fontFamily:'Bungee inline',
        fontWeight:1,
        overflow:'hidden',


    },
    Credits:{
        '@media (max-width: 1000px)':{
            fontSize:20
        }
    },
    Back:{
        position:'absolute',
        zIndex:50,
        width:'6.5%',
        overflow:'visible',
        left:'1vw',
        top:'1.9vh',
        // height:'1vw'
    },
    Heading:{
        fontFamily:'futura',
        fontWeight:600,
        '@media (max-width: 1000px)':{
            fontSize:18
        }
        
    },
    ParaContainer:{
        margin:'auto',
        marginTop:'6vh',
        width:'44vw'
    },
    Para:{
        fontSize:20,
        marginTop:'-1.8vh',
        fontFamily:'futura',
        '@media (max-width: 1000px)':{
            fontSize:16
        }
    },
    Scroll:{
        overflowY:'scroll',
        height:'85vh',
        width:'99.5%'
    },
    Suggestions:{
        position: "absolute",
        textAlign: "center",
        bottom:'8.5vh',
        right:'8vw',
    },
    Canvas:{
        position:'absolute',
        left:0,
        top:0,
        height:'100%',
        width:'55%',
        zIndex:20,
        // backgroundColor:'blue'
    },
    FormContainer:{
        position:'absolute',
        right:0,
        top:0,
        height:'100%',
        fontFamily:'Pica',
        width:'60%',
        zIndex:100,
        // backgroundColor:'red',
        // textAlign:'center'
    },
    Form:{
        position:'absolute',
        top:'50%',
        left:'50%',
        transform:'translate(-50%,-50%)',
        zIndex:80,
        margin:0,
        fontSize:18,
        fontFamily:'Pica',
        '@media (max-width: 1000px)':{
            fontSize:14
        }
    },
    FormElement:{
        display:'block',
        width:'23vw',
        padding:'1%',
        // margin:'1%',
        fontFamily:'Pica',
        // marginTop:'-10%',
        lineHeight:0,
        marginTop:'3%',
        backgroundColor:'#FFE7BA',
        border:'2px rgba(0,0,0,0) solid',
        borderRadius:'5px',

    },
    TextArea:{
        width:'100%',
        height:'25vh',
        fontFamily:'Pica',
        marginTop:'3%',
        // marginLeft:'5.3%'
        // margin:'1%',
        backgroundColor:'#FFE7BA',
        border:'2px rgba(0,0,0,0) solid',
        borderRadius:'5px',

    },
    Text:{
        fontSize:20,
        fontFamily:'Pica',
        // fontFamily:'futura',
        color:'#FFE7BA',
        '@media (max-width: 1000px)':{
            fontSize:16
        }
    }
})