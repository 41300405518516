import { StyleSheet,css } from 'aphrodite'
import React,{useRef,useEffect} from 'react'

export const HorizontalScroll = (props) => {
    const ref = useRef()
    useEffect(() => {
        function transformScroll(event) {
            
            if (!event.deltaY) {
              return;
            }
            
            ref.current.scrollLeft += event.deltaY;
          }
          
          var element = document.scrollingElement || document.documentElement;
          element.addEventListener("wheel", transformScroll);
          
          
        return () => {
            
            element.removeEventListener("wheel", transformScroll)
        }
    }, [])
    return (
        <div ref={ref} className={css(styles.Container)}>
            <div className={css(styles.Comic)}>
                <img  height='98%' src={props.url} alt=''/>
            </div>

        </div>
    )
}
const styles = StyleSheet.create({
    Container:{
        position:'absolute',
        // paddingLeft:'2%',
        // paddingRight:'2%',
        marginRight:'2%',
        width:'96%',
        height:'93%',
        overflowY:'hidden',
        overflowX:'auto',
        // backgroundColor:"red",
        top:'50%',
        left:'50%',
        transform:'translate(-50%,-48.9%)',

    },
    Comic:{
        // border: '#FFE7BA 4px solid',
        position:'absolute',
        // width:'98.25%',
        height:'100%',
        overflowX:'hidden',
        overflowY:'hidden',
        // left:'50%',
        top:'50%',
        transform:'translate(0%,-49.9%)',
        // marginTop:'0.4%',

        '@media (max-width:900px)':{
            // marginTop:'1.3%',
            height:'84%'
        }
    },
})