import React from 'react'

export const GradientVideo = () => {
    return (
        <svg width='100%' viewBox="0 0 103 700" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="103" width="700" height="102.3" transform="rotate(90 103 0)" fill="url(#paint0_linear)"/>
<defs>
<linearGradient id="paint0_linear" x1="453" y1="0" x2="453" y2="102.3" gradientUnits="userSpaceOnUse">
<stop stop-color="#3B3B3B"/>
<stop offset="1" stop-color="#3B3B3B" stop-opacity="0"/>
</linearGradient>
</defs>
</svg>

    )
}
