import React, { useEffect, useRef, useState } from 'react'
import { css,StyleSheet } from 'aphrodite'
import { Html } from '@react-three/drei'
import { HotspotManager } from './HotspotManager'
import { isMobile } from 'react-device-detect'
import { TextHeavy } from '../popups/TextHeavy'
import {ImagePopup } from '../popups/ImagePopup'
import {VideoPopup} from '../popups/VideoPopup'
import { ModelViewer3D } from '../popups/3DModelViewer'
import { AudioPopup } from '../popups/AudioPopup'
import { useBlur } from '../../stores'



export const PopupManager = (props) => {
    
    const switchRender = (type) => {
        switch(type){
            case 'image':
            case 'comic':
                return <ImagePopup {...props} />
            case 'textheavy':
                return <TextHeavy {...props} />
            case 'video':
                return <VideoPopup {...props} />
            case 'audio':
                return <AudioPopup {...props}/>
            case 'object':
                return <ModelViewer3D {...props}/>
            default:
                return null 
            }
        }
    const blur = useBlur()                           
    // const hideHotSpots = ({setShow,setShowHotspots}) => {
    //     setShow(false)
    //     setShowHotspots(true)
    //     
    //     blur.setBlur(false)
    // }
    useEffect(()=>{
        // if(props.type!=='audio')blur.setBlur(true)
    },[])
    // return null
    return switchRender(props.type)
                
            
       
    
}

const stylesheets = StyleSheet.create({
    Container:{
        position:'absolute',
        top:0,
        bottom:0,
        left:0,
        right:0,
        textAlign:'center',
        zIndex:12,
        background:'rgba(50,0,0,0.5)',
    },
    Media:{
        marginTop:`calc(${50*window.innerHeight/window.innerWidth}% - 300px)`,
    }
})
