import './App.css'
import { Html, OrbitControls, Stats } from '@react-three/drei'
import { Canvas } from '@react-three/fiber'
import { useLoader, useLocations, usePage, usePreloader } from './stores'
import Camera from './components/360Viewer/Camera'
import PanoramaViewer from './components/360Viewer/PanoramaViewer'
import { Suspense, useEffect, useState } from 'react'
import { HUD } from './components/360Viewer/HUD/HUD'
import { PanoramaLoader } from './components/360Viewer/PreLoader'
import { Mangroves } from './components/Introduction/Mangroves'
import { SplashScreen } from './components/Introduction/SplashScreen'
import { ValueWheel } from './components/Introduction/ValueWheel'
import PortraitError from './components/PortraitError/PortraitError'
import { TimeLines } from './components/Introduction/TimeLines'
import { Introduction } from './components/Introduction/Introduction'
import { GodrejLogo } from './components/assets/svgs/GodrejLogo'
import { useRef } from 'react'
import { AudioPlayer } from './components/popups/AudioPlayer'
import { Credits } from './components/Credits'
import { VideoBooth } from './components/360Viewer/VideoBooth'
import { HudSounds } from './components/HudSounds'
import { AudioHack } from './components/AudioHack'
import ReactGA from 'react-ga'
import { activateGA, sendPageView } from './components/GA/google-analytics'
import { gaConfig } from './constants'

/**
 * Activate GA Code
 */
activateGA(gaConfig.code)

function App() {
  const locationsState = useLocations()
  const pageState = usePage()
  const loader = useLoader()
  const [introduction, setIntroduction] = useState(true)
  const ref = useRef()
  const locs = useLocations()
  useEffect(() => {
    window.scrollTo(0, 1)
  })
  const goBackToLocation0 = () => {
    locs.setCurrentLocation(locs.allLocations[0])
    pageState.setPage('panorama')
  }

  useEffect(() => {
    // console.log('page state: ' + pageState.page)
    if (pageState.page === 'valuewheel') {
      sendPageView('valuewheel', 'valuewheel', '/valuewheel')
    } else if (pageState.page === 'timelines') {
      sendPageView('timelines', 'timelines', '/timelines')
    } else if (pageState.page === 'introduction') {
      sendPageView('introduction', 'introduction', '/introduction')
    } else if (pageState.page === 'splashscreen') {
      sendPageView('splashscreen', 'splashscreen', '/splashscreen')
    } else if (pageState.page === 'panorama') {
      sendPageView('panorama', 'panorama', '/panorama')
    } else if (pageState.page === 'credits') {
      sendPageView('credits', 'credits', '/credits')
    }
  }, [pageState.page])

  useEffect(() => {
    // locationsState.setCurrentLocation(locationsState.allLocations[1])
    pageState.setPage('splashscreen')
    // pageState.setPage('panorama')
    // ReactGA.initialize('UA-163873590-12')
  }, [])
  return (
    <>
      <>
        {pageState.page === 'credits' && <Credits />}
        <AudioPlayer />
        {introduction && (
          <>
            <SplashScreen />
            <Mangroves />
            <ValueWheel goBack={() => goBackToLocation0()} />
          </>
        )}
        <TimeLines />
      </>

      {/* <Introduction /> */}
      <HudSounds />
      <Introduction />
      <VideoBooth />
      <div>
        <HUD />
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          }}
        >
          <Canvas
            frameloop="demand"
            performance={{ min: window.innerWidth < 900 ? 0.2 : 0.4 }}
          >
            <PanoramaLoader />
            <Camera />
            {/* <pointLight position={[10, 10, 10]} /> */}
            <PanoramaViewer />
            {/* <Stats />  */}
          </Canvas>
        </div>
      </div>
      <PortraitError />
      <GodrejLogo onClick={goBackToLocation0} />
    </>
  )
}

export default App
